<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste des <span style="color:#ffa409">commissions</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>
    <div class="card-form card-base">
      <div class="toolbar-box flex align-center" v-if="!isMobile">
        <div class="box grow m-10 mt-5">
          <el-row :gutter="24">
            <el-col :span="8">
              <div class="grid-content bg-purple mr-5">
                <label>Période</label>
                <el-date-picker style="width: 100%!important;" v-model="form.date" type="daterange" range-separator="à"
                  start-placeholder="Date de début" end-placeholder="Date de fin" format="dd-MM-yyyy"
                  @change="onChange">
                </el-date-picker>
              </div>
            </el-col>

            <el-col :span="6">
              <div class="grid-content bg-purple">
                <label>Selectionner la branche(s)</label>
                <el-select style="width: 100%!important;" v-model="form.branches" multiple filterable allow-create
                  default-first-option placeholder="Selectionner branche(s)" collapse-tags :max-collapse-tags="4">
                  <el-option v-for="items in brancheOptions" :key="items" :label="items.branche" :value="items._id">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light">
                <label>Selectionner l'assureur(s)</label>
                <el-select style="width: 100%!important;" v-model="form.assureurs" multiple filterable allow-create
                  default-first-option placeholder="Selectionner assureur(s)" collapse-tags :max-collapse-tags="4">
                  <el-option v-for="items in assureurOptions" :key="items" :label="items.societe" :value="items.id">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple-light mt-20">
                <el-button class="float-right" @click="goFilter()" type="primary" round>Valider</el-button>
              </div>
            </el-col>
          </el-row>

        </div>
      </div>

    </div>

    <div class="mb-10 mt-10">
      <el-row :gutter="15">
        <el-col :span="16">
          <el-button type="primary" round :disabled="!checkfiltre" @click="dialogBordereaux = true">
            Exporter en Excel</el-button>
          <el-button type="primary" round @click="modalManyEncaissements()"> Plusieurs encaissements</el-button>

        </el-col>

        <el-col :span="8">
          <el-input placeholder="Rechercher ..." prefix-icon="el-icon-search" v-model="search" clearable>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />

    <div class="mb-5 table-box card-base card-shadow--medium box grow " id="table-wrapper" v-loading="!ready"
      v-if="!isMobile">
      <el-table :row-class-name="tableRowClassName" :data="listInPage" style="width: 100%" v-if="ready" :height="height"
        @selection-change="handleSelectionChange">

        <el-table-column label="Numero avenant" min-width="140" prop="numero_avenant" fixed></el-table-column>
        <el-table-column label="type avenant" min-width="180" prop="type_avenant"></el-table-column>
        <el-table-column label="type prime" min-width="160" prop="type_prime"></el-table-column>
        <el-table-column label="Client" min-width="200" prop="client">
          <template slot-scope="scope">
            <span class="date">
              <a href="#" @click="SetDetailUser(scope.row.user)">
                {{ scope.row.user.prenom }} - {{ scope.row.user.nom }}
              </a>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Numéro police" min-width="200" prop="numero_police"></el-table-column>
        <el-table-column label="Partenaire" min-width="150" prop="partenaireid.societe"></el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date Souscription" min-width="150" prop="dateSouscription">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime NETTE" min-width="150" prop="prime_nette">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.prime_nette !== undefined ? round(scope.row.prime_nette) : "" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Accessoires" min-width="150" prop="accessoires">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.accessoires !== undefined ? round(scope.row.accessoires) : "" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.prime_ttc !== undefined ? round(scope.row.prime_ttc) : "" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Commission" min-width="150" prop="commission">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.commission !== undefined ? round(scope.row.commission) : "" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Access courtier" min-width="150" prop="accessoires_courtier">
          <template slot-scope="scope">
            <span class="date">{{
              scope.row.accessoires_courtier !== undefined ?
                round(scope.row.accessoires_courtier) : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="150">
          <template slot-scope="scope">
            <el-button size="mini" @click="toggleDetails(scope.row)" type="primary" icon="el-icon-view"
              circle></el-button>
            <el-button size="mini" type="primary" @click="toggleCommission(scope.row)" icon="el-icon-edit"
              circle></el-button>
            <el-button size="mini" type="info" @click="toggleEncaissement(scope.row)" icon="el-icon-document-checked"
              circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
        :total="total"></el-pagination>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
    </div>

    <el-dialog title="Export un bordereaux en format excel" width="50%" :visible.sync="dialogBordereaux"
      v-if="dialogBordereaux == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div>
            <el-input placeholder="Description du bordereau..." type="textarea" v-model="form.description"></el-input>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading"
          @click="exportBordereaux()">Exporter</el-button>
        <el-button type="danger" round @click="dialogBordereaux = false">Fermer</el-button>
      </div>
    </el-dialog>

    <el-dialog width="70%" title="Client" :visible.sync="showUser" v-if="showUser == true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%" title="Client" :visible.sync="showUser" v-if="showUser == true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="50%" title="Détails" :visible.sync="showcommission">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style: none">
                  <li>
                    Assureur :
                    <span class="to-right assuraf-orange">{{
                      viewAssureur(detailcommission.partenaireid)
                      }}</span>
                  </li>
                  <li>
                    Branche :
                    <span class="to-right assuraf-orange">
                      {{ viewBranche(detailcommission.brancheid) }}
                    </span>
                  </li>

                  <li>
                    Contrat :
                    <span class="to-right assuraf-orange">{{
                      viewPolice(detailcommission)
                      }}</span>
                  </li>

                  <li>
                    Prime nette:
                    <span class="to-right assuraf-orange">{{
                      viewPrime(detailcommission)
                      }}</span>
                  </li>

                  <li>
                    Date d'éffet :
                    <span class="to-right assuraf-orange">{{
                      DateFormater(detailcommission.dateEffet)
                      }}</span>
                  </li>

                  <li>
                    Date d'échéance :
                    <span class="to-right assuraf-orange">{{
                      DateFormater(detailcommission.dateEcheance)
                      }}</span>
                  </li>

                  <li>
                    Date de création :
                    <span class="to-right assuraf-orange">{{
                      DateFormater(detailcommission.dateSouscription)
                      }}</span>
                  </li>
                  <li>
                    <el-divider />
                  </li>

                  <li>
                    Commission :
                    <span class="to-right assuraf-orange"><b>{{ round(detailcommission.commission) }}</b></span>
                  </li>
                  <li>
                    Accessoires courtiers :
                    <span class="to-right assuraf-orange"><b>{{
                      round(detailcommission.accessoires_courtier)
                        }}</b></span>
                  </li>
                  <li>
                    Total Revenu :
                    <span class="to-right assuraf-orange"><b>{{ totalRevenu(detailcommission) }}</b></span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    <h3 class="text-center">DETAILS COMMISSION</h3>
                  </li>
                  <li>
                    Etat encaissement  :
                    <span class="to-right" :style="detailcommission.etat_encaissement_commission == 'Non'
                        ? 'color : red;'
                        : 'color : green;'
                      ">{{ detailcommission.etat_encaissement_commission }}</span>
                  </li>
                  <li>
                    Moyen de paiement :
                    <span class="to-right">{{
                      detailcommission.moyen_paiement_commission
                      }}</span>
                  </li>
                  <li v-if="detailcommission.moyen_paiement_commission != 'Virement'">
                    Numéro:
                    <span class="to-right">{{
                      detailcommission.num_cheque_encaissement_commission
                      }}</span>
                  </li>
                  <li
                    v-if="detailcommission.moyen_paiement_commission == 'Virement' || detailcommission.moyen_paiement_commission == 'Chéque'">
                    Banque:
                    <span class="to-right">{{ detailcommission.banque_encaissement_commission }}</span>
                  </li>
                  <li>
                    Date encaissement:
                    <span class="to-right">{{
                      DateFormater(detailcommission.date_encaissement_commission)
                      }}</span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    <h3 class="text-center">DETAILS ENCAISSEMENT</h3>
                  </li>
                  <li>
                    Etat encaissement :
                    <span class="to-right" :style="
                      detailcommission.etat_encaissement == 'Non'
                        ? 'color : red;'
                        : 'color : green;'
                    ">
                      {{
                        detailcommission.etat_encaissement != undefined ?
                        detailcommission.etat_encaissement :
                        null
                      }}</span>
                  </li>
                  <li>
                    Moyen de paiement :
                    <span class="to-right">{{
                      detailcommission.moyen_paiement != undefined ? detailcommission.moyen_paiement : null
                    }} </span>
                  </li>
                  <li v-if="detailcommission.moyen_paiement != 'Virement'">
                    Numéro :
                    <span class="to-right">{{
                      detailcommission.num_cheque_encaissement != undefined ?
                      detailcommission.num_cheque_encaissement : null
                    }}</span>
                  </li>
                  <li v-if="detailcommission.moyen_paiement =='Virement' || detailcommission.moyen_paiement =='Chéque'">
                    Banque :
                    <span class="to-right">{{
                      detailcommission.banque_encaissement != undefined ? detailcommission.banque_encaissement :
                      null
                    }}</span>
                  </li>
                  <li>
                    Date :
                    <span class="to-right">{{
                      detailcommission.date_encaissement != undefined ?
                      DateFormater(detailcommission.date_encaissement) : null
                    }}</span>
                  </li>
                  <li>
                    <el-divider />
                  </li>

                  <li>
                    <h3 class="text-center">DETAILS REVERSEMENT </h3>
                  </li>
                  <li>
                    Etat reversement :
                    <span class="to-right" :style="detailcommission.etat_reversement == 'Non'
                      ? 'color : red;'
                      : 'color : green;'
                      ">
                      {{
                        detailcommission.etat_reversement != undefined ?
                          detailcommission.etat_reversement :
                          null
                      }}</span>
                  </li>
                  <li>
                    Mode de reversement :
                    <span class="to-right">{{
                      detailcommission.mode_reversement != undefined ? detailcommission.mode_reversement : null
                      }} </span>
                  </li>
                  <li v-if="detailcommission.mode_reversement != 'Virement'">
                    Numéro :
                    <span class="to-right">{{
                      detailcommission.num_cheque_reversement != undefined ?
                        detailcommission.num_cheque_reversement : null
                      }}</span>
                  </li>
                  <li
                    v-if="detailcommission.mode_reversement == 'Virement' || detailcommission.mode_reversement == 'Chéque'">
                    Banque:
                    <span class="to-right">{{
                      detailcommission.banque_reversement != undefined ? detailcommission.banque_reversement :
                        null
                      }}</span>
                  </li>
                  <li>
                    Date:
                    <span class="to-right">{{
                      detailcommission.date_reversement != undefined ?
                        DateFormater(detailcommission.date_reversement) : null
                      }}</span>
                  </li>
                  <li>
                    <el-divider />
                  </li>
                  <li>
                    Commentaire :
                    <div>{{
                      detailcommission.commentaire || null
                      }}</div>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog width="70%!important" title="Formulaire de modification" :visible.sync="editCommission"
      v-if="editCommission == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="12">
              <div>
                <label>Commission</label>
                <el-input v-model="detailcommission.commission" placeholder="Commission ..."
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <label>Accessoires courtiers</label>
                <el-input v-model="detailcommission.accessoires_courtier" placeholder="Accessoires courtier ..."
                  class="input-with-select"></el-input>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="updateCommission()">Modifier</el-button>
          <el-button type="danger" round @click="editCommission = false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog width="80%!important" title="Formulaire d'encaissement de commission"
      :visible.sync="editManyEncaissement" v-if="editManyEncaissement == true">
      <el-card class="box-card">
        <div style="text-align: center">
          <el-transfer :titles="['Commission', 'Encaissement']" filterable :button-texts="['Retirer', 'Ajouter']"
            style="text-align: left; display: inline-block;" :filter-method="filterMethod"
            filter-placeholder="filtre ..." v-model="listencaissement" :data="newlistcontrat">
          </el-transfer>
        </div>
      </el-card>
      <el-card class="mt-10">
        <el-row :gutter="15">
          <el-col :span="8" class="mt-5">
            <div>
              <label>Etat Encaissement</label>
              <el-select style="width: 100% !important" v-model="encaissement.etat_encaissement"
                placeholder="Selectionner ---">
                <el-option value="Non">Non</el-option>
                <el-option value="Oui">Oui</el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="8" class="mt-5">
            <div>
              <label>Moyen de paiement</label>
              <el-select style="width: 100% !important" v-model="encaissement.moyen_paiement"
                @change="updateLabelDate()" placeholder="Selectionner ---">
                <el-option value="Chéque"></el-option>
                <el-option value="Virement"></el-option>
                <el-option value="Autre"></el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="8" class="mt-5" v-if="encaissement.moyen_paiement == 'Chéque'">
            <div>
              <label>Numéro</label>
              <el-input v-model="encaissement.num_cheque" placeholder="Numéro  ..."
                class="input-with-select"></el-input>
            </div>
          </el-col>

          <el-col :span="8" class="mt-5" v-if="encaissement.moyen_paiement == 'Autre'">
            <div>
              <label>Mode de paiment</label>
              <el-input v-model="AUTRES" placeholder="Mode de paiment ..." class="input-with-select"></el-input>
            </div>

          </el-col>
          <el-col :span="8" class="mt-5" v-if="encaissement.moyen_paiement == 'Autre'">
            <div>
              <label>Numéro </label>
              <el-input v-model="encaissement.num_cheque" placeholder="Numéro du reçu ..."
                class="input-with-select"></el-input>
            </div>
          </el-col>
          <el-col :span="8" class="mt-5" v-if="encaissement.moyen_paiement !== 'Autre'">
            <div>
              <label>Banque</label>
              <el-input v-model="encaissement.banque" placeholder="banque" class="input-with-select"></el-input>
            </div>
          </el-col>
          <el-col :span="8" class="mt-5">
            <div>
              <label>Date {{ labelDate }}</label><br />
              <el-date-picker v-model="encaissement.date_encaissement" type="date" format="dd/MM/yyyy"
                :picker-options="dateBeforeToday" placeholder="Date encaissée" />
            </div>
          </el-col>
        </el-row>
      </el-card>
      <div class="mb-5" style="margin-top: 15px">
        <el-button type="primary" round @click="goManyEncaissement()">Valider</el-button>
        <el-button type="danger" round @click="editManyEncaissement = false">Annuler</el-button>
      </div>

    </el-dialog>

    <el-dialog width="70%!important" title="Formulaire d'encaissement de commission" :visible.sync="editEncaissement"
      v-if="editEncaissement == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="12">
              <div>
                <label>Etat Encaissement</label>
                <el-select style="width: 100% !important" v-model="detailcommission.etat_encaissement"
                  placeholder="Selectionner ---">
                  <el-option value="Non">Non</el-option>
                  <el-option value="Oui">Oui</el-option>
                </el-select>
              </div>
            </el-col>

            <el-col :span="12" class="mt-5">
              <div>
                <label>Moyen de paiement</label>
                <el-select style="width: 100% !important" v-model="detailcommission.moyen_paiement"
                  placeholder="Selectionner ---" @change="updateLabelDate()">
                  <el-option value="Chéque"></el-option>
                  <el-option value="Virement"></el-option>
                  <el-option value="Autre"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px" :gutter="15" v-if="detailcommission.moyen_paiement == 'Autre'">
            <el-col :span="12" class="mt-5">
              <div>
                <label>Mode de paiment</label>
                <el-input v-model="AUTRES" placeholder="Mode de paiment ..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mt-5">
              <div>
                <label>Numéro</label>
                <el-input v-model="detailcommission.num_cheque" placeholder="Numéro du reçu ..."
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 15px" class="mt-5">
              <div>
                <label>Date {{ labelDate }}</label><br />
                <el-date-picker v-model="detailcommission.date_encaissement" type="date" format="dd/MM/yyyy"
                  :picker-options="dateBeforeToday" placeholder="Date ..." class="input-with-select" />
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px" :gutter="15" v-else>
            <el-col :span="12" class="mt-5" v-if="detailcommission.moyen_paiement == option.cheque">
              <div>
                <label>Numéro</label>
                <el-input v-model="detailcommission.num_cheque" placeholder="Numéro  ..."
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mt-5">
              <div>
                <label>Banque</label>
                <el-input v-model="detailcommission.banque" placeholder="banque " class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mt-5">
              <div>
                <label>Date {{ labelDate }}</label><br />
                <el-date-picker v-model="detailcommission.date_encaissement" type="date" format="yyyy/MM/dd"
                  :picker-options="dateBeforeToday" placeholder="Date ..." class="input-with-select" />
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="updateEncaissement()">Modifier</el-button>
          <el-button type="danger" round @click="editEncaissement = false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import lib from "../../../libs/index";
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months priore
    const maxDate = new Date(today);

    return {
      /**debut commission */
      modalgenerationloading: false,
      editEncaissement: false,
      newlistcontrat: [],
      listencaissement: [],
      editManyEncaissement: false,
      encaissement: {
        etat_encaissement: '',
        moyen_paiement: '',
        banque: '',
        num_cheque: '',
        date_encaissement: '',
      },
      filterMethod(query, item) {
        return item.initial.toLowerCase().indexOf(query.toLowerCase()) > -1;
      },
      AUTRES: "",
      option: {
        virement: "Virement",
        cheque: "Chéque",
        autre: "Autre",
      },
      labelDate: "",
      /** end commission */

      detailcommission: {},
      showcommission: false,
      editCommission: false,
      dateBeforeToday: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      showUser: false,
      dialogBordereaux: false,
      listbranche: [],
      listassureur: [],
      count: 0,
      max: maxDate,
      recherche: false,
      listuser: null,
      listcontrat: [],
      form: {
        date: [],
        branches: [],
        assureurs: [],
        description: "",
      },
      checkfiltre: false,
      assureurOptions: [],
      brancheOptions: [],
      dismissSecs: 3,
      dismissCountDown: 0,
      detailsUser: [],
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listbranche,//users,
      itemsChecked: [],
    }
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    listFiltered() {
      if (this.search.length >= 1) {
        return this.listcontrat.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
            if (
              obj[k] &&
              obj[k]
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            ) {
              ctrl = true;
              /*on le push dans le tableau */
              //this.listcommission.push(obj)
            } else {
              /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
              if (Array.isArray(obj[k])) {
                obj[k].filter((nobj) => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (
                      nobj[l] &&
                      nobj[l]
                        .toString()
                        .toLowerCase()
                        .indexOf(this.search.toLowerCase()) !== -1
                    ) {
                      ctrl = true;
                      /* on le push dans le tableau */
                      //this.listcommission.push(obj)
                    }
                  }
                });
                /* Si la valeur courante n'est ni String, ni Tableau mais Object */
              } else if (
                !Array.isArray(obj[k]) &&
                typeof obj[k] == "object" &&
                obj[k] != null
              ) {
                /*== On crée un nouveau tablequ vide ==*/
                let my_new_array = [];

                /*== On push la valeur courante dans le nouveau tableau ==*/
                my_new_array.push(obj[k]);

                /*On refiltre le nouveau tableau atrribut par attribut*/
                my_new_array.filter((nobj) => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (
                      nobj[l] &&
                      nobj[l]
                        .toString()
                        .toLowerCase()
                        .indexOf(this.search.toLowerCase()) !== -1
                    ) {
                      ctrl = true;

                      /* on le push dans le tableau */
                      //this.listcommission.push(obj)
                    }
                  }
                });
              }
            }
          }
          return ctrl
        })
      } else {
        return this.listcontrat.filter((obj) => {
          let ctrl = false;
          for (let k in obj) {
            if (
              obj[k] &&
              obj[k]
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true;
          }
          return ctrl;
        });
      }
    },
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = ""
          let val2 = ""

          val1 = item1[prop]
          val2 = item2[prop]
          if (order === "descending") {
            return val2 < val1 ? -1 : 1
          }
          return val1 < val2 ? -1 : 1
        })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    },
    sortOptions() {
      return this.listuser
        .filter((f) => f.sortable)
        .map((f) => {
          return {
            text: f.label,
            value: f.key,
          };
        });
    },
  },
  watch: {
    isBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs["my-table"].$el;
        if (newVal === true) {
          tableScrollBody.classList.add("overflow-hidden");
        } else {
          tableScrollBody.classList.remove("overflow-hidden");
        }
      }
    },

    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    updateEncaissement() {
      if (
        this.detailcommission.etat_encaissement &&
        this.detailcommission.moyen_paiement &&
        this.detailcommission.date_encaissement
      ) {
        if (this.detailcommission.moyen_paiement == 'Autre')
          this.detailcommission.moyen_paiement = this.AUTRES
        Api()
          .post("/borderaux/commission/update/encaissement", this.detailcommission)
          .then((result) => {
            this.$message.success(
              "Modification effectuée avec succés !",
              this.AlertMessage
            );
            this.editEncaissement = false;
            this.getContrat();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.error(
          "verifié les informations saisie!",
          this.AlertMessage
        );
      }
    },

    toggleEncaissement(item) {
      this.detailcommission = item;
      this.editEncaissement = true;
    },
    updateLabelDate() {
      if (this.encaissement.moyen_paiement == "Virement" || this.detailcommission.moyen_paiement == "Virement") {
        this.labelDate = "du virement"
      }
      if (this.encaissement.moyen_paiement == "Chéque" || this.detailcommission.moyen_paiement == "Chéque") {
        this.labelDate = "du chéque"
      }
      if (this.encaissement.moyen_paiement == "Autre" || this.detailcommission.moyen_paiement == "Autre") {
        this.labelDate = "d'encaissement"
      }
    },
    modalManyEncaissements() {
      this.newlistcontrat = []
      this.listencaissement = []
      this.listFiltered.forEach((data, index) => {
        if (data.etat_encaissement_commission && data.etat_encaissement_commission.toUpperCase() == "NON") {

          this.newlistcontrat.push({
            label: data.numero_police,
            key: data._id,
            initial: data.numero_police
          });
        }
      });
      this.editManyEncaissement = true;
    },

    goManyEncaissement() {
      if (
        this.encaissement.etat_encaissement &&
        this.encaissement.moyen_paiement &&
        this.encaissement.date_encaissement &&
        this.listencaissement.length != 0
      ) {

        if (this.encaissement.moyen_paiement == 'Autre')
          this.encaissement.moyen_paiement = this.AUTRES

        Api()
          .post("/borderaux/commission/updatemany/encaissement", {
            _id: this.listencaissement,
            etat_encaissement: this.encaissement.etat_encaissement,
            moyen_paiement: this.encaissement.moyen_paiement,
            num_cheque: this.encaissement.num_cheque,
            banque: this.encaissement.banque,
            date_encaissement: this.encaissement.date_encaissement
          })
          .then((result) => {
            this.encaissement = {
              etat_encaissement: '',
              moyen_paiement: '',
              banque: '',
              num_cheque: '',
              date_encaissement: '',
            },
              this.$message.success(
                "Operation effectuée avec succés !",
                this.AlertMessage
              );
            this.editManyEncaissement = false;
            this.getContrat();

          })
          .catch((err) => {
            this.$message.error(
              "Verifié les informations saisies !",
              this.AlertMessage
            );
            console.log(err);
          });
      } else {
        this.$message.error(
          "Verifié les informations saisies !",
          this.AlertMessage
        );
      }
    },

    tableRowClassName({ row, rowIndex }) {
      const av = row.type_avenant;
      if (av != undefined && av.toUpperCase() == "ANNULATION") {
        return 'warning-row';
      }

    },

    updateCommission() {
      let item = this.detailcommission;

      if (isNaN(item.commission)) {
        this.$message.error(
          "Commission doit etre superieur ou egale a zéro !",
          this.AlertMessage
        );
        return;
      }
      if (isNaN(item.accessoires_courtier)) {
        this.$message.error(
          "Accessoires courtier doit etre superieur ou egale a zéro !",
          this.AlertMessage
        );
        return;
      }
      Api()
        .post("/borderaux/update/commission", {
          _id: item._id,
          commission: item.commission,
          accessoires_courtier: item.accessoires_courtier,
        })
        .then((resultat) => {
          this.$message.success(
            "Modification effectuée avec succés !",
            this.AlertMessage
          );
          this.getContrat();
          this.editCommission = false;
        })
        .catch((erreur) => {
          this.$message.error(
            "Vérifiez si les champs sont bien remplie !",
            this.AlertMessage
          );
          console.log(erreur);
        });
    },

    isEmailConfig() {
      return lib.sdk.emailConfig.includes(store.state.user.email)
        ? true
        : false;
    },

    generateCommission() {
      Api()
        .get('borderaux/updateContratAttribut')
        .then((result) => {
          // console.log("=================================================");
          // console.log("==> ", result.data);
          // console.log("=================================================");
          this.$message.success(
            "Modification effectuée avec succés !",
            this.AlertMessage
          );
        })
      setTimeout(() => {
        this.$router.go("/bordereaux/commissions")
      }, 2000)
        //this.$router.go("/bordereaux/commissions")
        .catch((err) => {
          console.log(err);
        });
    },
    round(val) {
      if (val != undefined)
        return Math.round(val).toLocaleString() + " CFA";
    },
    totalRevenu(item) {
      if (item.commission != undefined) {
        let total = Math.round(item.commission) + Math.round(item.accessoires_courtier);
        return Math.round(total).toLocaleString() + " CFA";
      }
    },
    viewAssureur(assureur) {
      if (assureur) return assureur.societe;
    },
    viewBranche(brancheid) {
      if (brancheid) return brancheid.branche;
    },
    viewClient(contrat) {
      if (contrat.user) return contrat.user.nom + " - " + contrat.user.prenom;
    },
    viewPolice(contrat) {
      if (contrat) return contrat.numero_police;
    },
    viewPrime(contrat) {
      if (contrat) return contrat.prime_nette + " CFA";
    },

    toggleDetails(item) {
      this.detailcommission = item;
      this.showcommission = true;
    },
    toggleCommission(item) {
      item.commission = Math.round(item.commission);
      item.accessoires_courtier = Math.round(item.accessoires_courtier);
      this.detailcommission = item;
      this.editCommission = true;
    },
    SetDetailUser(details) {
      this.detailsUser = details
      this.showUser = true
    },
    onChange: function (e) {
      this.recherche = true;
    },
    customLabel({ societe }) {
      return `${societe}`;
    },
    goFilter: async function () {
      if (this.form.date.length > 1) {

        const response = await Api().post("borderaux/filtreContratCommission", {
          debut_periode: this.form.date[0],
          fin_periode: this.form.date[1],
          partenaireid: this.form.assureurs,
          brancheid: this.form.branches,
        });
        if (response.data.length != 0) {
          this.recherche = true;
          this.checkfiltre = true;
          this.listcontrat = response.data;
          this.$message.success("voici la liste les contrats filtrés !", this.AlertMessage)
        } else {
          this.recherche = false;
          this.checkfiltre = false;
          this.$message.error("Aucun resultat ne correspond a votre requete !", this.AlertMessage)
        }
      } else {
        this.recherche = false;
        this.checkfiltre = false;
        this.$message.error("Tout les données sont obligatoires!", this.AlertMessage)
      }
    },

    exportBordereaux: async function () {
      if (
        this.form.description != "" &&
        this.form.date.length > 1 
      ) {
        this.modalgenerationloading = true;
        Api()
          .post("borderaux/exportBorderauxCommission", {
            debut_periode: this.form.date[0],
            description: this.form.description,
            fin_periode: this.form.date[1],
            partenaireid: this.form.assureurs, //,
            brancheid: this.form.branches,
          })
          .then((resultat) => {
            let url = resultat.data.lien;
            setTimeout(() => ((
              this.modalgenerationloading = false,
              this.dialogBordereaux = false,
              this.checkfiltre = true,
              this.$message.success("les données sont exportées !", this.AlertMessage),
              this.reset()
            ), window.open(url)), 3000);
          })
          .catch((err) => {
            this.modalgenerationloading = false
            this.$message.error("Le filtre a échoué !", this.AlertMessage)
          })
      } else {
        this.modalgenerationloading = false
        this.$message.error("Tous les champs sont obligatoires!", this.AlertMessage)
      }
    },
    reset() {
      this.form = {
        date: [],
        branches: [],
        assureurs: [],
        description: "",
      };
    },
    getBranches: async function () {
      const assureurs = await Api().get("/branche");
      if (assureurs) {
        this.listbranche = assureurs.data;
        for (let i = 0; i < this.listbranche.length; i++)
          this.brancheOptions.push(this.listbranche[i]);
      } else {
        this.$message.error("Erreur lors de la récuperation des branches !", this.AlertMessage)
      }
    },
    getAssureurs: async function () {
      const assureurs = await Api().get("/partenaire/assureur");
      if (assureurs) {
        this.listassureur = assureurs.data.partenaires;
        //console.log(this.listassureur)
        for (let i = 0; i < this.listassureur.length; i++) {
          this.assureurOptions.push(this.listassureur[i]);
        }
      } else {
        this.$toasted.show(
          "Erreur lors de la récuperation des assueurs !",
          this.ToastTabError
        );
      }
    },
    getContrat: async function () {
      Api()
        .get("/borderaux/listeContratComission")
        .then((resultat) => {
          this.listcontrat = resultat.data;
        });
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000
      });
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function (e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function (value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getBranches();
      this.getAssureurs();
      this.getContrat();
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {

      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }

          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      &>* {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
