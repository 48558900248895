<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste contrats <span style="color:#ffa409">reversement</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>
    <div class="card-form card-base">
      <div class="toolbar-box flex align-center" v-if="!isMobile">
        <div class="box grow m-10 mt-5">
          <el-row :gutter="24">
            <el-col :span="6">
              <div class="grid-content bg-purple mr-5">
                <span>Periode <el-text tag="b" size="small" type="danger">*</el-text></span>
                <el-date-picker style="width: 100%!important;" v-model="form.date" type="daterange" range-separator="à"
                  start-placeholder="Date de début" end-placeholder="Date de fin" format="dd-MM-yyyy" @change="onChange"
                  size="small">
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple-light">
                <label>Assureur(s) </label>
                <el-select style="width: 100%!important;" v-model="form.assureurs" multiple filterable allow-create
                  default-first-option placeholder="Selectionner assureur(s)" collapse-tags :max-collapse-tags="4"
                  size="small">
                  <el-option v-for="items in assureurOptions" :key="items" :label="items.societe" :value="items.id">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <label>Branche(s) </label>
                <el-select style="width: 100%!important;" v-model="form.branches" multiple filterable allow-create
                  default-first-option placeholder="Selectionner branche(s)" collapse-tags :max-collapse-tags="4"
                  size="small">
                  <el-option v-for="items in brancheOptions" :key="items" :label="items.branche" :value="items._id">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <label>Civilité</label>
                <el-select style="width: 100%!important;" v-model="form.citysen" multiple filterable allow-create
                  default-first-option placeholder="Selectionner etat(s)" collapse-tags :max-collapse-tags="4" 
                  size="small" >
                  <el-option v-for="items in citysens" :key="items" :label="items" :value="items">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">
                <label class="mb-5">Reversé</label>
                <el-select style="width: 100%!important;" v-model="form.etat" multiple filterable allow-create
                  default-first-option placeholder="Selectionner etat(s)" collapse-tags :max-collapse-tags="4" 
                  size="small">
                  <el-option v-for="items in etat_reversements" :key="items" :label="items" :value="items">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="2">
              <div  class="grid-content bg-purple mt-15">
                <el-button size="small"  @click="goFilter()" :loading="modalgeneration" type="primary"
                  round>Valider</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="toolbar-box flex align-center" v-if="isMobile">
        <div class="box grow m-10 mt-5">
          <el-row :gutter="24">
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <label>Selectionner l'assureur(s)</label>
                <el-select style="width: 100%!important;" v-model="form.assureurs" multiple filterable allow-create
                  default-first-option placeholder="Selectionner assureur(s)" collapse-tags :max-collapse-tags="4">
                  <el-option v-for="items in assureurOptions" :key="items" :label="items.societe" :value="items.id">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <label>Selectionner la branche(s)</label>
                <el-select style="width: 100%!important;" v-model="form.branches" multiple filterable allow-create
                  default-first-option placeholder="Selectionner branche(s)" collapse-tags :max-collapse-tags="4">
                  <el-option v-for="items in brancheOptions" :key="items" :label="items.branche" :value="items._id">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="grid-content bg-purple-light mt-20">
                <el-button class="float-right" @click="goFilter()" type="primary" round>Valider</el-button>
              </div>
            </el-col>
          </el-row>

        </div>
      </div>

    </div>

    <resize-observer @notify="handleResize" />
    <div class="mb-5 mt-10">
      <el-row :gutter="15">
        <el-col :span="18">
          <el-button size="small" type="primary" round :disabled="!checkfiltre" @click="dialogBordereaux = true">
            Exporter en Excel
          </el-button>
          <el-button size="small" type="primary" round @click="modalManyReversements()"> Plusieurs reversements</el-button>
          <el-button size="small" type="primary" :disabled="!activeProduction" v-loading.fullscreen.lock="modalgenerationloading"
            round @click="export_production()">
            Export Production
          </el-button>
        </el-col>
        <el-col :span="6">
          <el-input size="small" placeholder="Rechercher ..." prefix-icon="el-icon-search" v-model="search" clearable>
          </el-input>
        </el-col>
      </el-row>


    </div>

    <div class="mb-5 table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready"
      v-if="!isMobile">
      <el-table v-loading="loading" :data="listInPage" style="width: 100%" v-if="ready" :height="height"
        @selection-change="handleSelectionChange">
        <el-table-column label="Select" fixed min-width="80">
          <template slot-scope="scope">
            <el-checkbox :disabled="!checkfiltre" @input="onPreviewClick($event, scope.row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="Numero avenant" min-width="140" prop="numero_avenant" fixed></el-table-column>
        <el-table-column label="type avenant" min-width="180" prop="type_avenant"></el-table-column>
        <el-table-column label="type prime" min-width="160" prop="type_prime"></el-table-column>

        <el-table-column label="Client" min-width="200" prop="client">
          <template slot-scope="scope">
            <span class="date">
              <a href="#" @click="SetDetailUser(scope.row.user)">
                {{ scope.row.user.prenom || null }} - {{ scope.row.user.nom || null }}
              </a>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Avenant" min-width="180" prop="type_avenant"></el-table-column>

        <el-table-column label="Assureur" min-width="150" prop="partenaireid">
          <template slot-scope="scope">
            {{ scope.row.partenaireid.societe || null }}
          </template>
        </el-table-column>
        <el-table-column label="Branche" min-width="120" prop="brancheid">
          <template slot-scope="scope">
            {{ scope.row.brancheid.branche || null }}
          </template>
        </el-table-column>
        <el-table-column label="Numéro police" min-width="180" prop="numero_police"></el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date Souscription" min-width="150" prop="dateSouscription">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime NETTE" min-width="150" prop="prime_nette">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.prime_nette.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Accessoires" min-width="150" prop="accessoires">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.accessoires.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Taxe" min-width="150" prop="taxe">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.taxe.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.prime_ttc.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Reversement" prop="etat_reversement" min-width="150">
          <template slot-scope="scope">
            <b>
              <span :style="
              scope.row.etat_reversement == 'Non'
                  ? 'color : red;'
                  : scope.row.etat_reversement == 'Oui' 
                  ? 'color : green;' 
                  : 'color : orange;' 
                ">
                {{ scope.row.etat_reversement }}
              </span>
            </b>
          </template>
        </el-table-column>
        <el-table-column label="Mode reversement" min-width="150" prop="mode_reversement"></el-table-column>
        <el-table-column label="Numéro" min-width="150" prop="num_cheque_reversement"></el-table-column>
        <el-table-column label="Date reversée" min-width="150" prop="dateSouscription">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.date_reversement) }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="140">
          <template slot-scope="scope">
            <el-button size="mini" @click="toggleDetails(scope.row)" type="primary" icon="el-icon-view"
              circle></el-button>
            <el-button size="mini" type="primary" @click="toggleReversement(scope.row)" icon="el-icon-edit"
              circle></el-button>
            <el-button size="mini" type="info" @click="confirmReinitialiser(scope.row)" icon="el-icon-refresh-right"
              circle></el-button>
          </template>
        </el-table-column>

      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
        :total="total"></el-pagination>
    </div>

    <el-dialog title="Export un bordereaux en format excel" width="50%" :visible.sync="dialogBordereaux"
      v-if="dialogBordereaux == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div>
            <el-input placeholder="Description du bordereau..." type="textarea" v-model="form.description"></el-input>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="exportBordereaux()"
          v-loading.fullscreen.lock="loading">Exporter</el-button>
        <el-button type="danger" round @click="dialogBordereaux = false">Fermer</el-button>
      </div>
    </el-dialog>

    <el-dialog width="70%" title="Client" :visible.sync="showUser" v-if="showUser == true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%" title="Client" :visible.sync="showUser" v-if="showUser == true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%!important" title="Formulaire de reversement " :visible.sync="editReversement"
      v-if="editReversement == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-row style="margin-top: 15px" :gutter="15">
            <el-col :span="12">
              <div>
                <label>Etat Reversement</label>
                <el-select style="width: 100% !important" v-model="detailReversement.etat_reversement"
                  placeholder="Selectionner ---">
                  <el-option value="Non">Non</el-option>
                  <el-option value="Oui">Oui</el-option>
                  <el-option value="En attente">En attente</el-option>
                </el-select>
              </div>
            </el-col>

            <el-col :span="12" class="mt-5">
              <div>
                <label>Mode reversement</label>
                <el-select style="width: 100% !important" v-model="detailReversement.mode_reversement"
                  placeholder="Selectionner ---" @change="updateLabelDate()">
                  <el-option value="Chéque"></el-option>
                  <el-option value="Virement"></el-option>
                  <el-option value="Autre"></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 15px" :gutter="15" v-if="detailReversement.mode_reversement == 'Autre'">
            <el-col :span="12" class="mt-5">
              <div>
                <label>Mode de paiment</label>
                <el-input v-model="AUTRES" placeholder="Mode de paiment ..." class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mt-5">
              <div>
                <label>Numéro</label>
                <el-input v-model="detailReversement.num_cheque_reversement" placeholder="Numero du reçu ..."
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" style="margin-top: 15px" class="mt-5">
              <div>
                <label>Date {{ labelDate }}</label><br />
                <el-date-picker v-model="detailReversement.date_reversement" type="date" format="dd/MM/yyyy"
                  :picker-options="dateBeforeToday" placeholder="Date reversée" class="input-with-select" />
              </div>
            </el-col>

          </el-row>
          <el-row style="margin-top: 15px" :gutter="15" v-else>
            <el-col :span="12" class="mt-5" v-if="detailReversement.mode_reversement != 'Virement'">
              <div>
                <label>Numéro</label>
                <el-input v-model="detailReversement.num_cheque_reversement" placeholder="Numéro  ..."
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mt-5"
              v-if="detailReversement.mode_reversement == 'Virement' || detailReversement.mode_reversement == 'Chéque'">
              <div>
                <label>Banque</label>
                <el-input v-model="detailReversement.banque_reversement" placeholder="banque"
                  class="input-with-select"></el-input>
              </div>
            </el-col>
            <el-col :span="12" class="mt-5">
              <div>
                <label>Date {{ labelDate }}</label><br />
                <el-date-picker v-model="detailReversement.date_reversement" type="date" format="dd/MM/yyyy"
                  :picker-options="dateBeforeToday" placeholder="Date reversée" class="input-with-select" />
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="goReversement()">Reverser</el-button>
          <el-button type="danger" round @click="editReversement = false">Annuler</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog width="70%!important" title="Formulaire de reversement " :visible.sync="editManyReversement"
      v-if="editManyReversement == true">
      <el-card class="box-card">
        <div style="text-align: center">
          <el-transfer :titles="['Liste des contrats', 'Á reversé']" filterable :button-texts="['Retirer', 'Ajouter']"
            style="text-align: left; display: inline-block;" :filter-method="filterMethod"
            filter-placeholder="filtre ..." v-model="listreversement" :data="newlistcontrat">
          </el-transfer>
        </div>
      </el-card>
      <el-card class="mt-10">
        <el-row :gutter="15">
          <el-col :span="8" class="mt-5">
            <div>
              <label>Etat Reversement</label>
              <el-select style="width: 100% !important" v-model="reversement.etat_reversement"
                placeholder="Selectionner ---">
                <el-option value="Non">Non</el-option>
                <el-option value="Oui">Oui</el-option>
                <el-option value="En attente">En attente</el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="8" class="mt-5">
            <div>
              <label>Mode reversement</label>
              <el-select style="width: 100% !important" v-model="reversement.mode_reversement"
                placeholder="Selectionner ---" @change="updateLabelDate()">
                <el-option value="Chéque"></el-option>
                <el-option value="Virement"></el-option>
                <el-option value="Autre"></el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="8" class="mt-5" v-if="reversement.mode_reversement == 'Chéque'">
            <div>
              <label>Numéro</label>
              <el-input v-model="reversement.num_cheque_reversement" placeholder="Numéro ..."
                class="input-with-select"></el-input>
            </div>
          </el-col>
          <el-col :span="8" class="mt-5" v-if="reversement.mode_reversement == 'Autre'">
            <div>
              <label>Mode de paiment</label>
              <el-input v-model="AUTRES" placeholder="Mode de paiment ..." class="input-with-select"></el-input>
            </div>
          </el-col>

          <el-col :span="8" class="mt-5" v-if="reversement.mode_reversement == 'Autre'">
            <div>
              <label>Numéro</label>
              <el-input v-model="reversement.num_cheque_reversement" placeholder="Numéro du reçu ..."
                class="input-with-select"></el-input>
            </div>
          </el-col>

          <el-col :span="8" class="mt-5"
            v-if="reversement.mode_reversement == 'Virement' || reversement.mode_reversement == 'Chéque'">
            <div>
              <label>Banque</label>
              <el-input v-model="reversement.banque_reversement" placeholder="banque"
                class="input-with-select"></el-input>
            </div>
          </el-col>
          <el-col :span="8" class="mt-5">
            <div>
              <label>Date {{ labelDate }}</label><br />
              <el-date-picker v-model="reversement.date_reversement" type="date" format="dd/MM/yyyy"
                :picker-options="dateBeforeToday" placeholder="Date reversée" />
            </div>
          </el-col>
        </el-row>
      </el-card>
      <div class="mb-5" style="margin-top: 15px">
        <el-button type="primary" round @click="goManyReversement()">Valider</el-button>
        <el-button type="danger" round @click="editManyReversement = false">Annuler</el-button>
      </div>

    </el-dialog>

    <el-dialog width="50%" title="Détails" :visible.sync="showreversement">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style: none">
                  <li>
                    Assureur :
                    <span class="to-right assuraf-orange">{{
                      viewAssureur(detailReversement.partenaireid)
                      }}</span>
                  </li>
                  <li>
                    Branche :
                    <span class="to-right assuraf-orange">
                      {{ viewBranche(detailReversement.brancheid) }}
                    </span>
                  </li>

                  <li>
                    Contrat :
                    <span class="to-right assuraf-orange">{{
                      viewPolice(detailReversement)
                      }}</span>
                  </li>

                  <li>
                    Prime nette:
                    <span class="to-right assuraf-orange">{{
                      viewPrime(detailReversement)
                      }}</span>
                  </li>

                  <li>
                    Date d'éffet :
                    <span class="to-right assuraf-orange">{{
                      DateFormater(detailReversement.dateEffet)
                      }}</span>
                  </li>

                  <li>
                    Date d'échéance :
                    <span class="to-right assuraf-orange">{{
                      DateFormater(detailReversement.dateEcheance)
                      }}</span>
                  </li>

                  <li>
                    Date de création :
                    <span class="to-right assuraf-orange">{{
                      DateFormater(detailReversement.dateSouscription)
                      }}</span>
                  </li>
                  <li>
                    <el-divider />
                  </li>

                  <li>
                    Etat reversement :
                    <span class="to-right" :style="detailReversement.etat_reversement == 'Non'
                      ? 'color : red;'
                      : 'color : green;'
                      ">
                      {{
                        detailReversement.etat_reversement != undefined ?
                          detailReversement.etat_reversement :
                          null
                      }}</span>
                  </li>
                  <li>
                    Mode de reversement :
                    <span class="to-right">{{
                      detailReversement.mode_reversement != undefined ? detailReversement.mode_reversement : null
                      }} </span>
                  </li>
                  <li v-if="detailReversement.mode_reversement != 'Virement'">
                    Numéro :
                    <span class="to-right">{{
                      detailReversement.num_cheque_reversement != undefined ?
                        detailReversement.num_cheque_reversement : null
                    }}</span>
                  </li>
                  <li
                    v-if="detailReversement.mode_reversement == 'Virement' || detailReversement.mode_reversement == 'Chéque'">
                    Banque:
                    <span class="to-right">{{
                      detailReversement.banque_reversement != undefined ? detailReversement.banque_reversement :
                        null
                    }}</span>
                  </li>
                  <li>
                    Date:
                    <span class="to-right">{{
                      detailReversement.date_reversement != undefined ?
                        DateFormater(detailReversement.date_reversement) : null
                    }}</span>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%!important" title="Voulez-vous vraiment réinitialiser le reversement ?"
      :visible.sync="initReversement" v-if="initReversement == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div style="margin-left: -40px !important">
            <ul style="list-style: none">

              <li>
                Contrat :
                <span class="to-right assuraf-orange">{{
                  viewPolice(detailReversement)
                  }}</span>
              </li>

              <li>
                <el-divider />
              </li>
              <li>
                Etat reversement :
                <span class="to-right" :style="detailReversement.etat_reversement == 'Non'
                  ? 'color : red;'
                  : 'color : green;'
                  ">
                  {{
                    detailReversement.etat_reversement != undefined ?
                      detailReversement.etat_reversement :
                      null
                  }}</span>
              </li>
              <li>
                Mode de reversement :
                <span class="to-right">{{
                  detailReversement.mode_reversement != undefined ? detailReversement.mode_reversement : null
                  }} </span>
              </li>
              <li v-if="detailReversement.mode_reversement != 'Virement'">
                Numéro :
                <span class="to-right">{{
                  detailReversement.num_cheque_reversement != undefined ?
                    detailReversement.num_cheque_reversement : null
                }}</span>
              </li>
              <li
                v-if="detailReversement.mode_reversement == 'Virement' || detailReversement.mode_reversement == 'Chéque'">
                Banque:
                <span class="to-right">{{
                  detailReversement.banque_reversement != undefined ? detailReversement.banque_reversement :
                    null
                }}</span>
              </li>
              <li>
                Date:
                <span class="to-right">{{
                  detailReversement.date_reversement != undefined ?
                    DateFormater(detailReversement.date_reversement) : null
                }}</span>
              </li>
            </ul>
          </div>
        </el-card>
        <div class="mb-5" style="margin-top: 15px">
          <el-button type="primary" round @click="goReversementReinitialiser()">Oui</el-button>
          <el-button type="danger" round @click="initReversement = false">Non</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months priore
    const maxDate = new Date(today);

    return {
      modalgenerationloading: false,
      editManyReversement: false,
      newlistcontrat: [],
      listreversement: [],
      dateBeforeToday: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      filterMethod(query, item) {
        return item.initial.toLowerCase().indexOf(query.toLowerCase()) > -1;
      },
      reversement: {
        etat_reversement: '',
        mode_reversement: '',
        banque_reversement: '',
        num_cheque_reversement: '',
        date_reversement: '',
      },
      checkfiltre: false,
      activeProduction: false,
      detailReversement: {},
      editReversement: false,
      showreversement: false,
      reversements: [],
      dialogBordereaux: false,
      listbranche: [],
      listassureur: [],
      count: 0,
      max: maxDate,
      recherche: false,
      totalRows: null,
      initReversement: false,
      perPage: 10,
      listuser: null,
      listcontrat: [],
      filter: null,
      showUser: false,
      form: {
        date: [],
        branches: [],
        assureurs: [],
        description: "",
        etat: ["Oui", "Non", "En attente"],
        citysen: ["homme", "femme","entreprise"],
      },
      etat_reversements: ["Oui", "Non", "En attente"],
      citysens: ["homme", "femme","entreprise"],
      assureurOptions: [],
      brancheOptions: [],
      listbranche: [],
      detailbranche: [],
      showpack: false,
      nombranche: null,

      modalgeneration: false,
      detailsDevis: [],
      showdevis: false,
      pageOptions: [5, 10, 15],
      modalInfo: {
        title: '',
        content: ''
      },
      fletteRows: null,
      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      AUTRES: "",

      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listbranche,//users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() {
      if (this.search.length >= 3) {
        return this.listcontrat.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
              ctrl = true
              /*on le push dans le tableau */
              //this.listcontrat.push(obj)
            } else {
              /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
              if (Array.isArray(obj[k])) {
                obj[k].filter(nobj => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
                      ctrl = true
                      /* on le push dans le tableau */
                      //this.listcontrat.push(obj)
                    }
                  }
                })
                /* Si la valeur courante n'est ni String, ni Tableau mais Object */
              } else if (!Array.isArray(obj[k]) && typeof obj[k] == "object" && obj[k] != null) {
                /*== On crée un nouveau tablequ vide ==*/
                let my_new_array = []

                /*== On push la valeur courante dans le nouveau tableau ==*/
                my_new_array.push(obj[k])

                /*On refiltre le nouveau tableau atrribut par attribut*/
                my_new_array.filter(nobj => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
                      ctrl = true

                      /* on le push dans le tableau */
                      //this.listcontrat.push(obj)
                    }
                  }
                })
              }
            }
          }
          return ctrl
        })
      } else {
        return this.listcontrat.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
              ctrl = true
          }
          return ctrl
        })
      }

    },
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = ""
          let val2 = ""

          val1 = item1[prop]
          val2 = item2[prop]
          if (order === "descending") {
            return val2 < val1 ? -1 : 1
          }
          return val1 < val2 ? -1 : 1
        })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    },
  },
  watch: {
    isBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs["my-table"].$el;
        if (newVal === true) {
          tableScrollBody.classList.add("overflow-hidden");
        } else {
          tableScrollBody.classList.remove("overflow-hidden");
        }
      }
    },

    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {

    async export_production() {
      if (this.form.date.length > 1 ) {
        this.modalgenerationloading = true
        const response = await Api().post("borderaux/export/production/auto", {
          debut_periode: this.form.date[0],
          fin_periode: this.form.date[1],
          partenaireid: this.form.assureurs,
          brancheid: this.form.branches,
          etat: this.form.etat,
        }).then((result) => {

          let url = result.data.lien
          setTimeout(() => (
            this.modalgenerationloading = false,
            window.open(url)), 7000
          );
        }).catch((err) => {
          this.$message.error(
            "Verifié les informations saisies !",
            this.AlertMessage
          );
          this.modalgenerationloading = false
        });
      } else {
        this.modalgenerationloading = false
        this.$message.error(
          "Verifié les informations saisies !",
          this.AlertMessage
        );
      }
    },

    updateLabelDate() {
      if (this.reversement.mode_reversement == "Virement" || this.detailReversement.mode_reversement == "Virement") {
        this.labelDate = "du virement"
      }
      if (this.reversement.mode_reversement == "Chéque" || this.detailReversement.mode_reversement == "Chéque") {
        this.labelDate = "du chéque"
      }
      if (this.reversement.mode_reversement == "Autre" || this.detailReversement.mode_reversement == "Autre") {
        this.labelDate = "de reversement"

      }

    },

    modalManyReversements() {
      this.newlistcontrat = []
      this.listreversement = []
      this.listcontrat.forEach((contrat, index) => {
        if (contrat.etat_reversement && contrat.etat_reversement.toUpperCase() == "NON") {
          this.newlistcontrat.push({
            label: contrat.numero_police,
            key: contrat._id,
            initial: contrat.numero_police
          });
        }
      });
      this.editManyReversement = true;
    },
    goManyReversement() {

      if (
        this.reversement.etat_reversement &&
        this.reversement.mode_reversement &&
        this.reversement.date_reversement &&
        this.listreversement.length != 0
      ) {
        if (this.reversement.mode_reversement == 'Autre')
          this.reversement.mode_reversement = this.AUTRES

        Api()
          .post("/borderaux/updatemany/reversement", {
            _id: this.listreversement,
            etat_reversement: this.reversement.etat_reversement,
            mode_reversement: this.reversement.mode_reversement,
            num_cheque_reversement: this.reversement.num_cheque_reversement,
            banque_reversement: this.reversement.banque_reversement,
            date_reversement: this.reversement.date_reversement
          })
          .then((result) => {
            this.reversement = {
              etat_reversement: '',
              mode_reversement: '',
              banque_reversement: '',
              num_cheque_reversement: '',
              date_reversement: '',
            }
            this.AUTRES = ""
            this.$message.success(
              "Operation effectuée avec succés !",
              this.AlertMessage
            );
            this.editManyReversement = false;
            this.getContrat();
          })
          .catch((err) => {
            this.$message.error(
              "Verifié les informations saisies !",
              this.AlertMessage
            );
            console.log(err);
          });
      } else {
        this.$message.error(
          "Verifié les informations saisies !",
          this.AlertMessage
        );
      }
    },

    goReversementReinitialiser() {

      Api()
        .post("/borderaux/update/reversement", {
          _id: this.detailReversement._id,
          etat_reversement: "Non",
          mode_reversement: "",
          num_cheque_reversement: "",
          banque_reversement: "",
          date_reversement: ""
        }
        )
        .then((result) => {
          this.$message.success(
            "Operation effectuée avec succés !",
            this.AlertMessage
          );
          this.initReversement = false;
          this.getContrat();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goReversement() {
      if (
        this.detailReversement.etat_reversement &&
        this.detailReversement.mode_reversement &&
        this.detailReversement.date_reversement
      ) {
        if (this.detailReversement.mode_reversement == 'Autre')
          this.detailReversement.mode_reversement = this.AUTRES
        Api()
          .post("/borderaux/update/reversement", {
            _id: this.detailReversement._id,
            etat_reversement: this.detailReversement.etat_reversement,
            mode_reversement: this.detailReversement.mode_reversement,
            num_cheque_reversement: this.detailReversement.num_cheque_reversement,
            banque_reversement: this.detailReversement.banque_reversement,
            date_reversement: this.detailReversement.date_reversement
          }
          )
          .then((result) => {
            this.AUTRES = ""
            this.$message.success(
              "Operation effectuée avec succés !",
              this.AlertMessage
            );
            this.editReversement = false;
            this.getContrat();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.error(
          "Verifié les informations saisies!",
          this.AlertMessage
        );
      }
    },
    // view

    round(val) {
      if (val != undefined)
        return Math.round(val).toLocaleString() + " CFA";
    },
    totalRevenu(item) {
      if (item.commission != undefined) {
        let total = Math.round(item.commission) + Math.round(item.accessoires_courtier);
        return Math.round(total).toLocaleString() + " CFA";
      }
    },
    viewAssureur(assureur) {

      if (assureur) return assureur.societe;
    },
    viewBranche(brancheid) {
      if (brancheid) return brancheid.branche;
    },
    viewClient(contrat) {
      if (contrat.user) return contrat.user.nom + " - " + contrat.user.prenom;
    },
    viewPolice(contrat) {
      if (contrat) return contrat.numero_police;
    },
    viewPrime(contrat) {
      if (contrat) return contrat.prime_nette + " CFA";
    },

    confirmReinitialiser(item) {
      this.detailReversement = item;
      this.initReversement = true;
    },

    toggleReversement(item) {
      this.detailReversement = item;
      this.editReversement = true;
    },
    toggleDetails(item) {
      this.detailReversement = item;
      this.showreversement = true;
    },

    SetDetailUser(details) {
      this.detailsUser = details
      this.showUser = true
    },
    onChange: function (e) {
      this.recherche = true;
    },
    customLabel({ societe }) {
      return `${societe}`;
    },
    goFilter: async function () {
      try {
        this.modalgeneration = true;
      if (this.form.date.length > 1) {
        const response = await Api().post("borderaux/filtreContractReversement", {
          debut_periode: this.form.date[0],
          fin_periode: this.form.date[1],
          partenaireid: this.form.assureurs,
          brancheid: this.form.branches,
          etat: this.form.etat,
          citysen: this.form.citysen,    
        });
        if (response.data.length != 0) {
          this.modalgeneration = false;
          this.recherche = true;
          this.checkfiltre = true;
          this.listcontrat = []
          this.listcontrat = response.data;
          this.activeProduction = this.form.branches.length === 1 ? true : false;
          this.$message.success("voici la liste les contrats filtrés !", this.AlertMessage)
        } else {
          this.recherche = false;
          this.checkfiltre = false;
          this.modalgeneration = false;
          this.$message.error("Aucun resultat ne correspond a votre requete !", this.AlertMessage)
        }
      } else {
        this.recherche = false;
        this.checkfiltre = false;
        this.modalgeneration = false;

        this.$message.error("Tous les champs sont obligatoires!", this.AlertMessage)
      }
      } catch (error) {
        this.recherche = false;
        this.checkfiltre = false;
        this.modalgeneration = false;
        this.$message.error("error server !", this.AlertMessage)

      }
      
    },
    exportBordereaux: async function () {
      if (
        this.form.description &&
        this.form.date.length > 1
      ) {
        this.loading = true;
        if (this.reversements.length == 0) {
          this.reversements = this.listcontrat
        }
        const response = await Api().post("borderaux/filtreReversement", {
          debut_periode: this.form.date[0],
          fin_periode: this.form.date[1],
          description: this.form.description,
          partenaireid: this.form.assureurs,
          brancheid: this.form.branches,
          etat: this.form.etat,
          contratid: this.reversements,
          citysen: this.form.citysen,  
        });

        if (response.data.lien) {
          let url = response.data.lien;
          setTimeout(() => ((
            this.loading = false,
            this.dialogBordereaux = false,
            this.checkfiltre = false,
            this.reversements = [],
            this.$message.success("les données sont exportées !", this.AlertMessage),
            this.getContrat(),
            this.reset()
          ), window.open(url)), 5000);

        } else {
          this.loading = false;
          this.loading = false
          this.$message.error("Le filtre a échoué !", this.AlertMessage)

        }
      } else {
        this.loading = false
        this.$message.error("Tout les données sont obligatoires!", this.AlertMessage)
      }
    },
    reset() {
      this.reversements = [];
      this.form = {
        date: [],
        branches: [],
        assureurs: "",
        description: "",
        etat: ["Oui", "Non", "En attente"],
        citysen: this.citysens,  
      };
    },
    getBranches: async function () {
      const assureurs = await Api().get("/branche");
      if (assureurs) {
        this.listbranche = assureurs.data;
        for (let i = 0; i < this.listbranche.length; i++)
          this.brancheOptions.push(this.listbranche[i]);
      } else {
        this.$message.error("Erreur lors de la récuperation des branches !", this.AlertMessage)
      }
    },

    getAssureurs: async function () {
      const assureurs = await Api().get("/partenaire/assureur");
      if (assureurs) {
        this.listassureur = assureurs.data.partenaires;
        for (let i = 0; i < this.listassureur.length; i++) {
          this.assureurOptions.push(this.listassureur[i]);
        }
      } else {
        this.$message.error("Erreur lors de la récuperation des assueurs !", this.AlertMessage)
      }
    },
    getContrat: async function () {
      Api()
        .get("/borderaux/listeContratComission")
        .then((resultat) => {
          this.listcontrat = resultat.data;
        });
    },
    customLabel({ nom, prenom, telephone, photo }) {
      return `${nom} – ${prenom} – ${telephone}`;
    },
    onPreviewClick(value, item) {

      if (value === true) {
        this.reversements.push(item._id);
      } else {
        this.reversements.splice(this.reversements.indexOf(item._id), 1);
        return false;
      }
    },
    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000
      });
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function (e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function (value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getBranches();
      this.getAssureurs();
      this.getContrat();
      // this.getClients()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<!-- <style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style> -->

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {

      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }

          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      &>* {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
