<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste des contrats<span style="color:#ffa409"> encours</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input placeholder="Rechercher contrat encours..." prefix-icon="el-icon-search" v-model="search" clearable>
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input placeholder="Rechercher contrat encours..." prefix-icon="el-icon-search" v-model="search" clearable>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <div class="toolbar-box flex align-center" style="margin-top: -15px" v-if="!isMobile">
      <div class="box grow">
        <h3>Recherche par période de création</h3>
        <el-date-picker v-model="du" type="date" format="yyyy/MM/dd" placeholder="Choississez une date">
        </el-date-picker>
        <el-date-picker style="margin-left:15px" v-model="au" type="date" format="yyyy/MM/dd"
          placeholder="Choississez une date">
        </el-date-picker>
        <el-button class="button-filtre" type="primary" @click="FiltreDate" icon="el-icon-search" circle></el-button>
      </div>
    </div>
    <div class="toolbar-box flex align-center" style="margin-top: -15px" v-if="isMobile">
      <div class="box grow">
        <h3>Recherche par période de création</h3>
        <el-date-picker v-model="du" type="date" format="yyyy/MM/dd" placeholder="Choississez une date">
        </el-date-picker>
        <el-date-picker style="margin-top:15px" v-model="au" type="date" format="yyyy/MM/dd"
          placeholder="Choississez une date">
        </el-date-picker>
        <el-button class="button-filtre" type="primary" @click="FiltreDate" icon="el-icon-search" circle></el-button>
      </div>
    </div>

    <resize-observer @notify="handleResize" />

    <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
      <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading"
        @click="exportToExcel()">Exporter
        en Excel</el-button>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table :data="listInPage" style="width: 100%" :height="height" v-if="ready" @selection-change="handleSelectionChange">
        <el-table-column label="Date Souscription" fixed min-width="150" prop="dateSouscription">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Emission" min-width="150" prop="emission">
          <template slot-scope="scope">
            <span v-if="scope.row.emission" class="date"> <el-tag type="success" size="small" effect="light">Emis </el-tag> </span>
            <span v-else class="date"> <el-tag type="danger" size="small" effect="light">Non emis </el-tag></span>
          </template>
        </el-table-column>
        <el-table-column label="Client" min-width="200" prop="client">
          <template slot-scope="scope">
            <span class="date"> <a href="#" @click="SetDetailUser(scope.row.user)">{{ scope.row.user.prenom }} -
                {{ scope.row.user.nom }}</a></span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro police" min-width="180" prop="numero_police"></el-table-column>
        <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Plateforme" min-width="150" prop="plateforme">
          <template slot-scope="scope">
            <span class="date" style="color:#ffa409">{{ scope.row.plateforme }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Code Partenaire" min-width="150" prop="codep">
          <template slot-scope="scope">
            <span class="date" style="color:#ffa409">{{ scope.row.codep }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro flotte" min-width="150" prop="reference_flotte">
          <template slot-scope="scope">
            <span class="date" style="color:#ffa409">{{ scope.row.reference_flotte }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid">
          <template slot-scope="scope" >
            <span class="date">{{ scope.row.partenaireid.societe }}</span>
          </template>
          <!-- <template slot-scope="scope" v-else>
            <span class="date">{{ scope.row.partenaireid.societe.substring(0, 10) + '...' }}</span>
          </template> -->
        </el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.prime_ttc.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC Avant" min-width="150" prop="prime_avant">
          <template slot-scope="scope">
            <span class="date" v-if="scope.row.prime_avant">{{ scope.row.prime_avant.toLocaleString() || 0 }} CFA</span>
            <span class="date" v-else> - </span>
          </template>
        </el-table-column>
        <el-table-column label="Partenaire" min-width="150" prop="courtierid.societe"></el-table-column>
        <el-table-column label="Créateur" min-width="150" prop="creator">
          <template slot-scope="scope" v-if="$store.state.user.role == 'Admin' || $store.state.user.role == 'Agent'">
            <span v-if="scope.row.createur" class="date">{{ scope.row.createur.prenom }} - {{ scope.row.createur.nom
              }}</span>
            <span v-else>
              Admin
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="180">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailcontrat(scope.row)" type="primary" icon="el-icon-view"
              circle title="Voir"></el-button>
            <el-button size="mini" v-loading.fullscreen.lock="modalgenerationloading"
              @click="downloadAttestationProvisoire(scope.row)" type="primary" icon="el-icon-download"
              circle title="Télécharger"></el-button>
            <!-- <el-button :disabled="scope.row.brancheid.branche != 'Automobile'" @click="Edit_devis(scope.row)"
              type="primary" icon="el-icon-edit" size="mini" circle title="Renouveller"></el-button> -->
            <el-button :disabled="scope.row.brancheid.branche != 'Automobile'" @click="dialogAvenant(scope.row)"
              type="primary" icon="el-icon-edit" size="mini" circle title="Avenant"></el-button>
            
              <el-button @click="dialogEmission(scope.row)" type="primary" icon="el-icon-check" size="mini" circle title="Emission">
              </el-button>

          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
        :total="total"></el-pagination>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table :data="listInPage" style="width: 100%" v-if="ready" @selection-change="handleSelectionChange">
        <el-table-column label="Date Souscription" min-width="150" prop="dateSouscription">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Client" min-width="200" prop="client">
          <template slot-scope="scope">
            <span class="date"> <a href="#" @click="SetDetailUser(scope.row.user)">{{ scope.row.user.prenom }} -
                {{ scope.row.user.nom }}</a></span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro police" min-width="180" prop="numero_police"></el-table-column>
        <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Plateforme" min-width="150" prop="plateforme">
          <template slot-scope="scope">
            <span class="date" style="color:#ffa409">{{ scope.row.plateforme }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Code Partenaire" min-width="150" prop="codep">
          <template slot-scope="scope">
            <span class="date" style="color:#ffa409">{{ scope.row.codep }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Numéro flotte" min-width="150" prop="reference_flotte">
          <template slot-scope="scope">
            <span class="date" style="color:#ffa409">{{ scope.row.reference_flotte }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.partenaireid.societe }}</span>
          </template>
          <!-- <template slot-scope="scope" v-else>
            <span class="date">{{ scope.row.partenaireid.societe.substring(0, 10) + '...' }}</span>
          </template> -->
        </el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.prime_ttc.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Partenaire" min-width="150" prop="courtierid.societe"></el-table-column>
        <el-table-column label="Créateur" min-width="150" prop="creator">
          <template slot-scope="scope" v-if="$store.state.user.role == 'Admin' || $store.state.user.role == 'Agent'">
            <span v-if="scope.row.createur" class="date">{{ scope.row.createur.prenom }} - {{ scope.row.createur.nom
              }}</span>
            <span v-else>
              Admin
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Actions" prop="action" min-width="130">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailcontrat(scope.row)" type="primary" icon="el-icon-view"
              circle></el-button>
            <el-button size="mini" v-loading.fullscreen.lock="modalgenerationloading"
              @click="downloadAttestationProvisoire(scope.row)" type="primary" icon="el-icon-download"
              circle></el-button>
            <el-button
              :disabled="scope.row.brancheid.branche == 'Micro-auto' || scope.row.brancheid.branche == 'Micro-habitation'"
              size="mini" v-loading.fullscreen.lock="modalgenerationloading" @click="go_express_payment(scope.row)"
              type="primary" icon="el-icon-check" circle title="Transformer en contrat"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
        :total="total"></el-pagination>
    </div>

    <el-dialog width="70%!important" title="Détails contrat" :visible.sync="showcontrat"
      v-if="showcontrat == true && !isMobile">
      <div class="mb-5">
        <el-button type="primary" round @click="JsonFormaterView()">Détails en JSON</el-button>
        <el-button type="primary" round @click="getCommentDetail()">Lire commentaires</el-button>
        <el-button type="primary" round @click="SetCommentDetail()">Ajouter un commentaire</el-button>
        <el-button type="primary" round @click="viewAvenant()">Voir Avenant(s)</el-button>
        <el-button type="primary" round @click="exportAsASACI()" v-loading.fullscreen.lock="modalgeneration">
          Générer le fichier ASACI</el-button>
        <el-button type="primary" v-if="detailsContrat.brancheid.branche == 'Assur-Hospi'" round
          @click="updateBeneficiaire()">Modifier bénéficiaire</el-button>
       
      </div>
      <div class="mb-5">
        <el-button type="primary" v-if="detailsContrat.brancheid.branche == 'Automobile'" round @click="gotoEditAttestationPage(detailsContrat)">Attestation Digitale</el-button>
      </div>
      <el-row :gutter="15">
        <el-col :span="14">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Date de Souscription : <span class="to-right assuraf-orange">{{
                    DateFormater(detailsContrat.dateSouscription) }}</span></li>
                  <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{ detailsContrat.numero_police
                      }}</span></li>
                  <li v-if="detailsContrat.brancheid.branche == 'Voyage' && detailsContrat.increment_voyage">
                    Numéro Increment Voyage : <span class="to-right assuraf-orange">{{ detailsContrat.increment_voyage
                      }}</span></li>
                  <li>Numéro Police Assureur : <span class="to-right assuraf-orange">{{ detailsContrat.police_assureur
                      }}</span></li>
                  <li>Date Effet : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.dateEffet)
                      }}</span>
                  </li>
                  <li>Date Echéance : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.dateEcheance)
                      }}</span></li>
                  <li>Prime Nette : <span class="to-right assuraf-orange">{{ detailsContrat.prime_nette.toLocaleString()
                      }}</span></li>
                  <li>Prime TTC : <span class="to-right assuraf-orange">{{ detailsContrat.prime_ttc.toLocaleString()
                      }}</span></li>
                  <li>Taxe : <span class="to-right assuraf-orange">{{ detailsContrat.taxe.toLocaleString() }}</span>
                  </li>
                  <li
                    v-if="detailsContrat.brancheid.branche == 'Automobile' && CheckIfTypeDefined(detailsContrat.devisid) != null">
                    FGA : <span class="to-right assuraf-orange"
                      v-if="detailsContrat.devisid != null && detailsContrat.devisid.fga != null">
                      {{ detailsContrat.devisid.fga.toLocaleString() }}
                    </span>
                  </li>
                  <li>Accessoires : <span class="to-right assuraf-orange">{{ detailsContrat.accessoires.toLocaleString()
                      }}</span></li>
                  <li v-if="detailsContrat.partner_creator != null">Partenaires : <span
                      class="to-right assuraf-orange">{{
                        detailsContrat.partner_creator }}</span></li>
                </ul>

                <el-divider content-position="center"><b>Attestation Digitale</b></el-divider>
                <ul style="list-style:none" v-if="detailsContrat.brancheid.branche == 'Automobile' && attestation_digital_data!=null">
                  <li> 
                    Numéro d'attestation : 
                    <span class="to-right assuraf-orange">
                      <b> {{ attestation_digital_data.attestationNumber }}</b>
                    </span>  
                  </li>
                  <li> 
                    Lien de l'attestation : 
                    <span class="to-right assuraf-orange">
                      <a :href="attestation_digital_data.linkAttestation">Telecharger l'attestation digitale</a>
                    </span>  
                  </li>
                  <li> 
                    Lien de carte brune : 
                    <span class="to-right assuraf-orange">
                      <a :href="attestation_digital_data.linkCarteBrune">Telecharger la carte brune</a>
                    </span>  
                  </li>
                </ul>

                <!-- VIsualisation Population Contrat Santé -->
                <ul style="list-style:none" v-if="detailsContrat.brancheid.branche == 'Santé'">
                  <p> <b>Population : </b> </p>
                  <li> Adulte Homme : <span class="to-right assuraf-orange">{{
                    detailsContrat.devisid.details_pack_sante.assures.Adulte.Homme }}</span>
                  </li>
                  <li> Adulte Femme : <span class="to-right assuraf-orange">{{
                    detailsContrat.devisid.details_pack_sante.assures.Adulte.Femme }}</span>
                  </li>
                  <li> Enfant Garçon : <span class="to-right assuraf-orange">{{
                    detailsContrat.devisid.details_pack_sante.assures.Enfant.Garcon }}</span>
                  </li>
                  <li> Enfant Fille : <span class="to-right assuraf-orange">{{
                    detailsContrat.devisid.details_pack_sante.assures.Enfant.Fille }}</span>
                  </li>
                  <li> Senior Homme : <span class="to-right assuraf-orange">{{
                    detailsContrat.devisid.details_pack_sante.assures.Senior.Homme }}</span>
                  </li>
                  <li> Senior Femme : <span class="to-right assuraf-orange">{{
                    detailsContrat.devisid.details_pack_sante.assures.Senior.Femme }}</span>
                  </li>
                  <li style="font-weight: bolder;">
                    Total : <span class="to-right assuraf-orange">
                      {{ detailsContrat.devisid.details_pack_sante.assures.nombre_assures }}
                    </span>
                  </li>
                </ul>
                <!-- VIsualisation Population Contrat Vie -->
                <ul style="list-style:none" v-if="detailsContrat.brancheid.branche == 'Vie'">
                  <p> <b>Population : </b> </p>
                  <li>
                    Assuré :
                    <span class="to-right assuraf-orange">
                      {{ detailsContrat.details[0].contractor.Prenom }} - {{ detailsContrat.details[0].contractor.Nom }}
                    </span>
                  </li>

                  <li>
                    Souscripteur :
                    <span class="to-right assuraf-orange">
                      {{ detailsContrat.details[0].customer.Prenom }} - {{ detailsContrat.details[0].customer.Nom }}
                    </span>
                  </li>

                  <li>
                    Bénéficiaire :
                    <span class="to-right assuraf-orange">
                      {{ detailsContrat.details[0].beneficiary.Prenom }} - {{ detailsContrat.details[0].beneficiary.Nom
                      }}
                    </span>
                  </li>
                  <li style="font-weight: bolder;">
                    Total : <span class="to-right assuraf-orange">{{ countViePopulation(detailsContrat.details[0])
                      }}</span>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsContrat.user.prenom.toUpperCase()
                      }}</span>
                  </li>
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsContrat.user.nom.toUpperCase() }}</span>
                  </li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsContrat.user.telephone_port }}</span>
                  </li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsContrat.user.email }}</span></li>
                  <li>Date inscription : <span class="to-right assuraf-orange">{{
                    detailsContrat.accessoires.toLocaleString() }}</span></li>
                  <li>
                    <img :src="detailsContrat.user.photo" alt="" width="200">
                  </li>
                </ul>

                <p></p>
                <div v-if="detailsContrat.numero_police.startsWith('AA')">
                  <el-divider content-position="center" v-if="detailsContrat.brancheid.branche == 'Automobile'"><b>Infos du véhicule</b></el-divider>
                  <ul style="list-style:none" v-if="detailsContrat.brancheid.branche == 'Automobile'">
                    <li>L'immatriculation : <span class="to-right assuraf-orange">{{ detailsContrat.details[0].vehicules[0].immatriculation }}</span></li>
                    <li>Marque : <span class="to-right assuraf-orange">{{ detailsContrat.details[0].vehicules[0].marque }}</span></li>
                    <li>Modèle : <span class="to-right assuraf-orange">{{ detailsContrat.details[0].vehicules[0].model }}</span></li>
                    <li>Puissance fiscale : <span class="to-right assuraf-orange">{{ detailsContrat.details[0].vehicules[0].force }}</span></li>
                    <li>Garanties : <span v-for="garanties in detailsContrat.details[0].vehicules[0].garanties" :key="garanties" class="to-right assuraf-orange mr-2">{{ garanties }}</span></li>
                  </ul> 
                </div>
                <div v-if="detailsContrat.numero_police.startsWith('VV')">
                  <el-divider content-position="center"><b>Infos du voyageur</b></el-divider>
                  <ul style="list-style:none">
                    <li>Nom  : <span class="to-right assuraf-orange">{{ detailsContrat.details[0].nom_passager }}</span></li>
                    <li>Prénom : <span class="to-right assuraf-orange">{{ detailsContrat.details[0].prenom_passager }}</span></li>
                    <li>Numéro téléphone : <span class="to-right assuraf-orange">{{ detailsContrat.details[0].num_passager }}</span></li>
                    <li>Date de naissance : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.devisid.listPassager[0].dateNaissance) }}</span></li>
                    <li>Age : <span class="to-right assuraf-orange">{{ detailsContrat.devisid.listPassager[0].adulte_enfant }} agé de {{ detailsContrat.devisid.listPassager[0].age_passager }} ans</span></li>

                    <li>Nationalité : <span class="to-right assuraf-orange">{{ detailsContrat.details[0].nationalite_passager }}</span></li>
                    <li>Numéro passeport : <span class="to-right assuraf-orange mr-2">{{ detailsContrat.details[0].numero_ci_passager }}</span></li>
                  </ul> 
                  <el-divider content-position="center"><b>Infos du voyage</b></el-divider>
                  <ul style="list-style:none">
                    <li>Pays de départ  : <span class="to-right assuraf-orange">{{ detailsContrat.devisid.pays_domicile }}</span></li>
                    <li>Pays de destination : <span class="to-right assuraf-orange">{{ detailsContrat.devisid.destination.PAYS }}</span></li>
                    <li>Mode de rembousement : <span class="to-right assuraf-orange">{{ detailsContrat.devisid.mode_remboursement_voyage }}</span></li>
                  </ul> 
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="15" v-if="JsonView" style="margin-top: 15px">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails en JSON </span>
              </div>
              <div>
                <pre style="background:black;color:white">
              {{ detailsContrat }}
            </pre>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <div class="mt-5">
        <el-button type="primary" v-if="detailsContrat.brancheid.branche == 'Automobile'" round
          @click="detailAttestation()">Imprimer l'attestation</el-button>
        <!-- <el-button type="primary"
          v-if="!detailsContrat.police_assureur && detailsContrat.brancheid.branche == 'Automobile'" round
          @click="modalNumPolice = true">Imprimer le contrat</el-button> -->
        <!-- <a v-if="detailsContrat.lien_contrat" :href="detailsContrat.lien_contrat">Télécharger le contrat</a> -->
        <el-button type="primary"
          v-if="detailsContrat.brancheid.branche == 'Automobile'" round
          @click="showModalDownload()">Télécharger le contrat</el-button>
        <el-button type="primary" round v-if="detailsContrat.brancheid.branche == 'Vie'"
          @click="DownloadContratVie(detailsContrat)">Télécharger le contrat vie</el-button>
        <el-button type="primary" round
          v-if="detailsContrat.brancheid.branche == 'Vie' || detailsContrat.brancheid.branche == 'Santé'"
          @click="Download_QmVie(detailsContrat)">Télécharger le QM</el-button>
        <el-button type="primary" round
          v-if="$store.state.user.email == 'sgning@assuraf.com' || $store.state.user.email == 'mowencleopas@assuraf.com'"
          @click="DeleteContrat(detailsContrat)">Archiver le contrat</el-button>
        <el-button type="primary" round v-if="$store.state.user.email == 'sgning@assuraf.com' || $store.state.user.email == 'mowencleopas@assuraf.com'" @click="RemoveContrat(detailsContrat)">
          Supprimer définitivement
        </el-button>
      </div>
    </el-dialog>

    <el-dialog width="90%!important" title="Détails contrat" :visible.sync="showcontrat"
      v-if="showcontrat == true && isMobile">
      <div class="mb-5">
        <el-button type="primary" round @click="JsonFormaterView()">Détails en JSON</el-button>
      </div>
      <div class="mb-5 mt-5">
        <el-button type="primary" round @click="getCommentDetail()">Lire commentaires</el-button>
      </div>
      <div class="mb-5 mt-5">
        <el-button type="primary" round @click="SetCommentDetail()">Ajouter un commentaire</el-button>
      </div>
      <div class="mb-5 mt-5">
        <el-button type="primary" round @click="viewAvenant()">Voir Avenant(s)</el-button>
      </div>
      <div class="mb-5 mt-5">
        <el-button type="primary" round @click="exportAsASACI()" v-loading.fullscreen.lock="modalgeneration">
          Générer le fichier ASACI</el-button>
      </div>
      <div class="mb-5 mt-5">
        <el-button type="primary" v-if="detailsContrat.brancheid.branche == 'Assur-Hospi'" round
          @click="updateBeneficiaire()">Modifier bénéficiaire</el-button>
      </div>

      <el-row :gutter="15">
        <el-col :span="24" v-if="JsonView">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Détails en JSON </span>
              </div>
              <div>
                <pre style="background:black;color:white">
              {{ detailsContrat }}
            </pre>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Date de Souscription : <span class="to-right assuraf-orange">{{
                    DateFormater(detailsContrat.dateSouscription) }}</span></li>
                  <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{ detailsContrat.numero_police
                      }}</span></li>
                  <li v-if="detailsContrat.brancheid.branche == 'Voyage' && detailsContrat.increment_voyage">
                    Numéro Increment Voyage : <span class="to-right assuraf-orange">{{ detailsContrat.increment_voyage
                      }}</span></li>
                  <li>Numéro Police Assureur : <span class="to-right assuraf-orange">{{ detailsContrat.police_assureur
                      }}</span></li>
                  <li>Date Effet : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.dateEffet)
                      }}</span>
                  </li>
                  <li>Date Echéance : <span class="to-right assuraf-orange">{{ DateFormater(detailsContrat.dateEcheance)
                      }}</span></li>
                  <li>Prime Nette : <span class="to-right assuraf-orange">{{ detailsContrat.prime_nette.toLocaleString()
                      }}</span></li>
                  <li>Prime TTC : <span class="to-right assuraf-orange">{{ detailsContrat.prime_ttc.toLocaleString()
                      }}</span></li>
                  <li>Taxe : <span class="to-right assuraf-orange">{{ detailsContrat.taxe.toLocaleString() }}</span>
                  </li>
                  <li
                    v-if="detailsContrat.brancheid.branche == 'Automobile' && CheckIfTypeDefined(detailsContrat.devisid) != null">
                    FGA : <span class="to-right assuraf-orange" v-if="detailsContrat.devisid != null">{{
                      detailsContrat.devisid.fga.toLocaleString() }}</span></li>
                  <li>Accessoires : <span class="to-right assuraf-orange">{{ detailsContrat.accessoires.toLocaleString()
                      }}</span></li>
                  <li v-if="detailsContrat.partner_creator != null">Partenaires : <span
                      class="to-right assuraf-orange">{{
                        detailsContrat.partner_creator }}</span></li>
                </ul>
                <hr>

                <!-- VIsualisation Population Contrat Santé -->
                <ul style="list-style:none" v-if="detailsContrat.brancheid.branche == 'Santé'">
                  <p> <b>Population : </b> </p>
                  <li> Adulte Homme : <span class="to-right assuraf-orange">{{
                    detailsContrat.devisid.details_pack_sante.assures.Adulte.Homme }}</span>
                  </li>
                  <li> Adulte Femme : <span class="to-right assuraf-orange">{{
                    detailsContrat.devisid.details_pack_sante.assures.Adulte.Femme }}</span>
                  </li>
                  <li> Enfant Garçon : <span class="to-right assuraf-orange">{{
                    detailsContrat.devisid.details_pack_sante.assures.Enfant.Garcon }}</span>
                  </li>
                  <li> Enfant Fille : <span class="to-right assuraf-orange">{{
                    detailsContrat.devisid.details_pack_sante.assures.Enfant.Fille }}</span>
                  </li>
                  <li> Senior Homme : <span class="to-right assuraf-orange">{{
                    detailsContrat.devisid.details_pack_sante.assures.Senior.Homme }}</span>
                  </li>
                  <li> Senior Femme : <span class="to-right assuraf-orange">{{
                    detailsContrat.devisid.details_pack_sante.assures.Senior.Femme }}</span>
                  </li>
                  <li style="font-weight: bolder;">
                    Total : <span class="to-right assuraf-orange">
                      {{ detailsContrat.devisid.details_pack_sante.assures.nombre_assures }}
                    </span>
                  </li>
                </ul>
                <!-- VIsualisation Population Contrat Vie -->
                <ul style="list-style:none" v-if="detailsContrat.brancheid.branche == 'Vie'">
                  <p> <b>Population : </b> </p>
                  <li>
                    Assuré :
                    <span class="to-right assuraf-orange">
                      {{ detailsContrat.details[0].contractor.Prenom }} - {{ detailsContrat.details[0].contractor.Nom }}
                    </span>
                  </li>

                  <li>
                    Souscripteur :
                    <span class="to-right assuraf-orange">
                      {{ detailsContrat.details[0].customer.Prenom }} - {{ detailsContrat.details[0].customer.Nom }}
                    </span>
                  </li>

                  <li>
                    Bénéficiaire :
                    <span class="to-right assuraf-orange">
                      {{ detailsContrat.details[0].beneficiary.Prenom }} - {{ detailsContrat.details[0].beneficiary.Nom
                      }}
                    </span>
                  </li>
                  <li style="font-weight: bolder;">
                    Total : <span class="to-right assuraf-orange">{{ countViePopulation(detailsContrat.details[0])
                      }}</span>
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsContrat.user.prenom.toUpperCase()
                      }}</span>
                  </li>
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsContrat.user.nom.toUpperCase() }}</span>
                  </li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsContrat.user.telephone_port }}</span>
                  </li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsContrat.user.email }}</span></li>
                  <li>Date inscription : <span class="to-right assuraf-orange">{{
                    detailsContrat.accessoires.toLocaleString() }}</span></li>
                  <li>
                    <img :src="detailsContrat.user.photo" alt="" width="200">
                  </li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <div class="mt-5">
        <el-button type="primary" v-if="detailsContrat.brancheid.branche == 'Automobile'" round
          @click="detailAttestation()">Imprimer l'attestation</el-button>
      </div>
      
      <div class="mt-5">
        <el-button type="primary"
          v-if="detailsContrat.brancheid.branche == 'Automobile'" round
          @click="modalNumPolice = true">Télécharger le contrat</el-button>
      </div>
      <!-- <div class="mt-5">
        <el-button type="primary"
          v-if="!detailsContrat.police_assureur && detailsContrat.brancheid.branche == 'Automobile'" round
          @click="modalNumPolice = true">Imprimer le contrat</el-button>
      </div> -->
      <!-- <div class="mt-5">
        <a v-if="detailsContrat.lien_contrat" :href="detailsContrat.lien_contrat">Télécharger le contrat</a>
      </div> -->
      <div class="mt-5">
        <el-button type="primary" round v-if="detailsContrat.brancheid.branche == 'Vie'"
          @click="DownloadContratVie(detailsContrat)">Télécharger le contrat vie</el-button>
      </div>
      <div class="mt-5">
        <el-button type="primary" round
          v-if="detailsContrat.brancheid.branche == 'Vie' || detailsContrat.brancheid.branche == 'Santé'"
          @click="Download_QmVie(detailsContrat)">Télécharger le QM</el-button>
      </div>
      <div class="mt-5">
        <el-button type="primary" round
          v-if="$store.state.user.email == 'sgning@assuraf.com' || $store.state.user.email == 'mowencleopas@assuraf.com'"
          @click="DeleteContrat(detailsContrat)">Archiver le contrat</el-button>
      </div>
      <div class="mt-5">
        <el-button type="primary" round
          v-if="$store.state.user.email == 'sgning@assuraf.com' || $store.state.user.email == 'mowencleopas@assuraf.com'"
          @click="RemoveContrat(detailsContrat)">Supprimer définitivement</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Suppression contrat" width="50%" :visible.sync="modalDelete" v-if="modalDelete == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Voulez-vous supprimer ce contrat?</span>
          </div>
          <div>
            <ul style="list-style:none">
              <li>Contrat : <span class="to-right assuraf-orange">{{ detailsContrat.numero_police }}</span></li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="DeletingContrat(detailsContrat)">Oui</el-button>
        <el-button type="danger" round @click="modalDelete = false">Non</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Archivage du contrat" width="50%" :visible.sync="modalDelete" v-if="modalDelete == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Vous confirmez l'archivage de ce contrat ?</span>
          </div>
          <div>
            <ul style="list-style:none">
              <li>Numéro contrat : <span class="to-right assuraf-orange" style="font-size: 15px">{{
                detailsContrat.numero_police }}</span></li>
            </ul>
          </div>
          <div>
            <el-input type="textarea" :rows="2" placeholder="Pourquoi supprimer?" v-model="Delete_reason">
            </el-input>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="danger" round @click="ArchiveContract(detailsContrat)">Archive</el-button>
        <el-button type="primary" round @click="modalDelete = false">Annuler</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="'Détails des commentaires ' + commentcount" width="50%" :visible.sync="show_comment_contrat"
      v-if="show_comment_contrat == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Commentaires</span>
          </div>
          <div v-for="(cl, i) in commentlist" :key="i">
            <ul style="list-style:none">
              <li>{{ cl.userid.prenom }}-{{ cl.userid.nom }} a écrit <span class="to-right assuraf-orange">{{
                cl.comment_text }}</span></li>
              <li>Le<span class="to-right assuraf-orange">{{ DateFormater(cl.createdat) }}</span></li>
            </ul>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="SetCommentDetail()">Ajouter</el-button>
        <el-button type="danger" round @click="show_comment_contrat = false">Fermer</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Ajouter un commentaire" width="50%" :visible.sync="set_comment_contrat"
      v-if="set_comment_contrat == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Ajouter</span>
          </div>
          <div>
            <el-input aria-placeholder="Votre commentaire..." type="textarea" v-model="comment.comment_text"></el-input>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="sendComment()">Ajouter</el-button>
        <el-button type="danger" round @click="set_comment_contrat = false">Fermer</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Historique des avenants" width="70%" :visible.sync="set_story_avenant"
      v-if="set_story_avenant == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <el-table ref="singleTableRef" :data="story_avenant" highlight-current-row style="width: 100%">
            <el-table-column property="numero_avenant" label="Numero Avenant" width="150" />
            <el-table-column property="numero_police" label="Numero Police" width="200" />
            <el-table-column property="type_avenant" label="Type avenant" width="200" />
            <el-table-column property="type_prime" label="Type prime" width="150" />
            <el-table-column property="dateEffet" label="Date Effet" width="150">
              <template slot-scope="scope">
                <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
              </template>
            </el-table-column>
            <el-table-column property="dateEcheance" label="Date Echeance" width="150">
              <template slot-scope="scope">
                <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
              </template>
            </el-table-column>
            <el-table-column property="proprietaire.nom" label="Proprietaire" width="150" />
            <el-table-column property="prime_nette" label="Prime nette" width="150" />
            <el-table-column property="prime_ttc" label="Prime ttc" width="150" />
            <el-table-column property="updated" label="Date Creation" width="150">
              <template slot-scope="scope">
                <span class="date">{{ DateFormater(scope.row.updated) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="danger" round @click="set_story_avenant = false">Fermer</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Modifier Bénéficiaire" width="70%" :visible.sync="set_update_ben" v-if="set_update_ben == true">
      <div>

        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="Beneficiaire.Nom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input placeholder="Votre prenom" v-model="Beneficiaire.Prenom">
              </el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>CNI</label>
              <el-input placeholder="Votre piéce d'identité " v-model="Beneficiaire.Cni" type="number">
              </el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">


          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Filiation</label>
              <el-select style="width:100%" v-model="Beneficiaire.Filiation" placeholder="Selectionner une filiation">
                <el-option value="null" selected disabled="">Filiation</el-option>
                <el-option value="pere_mere">Père/Mère</el-option>
                <el-option value="fils_fille">Fils/Fille</el-option>
                <el-option value="frere_soeur">Frère/Soeur</el-option>
                <el-option value="epoux_epouse">Epoux/Epouse</el-option>
                <el-option value="autres">Autre (adoptive,..)</el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="grid-content bg-purple-light">
              <label>Date de naissance</label>
              <el-date-picker style="width: 100%!important;" v-model="dateNaissance" format="yyyy/MM/dd" type="date"
                :picker-options="dateBeforeToday" placeholder="Choississez une date">
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <label>Lieu de naissance</label>
              <el-input placeholder="Votre lieu de naissance" v-model="Beneficiaire.LieuNaissance">
              </el-input>
            </div>

          </el-col>
          <el-col style="margin-top: 15px" :span="8">
            <div class="grid-content bg-purple">
              <label>Téléphone</label>
              <el-input placeholder="Votre numéro de téléphone" v-model="Beneficiaire.Telephone">
              </el-input>
            </div>

          </el-col>
        </el-row>

        <div style="margin-top:15px">
          <el-button type="primary" round @click="updateBen()">Modifier</el-button>
          <el-button type="danger" round @click="set_update_ben = false">Fermer</el-button>
        </div>
      </div>
      <div style="margin-top:15px">

      </div>
    </el-dialog>



    <el-dialog title="Notification" width="50%" :visible.sync="modal_Delete_ok" v-if="modal_Delete_ok == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ notification_text }}</span>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="danger" round @click="modal_Delete_ok = false">Fermer</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Génération du fichier" width="50%" :visible.sync="modalNumPolice"
      v-if="modalNumPolice == true && !isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Ajouter</span>
          </div>
          <div>
            <el-row :gutter="15">
              <el-col :span="12">
                <label>Entrer le numéro de police</label>
                <el-input aria-placeholder="Numéro police" v-model="NumPolice"></el-input>
              </el-col>
              <el-col :span="12">
                <label>Entrer le numéro du talon attestation</label>
                <el-input aria-placeholder="Numéro talon" v-model="NumTalon"></el-input>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="Validate_numePolice(detailsContrat)">Valider</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Génération du fichier" width="90%" :visible.sync="modalNumPolice"
      v-if="modalNumPolice == true && isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Ajouter</span>
          </div>
          <div>
            <el-row :gutter="15">
              <el-col :span="24">
                <label>Entrer le numéro de police</label>
                <el-input aria-placeholder="Numéro police" v-model="NumPolice"></el-input>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <label>Entrer le numéro du talon attestation</label>
                <el-input aria-placeholder="Numéro talon" v-model="NumTalon"></el-input>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="Validate_numePolice(detailsContrat)">Valider</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Génération du fichier" width="50%" :visible.sync="modalNumPoliceAttest"
      v-if="modalNumPoliceAttest == true && !isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Modifier</span>
          </div>
          <div>
            <el-row :gutter="15">
              <el-col :span="12">
                <label>Entrer le numéro de police de l'attestation</label>
                <el-input placeholder="Numéro police" v-model="NumPolice"></el-input>
              </el-col>
              <el-col :span="12">
                <label>Entrer la categorie de l'attestation</label>
                <el-input placeholder="Catégorie" v-model="categorie"></el-input>
              </el-col>
            </el-row>

            <el-row style="margin-top:15px">
              <el-col>
                <el-switch style="display: block" v-model="checked" active-color="#13ce66" inactive-color="#ff4949"
                  active-text="Changer le nom du client" @change="checkedControl()">
                </el-switch>
              </el-col>
            </el-row>

            <el-row :gutter="15" style="margin-top:15px" v-show="client_name">
              <el-col :span="12">
                <label>Entrer le nom sur la carte grise</label>
                <el-input placeholder="Nom" v-model="NomSurCarte"></el-input>
              </el-col>
              <el-col :span="12">
                <label>Entrer le prénom sur la carte grise</label>
                <el-input placeholder="Prénom" v-model="PrenomSurCarte"></el-input>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="Validate_numePoliceAttest()">Valider</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Génération du fichier" width="90%" :visible.sync="modalNumPoliceAttest"
      v-if="modalNumPoliceAttest == true && isMobile">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Modifier</span>
          </div>
          <div>
            <el-row :gutter="15">
              <el-col :span="24">
                <label>Entrer le numéro de police de l'attestation</label>
                <el-input placeholder="Numéro police" v-model="NumPolice"></el-input>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <label>Entrer la categorie de l'attestation</label>
                <el-input placeholder="Catégorie" v-model="categorie"></el-input>
              </el-col>
              <el-col :span="24" style="margin-top:15px">
                <el-switch style="display: block" v-model="checked" active-color="#13ce66" inactive-color="#ff4949"
                  active-text="Changer le nom du client" @change="checkedControl()">
                </el-switch>
              </el-col>
              <el-col :span="24" style="margin-top:15px" v-show="client_name">
                <label>Entrer le nom sur la carte grise</label>
                <el-input placeholder="Nom" v-model="NomSurCarte"></el-input>
              </el-col>
              <el-col :span="24" style="margin-top:15px" v-show="client_name">
                <label>Entrer le prénom sur la carte grise</label>
                <el-input placeholder="Prénom" v-model="PrenomSurCarte"></el-input>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="Validate_numePoliceAttest()">Valider</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Détails attestation" width="90%" :visible.sync="showAttestation" v-if="showAttestation == true">
      <div class="grid-content bg-purple">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Attestation</span>
          </div>
          <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS AUTOMOTO -->
          <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS AUTOMOTO -->
          <div v-if="detailsContrat.brancheid.branche == 'Automobile'">
            <!-- LA PARTIE ATTESTATION SUNU, PROVIDENCE, -->
            <!-- LA PARTIE ATTESTATION SUNU, PROVIDENCE, -->
            <!-- LA PARTIE ATTESTATION SUNU, PROVIDENCE, -->
            <!-- LA PARTIE ATTESTATION SUNU, PROVIDENCE, -->
            <div id="canvas" height="450" v-if="Sunu">
              <div class="ml-1 row" style="display:inline-flex!important">
                <div class="column" style="width:309px!important">
                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top: 38px!important;list-style:none!important;margin-left: -10px!important;">
                    <li><span class="font-weight-bold" v-show="user_name">{{ detailsContrat.user.prenom }}
                        {{ detailsContrat.user.nom.toUpperCase() }}</span> </li>
                    <li><span class="font-weight-bold">{{ NomSurCarte }} {{ PrenomSurCarte }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.profession }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.adresse }}</span> </li>
                  </ul>

                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top:98px!important;list-style:none!important;margin-left: -10px!important;">
                    <li><span class="font-weight-bold">{{ categorie }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].marque }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].immatriculation
                        }}</span>
                    </li>
                    <li><span class="font-weight-bold">{{ NumPolice }}</span> </li>
                    <li v-show="isToday"><span class="font-weight-bold">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span> </li>
                    <li v-show="isNotToday"><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEffet) +
                      '00H00' }}</span> </li>
                    <li><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEcheance) + ' 24H' }}</span>
                    </li>
                  </ul>
                </div>

                <div class="column" style="width: 196px!important">
                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top:96px!important;list-style:none!important;margin-left: -15px!important;">
                    <li><span class="font-weight-bold">{{ categorie }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].marque }}</span> </li>
                    <li><span class="font-weight-bold"> {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].immatriculation
                        }}</span>
                    </li>
                    <li><span class="font-weight-bold">{{ NumPolice }}</span> </li>
                    <li v-show="isToday"><span class="font-weight-bold">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span> </li>
                    <li v-show="isNotToday"><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEffet) +
                      '00H00' }} </span> </li>
                    <li><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEcheance) + ' 24H' }}</span>
                    </li>
                  </ul>
                </div>

                <div class="column">
                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top: 38px!important;list-style:none!important;margin-left: 25px!important;">
                    <li><span class="font-weight-bold" v-show="user_name">{{ detailsContrat.user.prenom }}
                        {{ detailsContrat.user.nom.toUpperCase() }}</span> </li>
                    <li><span class="font-weight-bold"> {{ NomSurCarte }} {{ PrenomSurCarte }} </span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.profession }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.adresse }}</span> </li>
                  </ul>

                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top:98px!important;list-style:none!important;margin-left: 25px!important;">
                    <li><span class="font-weight-bold">{{ categorie }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].marque }}</span> </li>
                    <li><span class="font-weight-bold"> {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].immatriculation
                        }}</span>
                    </li>
                    <li><span class="font-weight-bold">{{ NumPolice }}</span> </li>
                    <li v-show="isToday"><span class="font-weight-bold">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span> </li>
                    <li v-show="isNotToday"><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEffet) +
                      '00H00' }} </span> </li>
                    <li><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEcheance) + ' 24H' }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- LA PARTIE ATTESTATION ASKIA -->
            <!-- LA PARTIE ATTESTATION ASKIA -->
            <!-- LA PARTIE ATTESTATION ASKIA -->
            <!-- LA PARTIE ATTESTATION ASKIA -->
            <div id="canvas" width="450" v-if="Askia" style="height:250px!important">
              <div class="ml-1 row" style="display:inline-flex!important">
                <!-- 1 Colonne -->
                <div class="column" style="width:309px!important;margin-left:75px">
                  <ul class="detailsSeeAttest"
                    style="margin-top: 33px!important;list-style:none!important;margin-left: 35px!important;">
                    <li><span class="font-weight-bold" style="margin-left:-92px;font-size:11px!important">{{
                      detailsContrat.user.prenom }}
                        {{ detailsContrat.user.nom.toUpperCase() }}</span> </li>
                  </ul>

                  <ul class="detailsSeeAttest"
                    style="margin-top: 18px!important;list-style:none!important;margin-left: 25px!important;line-height:12px!important">
                    <li><span class="font-weight-bold" style="margin-left:-50px;font-size:11px!important">{{ NumPolice
                        }}</span> </li>
                    <li v-show="isToday" style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:-35px;font-size:11px!important">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span> </li>

                    <li v-show="isNotToday" style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:-35px;font-size:11px!important">{{ DateFormatter(detailsContrat.dateEffet) +
                          '00H00' }}</span> </li>
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:-35px;font-size:11px!important">{{ DateFormatter(detailsContrat.dateEcheance)
                          +
                          ' 24H' }}</span> </li>
                    <!-- <li style="margin-top:-1px">-</li> -->
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="font-size:11px!important;margin-left:-35px">{{
                          detailsContrat.details[0].vehicules[0].marque }}</span>
                    </li>
                    <!-- <li style="margin-top:-1px"><span class="font-weight-bold" style="font-size:9px!important">{{detailsContrat.details[0].vehicules[0].immatriculation}}</span> </li> -->
                  </ul>

                  <ul class="detailsSeeAttest"
                    style="margin-top: 13px!important;list-style:none!important;margin-left: 25px!important;">
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:-65px;font-size:11px!important">{{
                          detailsContrat.details[0].vehicules[0].immatriculation }}</span>
                    </li>
                    <li><span class="font-weight-bold" style="margin-left:-40px;font-size:11px!important">
                        {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:60px;font-size:11px!important">{{ categorie }}</span> </li>
                  </ul>
                </div>
                <!-- 2eme colonne-->
                <div class="column" style="width:309px!important;margin-left:-20px">
                  <ul class="detailsSeeAttest"
                    style="margin-top: 69px!important;list-style:none!important;margin-left: 1px!important;">
                    <li><span class="font-weight-bold" style="margin-left:-65px;font-size:11px!important">{{ NumPolice
                        }}</span> </li>

                    <li v-show="isToday" style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:-50px;font-size:11px!important">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span> </li>


                    <li v-show="isNotToday" style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:-50px;font-size:11px!important">{{ DateFormatter(detailsContrat.dateEffet) +
                          '00H00' }}</span> </li>
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:-50px;font-size:11px!important">{{ DateFormatter(detailsContrat.dateEcheance)
                          +
                          ' 24H' }}</span> </li>
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="font-size:11px!important;margin-left:-43px">{{
                          detailsContrat.details[0].vehicules[0].marque }}</span>
                    </li>

                  </ul>

                  <ul class="detailsSeeAttest"
                    style="margin-top: 13px!important;list-style:none!important;margin-left: 1px!important;">
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:-50px;font-size:11px!important">{{
                          detailsContrat.details[0].vehicules[0].immatriculation }}</span>
                    </li>
                    <li><span class="font-weight-bold" style="margin-left:-50px;font-size:11px!important">
                        {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:50px;font-size:11px!important">{{ categorie }}</span> </li>
                  </ul>
                </div>

                <!-- 3eme colonne-->
                <div class="column" style="width:309px!important;margin-left:-120px;margin-top:-15px">
                  <ul class="detailsSeeAttest"
                    style="margin-top: 20px!important;list-style:none!important;margin-left: 15px!important;">
                    <li><span class="font-weight-bold" style="margin-left:-10px;font-size:11px!important">{{
                      detailsContrat.user.prenom }}
                        {{ detailsContrat.user.nom.toUpperCase() }}</span> </li>
                  </ul>

                  <ul class="detailsSeeAttest"
                    style="margin-top: 18px!important;list-style:none!important;margin-left: 5px!important;line-height:12px!important">
                    <li style="margin-top:-5px"><span class="font-weight-bold"
                        style="margin-left:20px;font-size:11px!important">{{ detailsContrat.user.profession }}</span>
                    </li>
                    <li style="margin-top:1px"><span class="font-weight-bold"
                        style="margin-left:60px;font-size:11px!important">{{ NumPolice }}</span> </li>
                    <li v-show="isToday" style="margin-top:10px;margin-left: 1px"><span class="font-weight-bold"
                        style="font-size:11px!important">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}
                        <span class="font-weight-bold" style="margin-left: 50px">{{
                          DateFormatter(detailsContrat.dateEcheance) + ' 24H' }}</span></span>
                    </li>

                    <li v-show="isNotToday" style="margin-top:10px;margin-left: 1px"><span class="font-weight-bold"
                        style="font-size:11px!important">{{ DateFormatter(detailsContrat.dateEffet) + ' 00H00' }}
                        <span class="font-weight-bold" style="margin-left: 50px">{{
                          DateFormatter(detailsContrat.dateEcheance) + ' 24H' }}</span></span>
                    </li>
                    <li style="margin-top:5px"><span class="font-weight-bold"
                        style="font-size:11px!important;margin-left:130px">{{
                          detailsContrat.details[0].vehicules[0].marque }}</span>
                    </li>
                    <li style="margin-top:5px"><span class="font-weight-bold"
                        style="font-size:11px!important;margin-left:125px">{{
                          detailsContrat.details[0].vehicules[0].immatriculation }}</span>
                    </li>
                  </ul>

                  <ul class="detailsSeeAttest"
                    style="margin-top: 15px!important;list-style:none!important;margin-left: 15px!important;">
                    <li><span class="font-weight-bold" style="font-size:11px!important">
                        {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:70px;font-size:11px!important">{{ categorie }}</span> </li>
                  </ul>
                </div>
              </div>
            </div>


            <!-- LA PARTIE ATTESTATION AXA -->
            <!-- LA PARTIE ATTESTATION AXA -->
            <!-- LA PARTIE ATTESTATION AXA -->
            <!-- LA PARTIE ATTESTATION AXA -->
            <div id="canvas" width="450" v-if="Axa" style="height:250px!important">
              <div class="ml-1 row" style="display:inline-flex!important">
                <!-- 1 Colonne -->
                <div class="column" style="width:309px!important;margin-left:-5px;margin-top:22px">
                  <ul class="detailsSeeAttest"
                    style="margin-top: 33px!important;list-style:none!important;margin-left: 65px!important;">
                    <li><span class="font-weight-bold" style="margin-left:-92px;font-size:11px!important"
                        v-show="user_name">{{ detailsContrat.user.prenom }} {{ detailsContrat.user.nom.toUpperCase()
                        }}</span>
                    </li>
                    <li><span class="font-weight-bold" style="margin-left:-92px;font-size:11px!important">{{
                      PrenomSurCarte }} {{ NomSurCarte }}</span> </li>

                  </ul>

                  <ul class="detailsSeeAttest"
                    style="margin-top: 18px!important;list-style:none!important;margin-left: 25px!important;line-height:12px!important">
                    <li><span class="font-weight-bold" style="margin-left:-35px;font-size:11px!important">{{ NumPolice
                        }}</span> </li>
                    <li v-show="isToday" style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:-22px;font-size:11px!important">{{ DateFormatter(detailsContrat.dateEffet)
                        }}{{
                          MinuteFormatter(detailsContrat.dateEffet) }}</span>
                    </li>

                    <li style="margin-top:-1px;margin-left: -40px">
                      <span v-show="isToday" class="font-weight-bold"
                        style="margin-left:-22px;font-size:11px!important">{{
                          DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span>
                      <span v-show="isNotToday" class="font-weight-bold"
                        style="margin-left:-22px;font-size:11px!important">{{ DateFormatter(detailsContrat.dateEffet) +
                          '00H00' }}</span>
                      <span class="font-weight-bold" style="margin-left:10px;font-size:11px!important">{{
                        DateFormatter(detailsContrat.dateEcheance) + '24H' }}</span>
                    </li>
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="font-size:11px!important;margin-left:-15px">{{
                          detailsContrat.details[0].vehicules[0].marque }}</span>
                    </li>
                  </ul>

                  <ul class="detailsSeeAttest"
                    style="margin-top: 17px!important;list-style:none!important;margin-left: 25px!important;">
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:5px;font-size:11px!important">{{
                          detailsContrat.details[0].vehicules[0].immatriculation }}</span>
                    </li>
                    <li style="margin-top: 5px"><span class="font-weight-bold"
                        style="margin-left:-30px;font-size:11px!important">
                        {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:40px;font-size:11px!important">{{ categorie }}</span> </li>
                  </ul>
                </div>

                <!-- 2eme colonne-->
                <div class="column" style="width:309px!important;margin-left:-80px;margin-top:35px">
                  <ul class="detailsSeeAttest"
                    style="margin-top: 69px!important;list-style:none!important;margin-left: 1px!important;">
                    <li><span class="font-weight-bold" style="margin-left:-15px;font-size:11px!important">{{ NumPolice
                        }}</span> </li>

                    <li style="margin-top:-1px;margin-left: -40px">
                      <span v-show="isToday" class="font-weight-bold"
                        style="margin-left:5px;font-size:11px!important">{{
                          DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span>
                      <span v-show="isNotToday" class="font-weight-bold"
                        style="margin-left:5px;font-size:11px!important">{{
                          DateFormatter(detailsContrat.dateEffet) +
                          '00H00' }}</span>
                      <span class="font-weight-bold" style="margin-left:10px;font-size:11px!important">{{
                        DateFormatter(detailsContrat.dateEcheance) + '24H' }}</span>
                    </li>
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="font-size:11px!important;margin-left:20px">{{
                          detailsContrat.details[0].vehicules[0].marque
                        }}</span>
                    </li>

                  </ul>

                  <ul class="detailsSeeAttest"
                    style="margin-top: 13px!important;list-style:none!important;margin-left: 1px!important;">
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:25px;font-size:11px!important">{{
                          detailsContrat.details[0].vehicules[0].immatriculation }}</span>
                    </li>
                    <li><span class="font-weight-bold" style="margin-left:-25px;font-size:11px!important">
                        {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:70px;font-size:11px!important">{{ categorie }}</span> </li>
                  </ul>
                </div>

                <!-- 3eme colonne-->
                <div class="column" style="width:309px!important;margin-left:-160px;margin-top:5px">
                  <ul class="detailsSeeAttest"
                    style="margin-top: 35px!important;list-style:none!important;margin-left: 15px!important;">
                    <li><span class="font-weight-bold" style="margin-left:10px;font-size:11px!important"
                        v-show="user_name">{{ detailsContrat.user.prenom }} {{ detailsContrat.user.nom.toUpperCase()
                        }}</span>
                    </li>
                    <li><span class="font-weight-bold" style="margin-left:10px;font-size:11px!important">{{
                      PrenomSurCarte
                        }} {{ NomSurCarte }}</span> </li>
                  </ul>

                  <ul class="detailsSeeAttest"
                    style="margin-top: 18px!important;list-style:none!important;margin-left: 5px!important;line-height:12px!important">
                    <li style="margin-top:-5px"><span class="font-weight-bold"
                        style="margin-left:30px;font-size:11px!important">{{ detailsContrat.user.profession }}</span>
                    </li>
                    <li style="margin-top:1px"><span class="font-weight-bold"
                        style="margin-left:70px;font-size:11px!important">{{ NumPolice }}</span> </li>
                    <li v-show="isToday" style="margin-top:10px;margin-left: 35px"><span class="font-weight-bold"
                        style="font-size:11px!important">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}
                        <span class="font-weight-bold" style="margin-left: 50px">{{
                          DateFormatter(detailsContrat.dateEcheance) + ' 24H' }}</span></span>
                    </li>

                    <li v-show="isNotToday" style="margin-top:10px;margin-left: 35px"><span class="font-weight-bold"
                        style="font-size:11px!important">{{ DateFormatter(detailsContrat.dateEffet) + ' 00H00' }}
                        <span class="font-weight-bold" style="margin-left: 40px">{{
                          DateFormatter(detailsContrat.dateEcheance) + ' 24H' }}</span></span>
                    </li>
                    <li style="margin-top:5px"><span class="font-weight-bold"
                        style="font-size:11px!important;margin-left:130px">{{
                          detailsContrat.details[0].vehicules[0].marque }}</span>
                    </li>
                    <li style="margin-top:5px"><span class="font-weight-bold"
                        style="font-size:11px!important;margin-left:135px">{{
                          detailsContrat.details[0].vehicules[0].immatriculation }}</span>
                    </li>
                  </ul>

                  <ul class="detailsSeeAttest"
                    style="margin-top: 15px!important;list-style:none!important;margin-left: 15px!important;">
                    <li><span class="font-weight-bold" style="margin-left:35px;font-size:11px!important">
                        {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li style="margin-top:-1px"><span class="font-weight-bold"
                        style="margin-left:140px;font-size:11px!important">{{ categorie }}</span> </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- LA PARTIE ATTESTATION AMSA -->
            <!-- LA PARTIE ATTESTATION AMSA -->
            <!-- LA PARTIE ATTESTATION AMSA -->
            <!-- LA PARTIE ATTESTATION AMSA -->
            <div id="canvas" width="450" v-if="Amsa" style="height:300px!important">
              <div class="ml-1 row" style="display:inline-flex!important">
                <div class="column" style="width:309px!important;margin-top: -5px">
                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top: 40px!important;list-style:none!important;margin-left: 60px!important;">
                    <li><span class="font-weight-bold" v-show="user_name">{{ detailsContrat.user.prenom }}
                        {{ detailsContrat.user.nom.toUpperCase() }}</span> </li>
                    <li><span class="font-weight-bold">{{ NomSurCarte }} {{ PrenomSurCarte }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.profession }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.adresse }}</span> </li>
                  </ul>

                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top:70px!important;list-style:none!important;margin-left: 60px!important;">
                    <li><span class="font-weight-bold">{{ categorie }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].marque }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li><span class="font-weight-bold" style="margin-left:80px">{{
                      detailsContrat.details[0].vehicules[0].immatriculation }}</span> </li>
                    <li><span class="font-weight-bold">{{ NumPolice }}</span> </li>
                    <li>
                      <span v-show="isToday" class="font-weight-bold">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span>
                      <span v-show="isNotToday" class="font-weight-bold">{{ DateFormater(detailsContrat.dateEffet) +
                        '00H00' }}</span>
                      <span class="ml-1 font-weight-bold">{{ DateFormater(detailsContrat.dateEcheance) + ' 24H'
                        }}</span>
                    </li>
                  </ul>
                </div>

                <div class="column" style="width: 196px!important">
                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top:108px!important;list-style:none!important;margin-left: 40px!important;">
                    <li><span class="font-weight-bold">{{ categorie }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].marque }}</span> </li>
                    <li><span class="font-weight-bold"> {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li><span class="font-weight-bold" style="margin-left: 30px">{{
                      detailsContrat.details[0].vehicules[0].immatriculation }}</span> </li>
                    <li><span class="font-weight-bold">{{ NumPolice }}</span> </li>
                    <li v-show="isToday"><span class="font-weight-bold">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span> </li>
                    <li v-show="isNotToday"><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEffet) +
                      '00H00' }} </span> </li>
                    <li><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEcheance) + ' 24H' }}</span>
                    </li>
                  </ul>
                </div>

                <div class="column">
                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top: 37px!important;list-style:none!important;margin-left: 65px!important;">
                    <li><span class="font-weight-bold" v-show="user_name">{{ detailsContrat.user.prenom }}
                        {{ detailsContrat.user.nom.toUpperCase() }}</span> </li>
                    <li><span class="font-weight-bold"> {{ NomSurCarte }} {{ PrenomSurCarte }} </span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.profession }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.adresse }}</span> </li>
                  </ul>

                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top:68px!important;list-style:none!important;margin-left: 85px!important;">
                    <li><span class="font-weight-bold">{{ categorie }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].marque }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li><span class="font-weight-bold" style="margin-left:80px">{{
                      detailsContrat.details[0].vehicules[0].immatriculation }}</span> </li>
                    <li><span class="font-weight-bold">{{ NumPolice }}</span> </li>
                    <li>
                      <span v-show="isToday" class="font-weight-bold">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span>
                      <span v-show="isNotToday" class="font-weight-bold">{{ DateFormater(detailsContrat.dateEffet) +
                        '00H00' }}</span>
                      <span class="ml-1 font-weight-bold">{{ DateFormater(detailsContrat.dateEcheance) + ' 24H'
                        }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- LA PARTIE ATTESTATION SALAMA -->
            <!-- LA PARTIE ATTESTATION SALAMA -->
            <!-- LA PARTIE ATTESTATION SALAMA -->
            <!-- LA PARTIE ATTESTATION SALAMA -->
            <div id="canvas" width="450" height="450" v-if="Salama">
              <div class="ml-1 row" style="display:inline-flex!important">
                <div class="column" style="width:309px!important">
                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top: 25px!important;list-style:none!important;margin-left: 30px!important;">
                    <li><span class="font-weight-bold" v-show="user_name">{{ detailsContrat.user.prenom }}
                        {{ detailsContrat.user.nom.toUpperCase() }}</span> </li>
                    <li><span class="font-weight-bold">{{ NomSurCarte }} {{ PrenomSurCarte }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.profession }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.adresse }}</span> </li>
                  </ul>

                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top:83px!important;list-style:none!important;margin-left: 30px!important;">
                    <li><span class="font-weight-bold">{{ categorie }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].marque }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].immatriculation
                        }}</span>
                    </li>
                    <li><span class="font-weight-bold">{{ NumPolice }}</span> </li>
                    <li v-show="isToday"><span class="font-weight-bold">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span> </li>
                    <li v-show="isNotToday"><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEffet) +
                      '00H00' }}</span> </li>
                    <li><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEcheance) + ' 24H' }}</span>
                    </li>
                  </ul>
                </div>

                <div class="column" style="width: 170px!important">
                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top:86px!important;list-style:none!important;margin-left: 50px!important;">
                    <li><span class="font-weight-bold">{{ categorie }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].marque }}</span> </li>
                    <li><span class="font-weight-bold"> {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].immatriculation
                        }}</span>
                    </li>
                    <li><span class="font-weight-bold">{{ NumPolice }}</span> </li>
                    <li v-show="isToday"><span class="font-weight-bold">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span> </li>
                    <li v-show="isNotToday"><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEffet) +
                      '00H00' }} </span> </li>
                    <li><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEcheance) + ' 24H' }}</span>
                    </li>
                  </ul>
                </div>

                <div class="column">
                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top: 33px!important;list-style:none!important;margin-left: 95px!important;">
                    <li><span class="font-weight-bold" v-show="user_name">{{ detailsContrat.user.prenom }}
                        {{ detailsContrat.user.nom.toUpperCase() }}</span> </li>
                    <li><span class="font-weight-bold"> {{ NomSurCarte }} {{ PrenomSurCarte }} </span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.profession }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.adresse }}</span> </li>
                  </ul>

                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top:91px!important;list-style:none!important;margin-left: 95px!important;">
                    <li><span class="font-weight-bold">{{ categorie }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].marque }}</span> </li>
                    <li><span class="font-weight-bold"> {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].immatriculation
                        }}</span>
                    </li>
                    <li><span class="font-weight-bold">{{ NumPolice }}</span> </li>
                    <li v-show="isToday"><span class="font-weight-bold">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span> </li>
                    <li v-show="isNotToday"><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEffet) +
                      '00H00' }} </span> </li>
                    <li><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEcheance) + ' 24H' }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- LA PARTIE ATTESTATION ALLIANZ -->
            <!-- LA PARTIE ATTESTATION ALLIANZ -->
            <!-- LA PARTIE ATTESTATION ALLIANZ -->
            <!-- LA PARTIE ATTESTATION ALLIANZ -->
            <div id="canvas" width="450" height="450" v-if="Allianz">
              <div class="ml-1 row" style="display:inline-flex!important">
                <div class="column" style="width:309px!important">
                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top: 25px!important;list-style:none!important;margin-left: 30px!important;">
                    <li><span class="font-weight-bold" v-show="user_name">{{ detailsContrat.user.prenom }}
                        {{ detailsContrat.user.nom.toUpperCase() }}</span> </li>
                    <li><span class="font-weight-bold">{{ NomSurCarte }} {{ PrenomSurCarte }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.profession }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.adresse }}</span> </li>
                  </ul>

                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top:83px!important;list-style:none!important;margin-left: 30px!important;">
                    <li><span class="font-weight-bold">{{ categorie }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].marque }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].immatriculation
                        }}</span>
                    </li>
                    <li><span class="font-weight-bold">{{ NumPolice }}</span> </li>
                    <li v-show="isToday"><span class="font-weight-bold">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span> </li>
                    <li v-show="isNotToday"><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEffet) +
                      '00H00' }}</span> </li>
                    <li><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEcheance) + ' 24H' }}</span>
                    </li>
                  </ul>
                </div>

                <div class="column" style="width: 158px!important">
                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top:86px!important;list-style:none!important;margin-left: 50px!important;">
                    <li><span class="font-weight-bold">{{ categorie }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].marque }}</span> </li>
                    <li><span class="font-weight-bold"> {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].immatriculation
                        }}</span>
                    </li>
                    <li><span class="font-weight-bold">{{ NumPolice }}</span> </li>
                    <li v-show="isToday"><span class="font-weight-bold">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span> </li>
                    <li v-show="isNotToday"><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEffet) +
                      '00H00' }} </span> </li>
                    <li><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEcheance) + ' 24H' }}</span>
                    </li>
                  </ul>
                </div>

                <div class="column">
                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top: 33px!important;list-style:none!important;margin-left: 95px!important;">
                    <li><span class="font-weight-bold" v-show="user_name">{{ detailsContrat.user.prenom }}
                        {{ detailsContrat.user.nom.toUpperCase() }}</span> </li>
                    <li><span class="font-weight-bold"> {{ NomSurCarte }} {{ PrenomSurCarte }} </span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.profession }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.user.adresse }}</span> </li>
                  </ul>

                  <ul class="AttestPoliceSize detailsSeeAttest"
                    style="margin-top:91px!important;list-style:none!important;margin-left: 95px!important;">
                    <li><span class="font-weight-bold">{{ categorie }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].marque }}</span> </li>
                    <li><span class="font-weight-bold"> {{ detailsContrat.details[0].vehicules[0].model }}</span> </li>
                    <li><span class="font-weight-bold">{{ detailsContrat.details[0].vehicules[0].immatriculation
                        }}</span>
                    </li>
                    <li><span class="font-weight-bold">{{ NumPolice }}</span> </li>
                    <li v-show="isToday"><span class="font-weight-bold">{{ DateFormatter(detailsContrat.dateEffet) }}
                        {{ MinuteFormatter(detailsContrat.dateEffet) }}</span> </li>
                    <li v-show="isNotToday"><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEffet) +
                      '00H00' }} </span> </li>
                    <li><span class="font-weight-bold">{{ DateFormater(detailsContrat.dateEcheance) + ' 24H' }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>

          <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS HABITATION -->
          <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS HABITATION -->
          <div v-if="detailsContrat.brancheid.branche == 'Habitation'">
            <p>Comming soon</p>
          </div>

          <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS VOYAGE -->
          <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS VOYAGE -->
          <div v-if="detailsContrat.brancheid.branche == 'Voyage'">
            <p>Comming soon</p>
          </div>

          <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS SANTÉ -->
          <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS SANTÉ -->
          <div v-if="detailsContrat.brancheid.branche == 'Santé'">
            <p>Comming soon</p>
          </div>

          <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS VIE  -->
          <!-- AFFICHAGE DE L'ATTESTATION SI C'EST DEVIS VIE -->
          <div v-if="detailsContrat.brancheid.branche == 'Vie'">
            <p>Comming soon</p>
          </div>
        </el-card>
      </div>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="downloadAttestationPdf()">Imprimer l'attestation</el-button>
      </div>
    </el-dialog>

    <el-dialog width="70%" title="Client" :visible.sync="showUser" v-if="showUser == true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%" title="Client" :visible.sync="showUser" v-if="showUser == true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%" title="Formulaire des avenants" persistent :visible.sync="showAvenant" v-if="showAvenant">
      <avenants-component :detailsContrat="detailsContrat"></avenants-component>
    </el-dialog>

    <el-dialog width="50%" title="Emission" :visible.sync="showcontratemission" v-if="showcontratemission == true && !isMobile">
            <el-row :gutter="15">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>Confirmez-vous l'émission de ce contrat numéro : <span class="assuraf-orange">
                                  {{ detailsContrat.numero_police }}</span> ? </span>
                            </div>
                            <div >
                              <el-col :span="18">
                                <div class="grid-content bg-purple mb-20">
                                  <label>Numero police assureur</label>
                                  <el-input placeholder="Police assureur" v-model="police_assureur">
                                  </el-input>
                                </div>
                              </el-col>
                            </div>
                        </el-card>
                    </div>
                    <div style="margin-top:15px">
                        <el-button type="primary" round @click="Emission(detailsContrat)">Oui</el-button>
                        <el-button type="danger" round @click="showcontratemission = false">Non</el-button>
                        <el-button type="secondary"  @click="initEmission(detailsContrat)" round >Reinitialiser</el-button>
                    </div>
                </el-col>
            </el-row>
    </el-dialog>


  </div>
</template>

<script>


import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
//fin importation assuraf
import Beneficiaire from "../../../models/Beneficiaire.class"
import html2pdf from "html2pdf.js";

export default {
  name: "StyledTablePage",
  components: {
    avenantsComponent: () => import('../../../components/avenant/render.component.vue'),
  },
  data() {
    return {
      attestation_digital_data  :null,
      police_assureur : '',
      showcontratemission: false,
      dateBeforeToday: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      showAvenant: false,
      Beneficiaire: new Beneficiaire(),
      set_update_ben: false,
      showUser: false,
      detailsUser: [],
      modalgenerationloading: false,
      show_comment_contrat: false,
      set_comment_contrat: false,
      set_story_avenant: false,
      story_avenant: [],
      commentcount: null,
      commentlist: null,
      comment: { userid: null, comment_text: null, contratid: null, createdat: null },

      optionsdate: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      JsonView: false,
      Delete_reason: null,
      checked: null,
      user_name: true,
      client_name: false,
      isToday: false,
      isNotToday: false,
      Sunu: false,
      Salama: false,
      Allianz: false,
      du: null,
      au: null,
      Askia: false,
      Axa: false,
      Amsa: false,
      devisclient: false,
      modalgeneration: false,
      modalNumPolice: false,
      modal_Delete_ok: false,
      modalNumPoliceAttest: false,
      NumPolice: null,
      NomPrenom: null,
      PrenomSurCarte: null,
      NomSurCarte: null,
      categorie: null,
      NumPoliceAttest: null,
      NumTalon: null,
      showUser: false,
      paramclient: null,
      detailsContrat: [],
      listcontrat: [],
      userid: null,
      userRole: null,
      showcontrat: false,
      showAttestation: false,
      notification_text: null,
      sonam: "SONAM GENERALE ASSURANCES",

      userflette: [],
      dateNaissance: null,
      editUser: false,
      Selectedbranche: null,
      selectedAssureur: [],
      configUserDevisAssureur: null,
      assureurs: [],
      branchelist: null,
      modalSociete: false,
      configHead: [
        { key: "branche", label: "branche" },
        { key: "assureurs", label: "assureur" },
        { key: "action", label: "Action" }
      ],
      selectedUser: null,
      listeAssureurs: null,
      usermenu: [],
      collapse: null,
      menu_name: null,
      showConfigMenu: false,
      submenu: null,
      userSubmenu: [],
      UserFinalMenu: [],

      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listcontrat,//users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() {
      if (this.search.length >= 3) {
        return this.listcontrat.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            /* Si la valeur courante est un String, et correspond à la valeur recherchée */
            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
              ctrl = true
              /*on le push dans le tableau */
              //this.listcontrat.push(obj) 
            } else {
              /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
              if (Array.isArray(obj[k])) {
                obj[k].filter(nobj => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
                      ctrl = true
                      /* on le push dans le tableau */
                      //this.listcontrat.push(obj)
                    }
                  }
                })
                /* Si la valeur courante n'est ni String, ni Tableau mais Object */
              } else if (!Array.isArray(obj[k]) && typeof obj[k] == "object" && obj[k] != null) {
                /*== On crée un nouveau tablequ vide ==*/
                let my_new_array = []

                /*== On push la valeur courante dans le nouveau tableau ==*/
                my_new_array.push(obj[k])

                /*On refiltre le nouveau tableau atrribut par attribut*/
                my_new_array.filter(nobj => {
                  for (let l in nobj) {
                    /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                    if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
                      ctrl = true

                      /* on le push dans le tableau */
                      //this.listcontrat.push(obj)
                    }
                  }
                })
              }
            }
          }
          return ctrl
        })
      } else {
        return this.listcontrat.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
              ctrl = true
          }
          return ctrl
        })
      }
    },

    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = ""
          let val2 = ""

          val1 = item1[prop]
          val2 = item2[prop]
          if (order === "descending") {
            return val2 < val1 ? -1 : 1
          }
          return val1 < val2 ? -1 : 1
        })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listcontrat.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    gotoEditAttestationPage(item){
      this.$router.push({ name: 'editAttestion', params: { id: item.id }})
    },
    showModalDownload(){
      this.modalNumPolice = true
      this.NumPolice = this.detailsContrat.police_assureur
    },
    dialogEmission(item) {
      this.detailsContrat = item
      this.showcontratemission = true,
      this.police_assureur =  this.detailsContrat.police_assureur ? this.detailsContrat.police_assureur : ''
    },

    Emission(item) {
      if(this.police_assureur) {
        Api().post('/contrat/update/emission', { numero_police: item.numero_police, police_assureur:  this.police_assureur })
        .then(resultat => {
          this.showcontratemission = false
          this.police_assureur = ''
          this.$message.success("Opération effectuée avec succés !",
            this.AlertMessage);
          this.getContrat()
        }).catch(err => {
          this.$message.error("Erreur lors de l'émission du contrat !",
            this.AlertMessage);
        });
      }else  { 
        this.$message.error("La police assureur est obligatoire!",
        this.AlertMessage);
      }

    
    },

    initEmission(item) {
      Api().post('/contrat/reinit/emission', { numero_police: item.numero_police})
        .then(resultat => {
          this.showcontratemission = false
          this.police_assureur = ''
          this.$message.success("Opération effectuée avec succés !",
            this.AlertMessage);
          this.getContrat()
        }).catch(err => {
          this.$message.error("Erreur lors de l'émission du contrat !",
            this.AlertMessage);
        });
    },

    go_express_payment(contrat) {
      this.detailsContrat = contrat
      localStorage.setItem("contrat_for_express_payment", contrat.numero_police)
      this.$router.go(this.$router.push({ name: 'paiement_cash' }))
    },
    countViePopulation(data) {
      if (data.contractor.Nom == data.customer.Nom && data.contractor.Prenom == data.customer.Prenom) {
        return 2
      } else if (data.contractor.Nom == data.customer.Nom && data.contractor.Prenom == data.customer.Prenom && data.contractor.Nom == data.beneficiary.Nom && data.contractor.Prenom == data.beneficiary.Prenom) {
        return 1
      } else {
        return 3
      }
    },

    SetDetailUser(details) {
      this.detailsUser = details
      this.showUser = true
    },

    /*==Téléchargement de contrat pdf vie ==*/
    DownloadContratVie(item) {
      Api().post('/contrat/download/contratvie', { id: item.id })
        .then((result) => {
          this.modalgeneration = true
          let url = result.data.lien
          setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
        }).catch((err) => {
          this.$message.error('Error HTTP !', this.AlertMessage)
        });
    },

    /*== Téléchargement de questionnaire médical pdf vie==*/
    Download_QmVie(item) {
      Api().post('/contrat/download/questionnaire_medical', { id: item.id })
        .then((result) => {
          this.modalgeneration = true
          let url = result.data.lien
          setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
        }).catch((err) => {
          this.$message.error('Error HTTP !', this.AlertMessage)
        });
    },

    updateBen() {

      if (this.Beneficiaire.Nom && this.Beneficiaire.Prenom) {
        if (
          this.dateNaissance &&
          this.Beneficiaire.LieuNaissance
        ) {
          if (!this.Beneficiaire.Filiation) {
            this.$message.error('Filiation obligatoire !', this.AlertMessage)
            return
          }
        } else {
          this.$message.error('Date et Lieu de naissance obligatoires !', this.AlertMessage)
          return
        }
      } else {
        this.$message.error('Nom et Prénom obligatoires !', this.AlertMessage)
        return
      }
      this.modalgeneration = true
      this.Beneficiaire.dateNaissance = this.dateNaissance
      const data = {
        _id: this.detailsContrat.id,
        Nom: this.Beneficiaire.Nom,
        Prenom: this.Beneficiaire.Prenom,
        dateNaissance: this.Beneficiaire.dateNaissance,
        LieuNaissance: this.Beneficiaire.LieuNaissance,
        Filiation: this.Beneficiaire.Filiation,
        Cni: this.Beneficiaire.Cni,
        Telephone: this.Beneficiaire.Telephone,
      }
      this.modalgenerationloading = true;
      Api().post('/micro/contrat/renouvellement', data)
        .then((result) => {
          this.$message.success('Bénéficiaire modifié avec succés !', this.AlertMessage)
          this.set_update_ben = false;
          this.showcontrat = false;
          this.modalgenerationloading = false;
          this.getContrat();
        }).catch((err) => {
          this.modalgenerationloading = false;
          console.log("error");
        });

      setTimeout(() => {
        this.modalgenerationloading = false
      }, 20000)
    },

    yearsDiffBenef() {
      const date1 = dayjs(this.Beneficiaire.dateNaissance);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, "days");
      this.age = daydiff;

      if (this.age < 0) {
        this.age = null;
        this.$toasted.show(
          "Erreur, date antérieure à la date actuelle! ",
          this.ToastTabError
        );
        this.Beneficiaire.dateNaissance = "";
      }
    },
    sendComment() {
      this.comment.userid = this.$store.state.user._id
      this.comment.contratid = this.detailsContrat.id
      this.comment.createdat = dayjs(new Date())
      if (this.comment.userid != '' && this.comment.userid != null && this.comment.contratid != '' && this.comment.contratid != null && this.comment.comment_text != null && this.comment.comment_text != '') {
        Api().post('/commentcontract/create', this.comment)
          .then(result => {
            if (result.data.status == 1) {
              this.comment.userid = null
              this.comment.contratid = null
              this.comment.comment_text = null
              this.comment.createdat = null
              this.$message.success(result.data.msg, this.AlertMessage)
            } else {
              this.$message.error(result.data.msg, this.AlertMessage)
            }
          }).catch(err => {
            this.$message.error('Error 400 !', this.AlertMessage)
          })
      } else {
        this.$message.error('Touts les champs sont obligatoires !', this.AlertMessage)
      }
    },

    SetCommentDetail() {
      this.set_comment_contrat = true
    },

    viewAvenant() {
      Api().post('/avenant/contrat/get', { numero_police: this.detailsContrat.numero_police })
        .then(result => {
          console.log("======= result ==========")
          console.log(result.data)
          this.story_avenant = result.data
          this.set_story_avenant = true
        }).catch(err => {
          console.log("======= err ==========")
          console.log(err)
        })

    },
    exportAsASACI() {
      this.modalgeneration = true
      Api().post('/export/contrat/asaci', { contrat_id: this.detailsContrat.id })
        .then(resultat => {

          if (resultat.data.lien) {
            setTimeout(() => (
              this.modalgeneration = false,
              window.open(resultat.data.lien)), 7000
            );
          } else {
            this.modalgeneration = false
            this.$message.error('Erreur lien non disponible code=500 !', this.AlertMessage)
          }

        }).catch(err => {
          this.modalgeneration = false
          this.$message.error('Erreur de telechargement code=500 !', this.AlertMessage)
          console.log("======= err ==========")
        })
    },
    updateBeneficiaire() {
      this.set_update_ben = true
      this.Beneficiaire = this.detailsContrat.details[0].beneficiary
    },
    getCommentDetail() {
      Api().post('/commentcontract/listing/bycontratid', { id: this.detailsContrat.id })
        .then(res => {
          this.commentlist = res.data.comments
          this.commentcount = res.data.count
          this.show_comment_contrat = true
        })
        .catch(err => {
          this.$message.error('Error 404 !', this.AlertMessage)
        })
    },

    /*Get comment for details or add comment on contract*/
    Get_set_Comment(item) {
      this.detailsContrat = item
      this.showcontrat = true
    },

    JsonFormaterView() {
      if (this.JsonView == true) {
        this.JsonView = false
      } else if (this.JsonView == false) {
        this.JsonView = true
      }
    },

    //Provisory Attestation download
    downloadAttestationProvisoire(contrat) {
      if (contrat.brancheid.branche == 'Voyage' && contrat.partenaireid.societe == this.sonam) {
        this.modalgenerationloading = true
        let url = contrat.lien_contrat
        setTimeout(() => (
          this.modalgenerationloading = false,
          window.open(url)), 7000);
      } else {
        this.modalgenerationloading = true
        Api().get('contrat/attestation/download/' + contrat.id)
          .then(response => {
           
            let url = response.data.lien
            setTimeout(() => (
              this.modalgenerationloading = false,
              window.open(url)), 7000);
          })
      }
    },

    checkedControl() {
      if (this.checked === true) {
        this.client_name = true
        this.user_name = null
      } else if (this.checked === false) {
        this.client_name = false
        this.user_name = true
        this.NomPrenom = null
        this.PrenomSurCarte = null
        this.NomSurCarte = null
      }
    },

    CheckIfTypeDefined(item) {
      return typeof item
    },

    /*==== EXPORT Liste Contrat en XLS ====*/
    exportToExcel() {
      Api().get('/export/contrat')
        .then(resultat => {
          this.modalgeneration = true
          let url = resultat.data.lien
          setTimeout(() => (
            this.modalgeneration = false,
            window.open(url)), 7000
          );
        });
    },

    DateFormatter: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    MinuteFormatter(TheDate) {
      if (TheDate) {
        return dayjs(new Date()).format('HH:mm')
      }
    },

    // downloadAttestationPdf() {
    //   let pdfName = this.detailsContrat.user.prenom
    //   const canvas = document.getElementById('canvas')

    //   html2canvas(canvas).then(function (canvas) {
    //     var imgData = canvas.toDataURL('image/png')
    //     var doc = new jsPDF('landscape')
    //     doc.addImage(imgData, 'PNG', 10, 65)
    //     doc.save('Attestation-' + pdfName + '.pdf')
    //   })
    //   this.showAttestation = false
    // },


    downloadAttestationPdf() {
      let pdfName = this.detailsContrat.user.prenom
      html2pdf(document.getElementById("canvas"), {
        margin: [60, 20, 0, 0],
        filename: ("Attestation " + pdfName + ".pdf"),
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { orientation: 'landscape' }

      });
    },


    detailAttestation: function () {
      let today = dayjs(new Date()).format('DD-MM-YYYY')
      if (this.DateFormatter(this.detailsContrat.dateEffet) == today) {
        this.isToday = true
        this.isNotToday = false
      } else {
        this.isNotToday = true
        this.isToday = false
      }

      this.showAttestation = true
      if (this.detailsContrat.partenaireid.societe == 'SUNU') {
        this.Sunu = true
        this.Askia = false
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'PROVIDENCE') {
        this.Sunu = true
        this.Askia = false
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'NSIA') {
        this.Sunu = true
        this.Askia = false
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'ASKIA') {
        this.Askia = true
        this.Sunu = false
        this.Axa = false
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'AXA' || this.detailsContrat.partenaireid.societe == 'SANLAM') {
        this.Axa = true
        this.Askia = false
        this.Sunu = false
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'AMSA') {
        this.Axa = false
        this.Askia = false
        this.Sunu = false
        this.Amsa = true
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'SALAMA') {
        this.Sunu = false
        this.Askia = false
        this.Amsa = false
        this.Axa = false
        this.Salama = true
        this.modalNumPoliceAttest = true
      } else if (this.detailsContrat.partenaireid.societe == 'ALLIANZ') {
        this.Sunu = false
        this.Askia = false
        this.Amsa = false
        this.Axa = false
        this.Salama = false
        this.Allianz = true
        this.modalNumPoliceAttest = true
      }
      if (this.showAttestation == true) {
        this.showcontrat = false
      }
      if (this.detailsContrat.user.profession == null) {
        this.detailsContrat.user.profession = '-'
      }
    },

    detailcontrat(item) {
        if (item.details && item.details.length > 1 && item.details[1].attestation_digitale !== undefined){
          this.attestation_digital_data = item.details[1].attestation_digitale;
        }
    
      this.detailsContrat = item
      this.showcontrat = true
    },

    DeleteContrat(item) {
      this.detailsContrat = item
      this.modalDelete = true
    },

    RemoveContrat(item) {
      Api().post('/contrat/remove/', { id: item.id, userid: store.state.user._id })
        .then((result) => {
          if (result.data.msg == 'Contrat supprimé !') {
            this.showcontrat = false
            this.getContrat()
          }
        }).catch((err) => {
          console.log('Oups !')
        });

    },

    ArchiveContract(item) {
      if (this.Delete_reason) {
        let ctr = {
          id: item.id,
          userid: store.state.user._id,
          brancheid: item.brancheid._id,
          archive_reason: this.Delete_reason
        }

        Api().post('/contrat/delete/', ctr)
          .then((result) => {
            if (result.data.rep.n == 1) {
              this.showcontrat = false
              this.Delete_reason = null
              this.modalDelete = false
              this.getContrat()
            }
          }).catch((err) => {
            console.log('Oups !')
          });
      } else {
        this.$message.error('Champs obligatoires !', this.AlertMessage)
      }
    },

    getContrat() {

      if (this.$store.state.user.role == 'Agent') {
        Api().post('/contrat/courtier/encours', { courtierid: this.$store.state.user.societe })
          .then(resultat => {
            this.listcontrat = resultat.data.contrats
            this.totalRows = resultat.data.contrats.length
          });

      } else if (this.$store.state.user.role == 'courtier') {
        Api().get('/contrat/courtier/encours/' + this.$store.state.user.societe)
          .then(resultat => {
            this.listcontrat = resultat.data.contrats
            this.totalRows = resultat.data.contrats.length
          });

      } else if (this.$store.state.user.role == 'Assureur') {
        Api().get('/contrat/assureur/encours/' + this.$store.state.user.societe)
          .then(resultat => {
            this.listcontrat = resultat.data.contrats
            this.totalRows = resultat.data.contrats.length
          });

      } else if (this.$store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin') {
        Api().get('/contrat/encours/')
          .then(resultat => {
            this.listcontrat = resultat.data.contrats
            this.totalRows = resultat.data.contrats.length
          });
      }

    },

    SetDetailUser(details) {
      this.detailsUser = details
      this.showUser = true
    },

    Validate_numePolice(contrat) {
      if (this.NumPolice) {
        contrat.NumPolice = this.NumPolice
        this.Contract_generator(contrat)
      } else {
        this.$message.error('Numéro de police obligatoire !', this.AlertMessage)
      }

    },

    Validate_numePoliceAttest() {
      if (this.checked) {
        if (this.PrenomSurCarte && this.NomSurCarte && this.NumPolice && this.categorie) {
          let data = {
            idContart: this.detailsContrat.id,
            nom: this.NomSurCarte,
            prenom: this.PrenomSurCarte
          }
          Api().post('/contrat/update/user_on_gray_card', data)
            .then((result) => {
              if (result.data.response.nModified || result.data.response.ok) {
                this.modalNumPoliceAttest = false
              }
            }).catch((err) => { });

        } else {
          this.$message.error('Champs obligatoires !', this.AlertMessage)
        }

      } else {
        if (this.NumPolice && this.categorie) {
          this.modalNumPoliceAttest = false
        } else {
          this.$message.error('Champs obligatoires !', this.AlertMessage)
        }
      }

    },

    Contract_generator: function (contrat) {
      this.modalNumPolice = false
      this.modalNumPoliceAttest = false

      switch (contrat.brancheid.branche) {
        case 'Automobile':
          let data = {
            idContrat: contrat.id,
            numpolice: this.NumPolice,
            numTalon: this.NumTalon
          }
          Api().post('contrat/definitif/auto', data)
            .then(response => {
              this.NumPolice = null
              this.modalgeneration = true
              let url = response.data.lien
              setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
            })
          break;
        case 'Habitation':
          Api().get('contrat/definitif/mrh/' + contrat.id)
            .then(response => {
              this.modalgeneration = true
              let url = response.data.lien
              setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
            })
          break;
        case 'Voyage':
          let Traveldata = {
            idContrat: contrat.id,
            numpolice: this.NumPolice,
            numTalon: this.NumTalon
          }
          Api().post('contrat/definitif/voyage', Traveldata)
            .then(response => {
              this.modalgeneration = true
              let url = response.data.lien
              setTimeout(() => (this.modalgeneration = false, window.open(url)), 7000);
            })
          break;

      }

    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000
      });
    },

    detaildevis(item) {

      this.detailsDevis = item
      this.showdevis = true
    },

    // Fonction de download du devis en fichier pdf
    Downloa_devis: function (devis) {
      this.modalgenerationloading = true;
      Api().get('devis/download/' + devis._id)
        .then(response => {
          //this.modalgeneration = true
          let url = response.data.lien
          setTimeout(() => (
            this.modalgenerationloading = false,
            window.open(url)), 7000);
        })
    },

    dialogAvenant(contrat) {
      this.showAvenant = true;
      this.detailsContrat = contrat;
    },
    Edit_devis: function (item) {
      switch (item.brancheid.branche) {
        case 'Automobile':
          Api().get('devis/' + item.devisid._id)
            .then(response => {
              delete response.data.devis.type_avenant
              const newDevis = {
                type_avenant: "RENOUVELLEMENT",
                ...response.data.devis
              }
              this.$store.dispatch('setDevisReopen', newDevis)
              this.$router.push({ name: 'avenant_update_devis_auto' })
            })
          break
      }
    },



    Remover() {
      Api().post('sinistre/remove')
        .then(rep => { })
    },

    getDevis() {

      if (this.$store.state.user.role == 'agent général') {
        Api().get('/devis/assureur/' + this.$store.state.user.societe)
          .then(resultat => {
            this.listdevis = resultat.data.devis
            this.totalRows = this.listdevis.length
          });
      } else if (this.$store.state.user.role == 'Agent') {
        Api().get('/devis/courtier/' + this.$store.state.user.societe)
          .then(resultat => {
            this.listdevis = resultat.data.devis
            this.totalRows = resultat.data.devis.length
          });

      } else if (this.$store.state.user.role == 'Assureur') {
        Api().get('/devis/assureur/' + this.$store.state.user.societe)
          .then(resultat => {
            this.listdevis = resultat.data.devis
            this.totalRows = resultat.data.devis.length
          });
      } else if (this.$store.state.user.role == 'Admin') {
        Api().get('/devis/')
          .then(resultat => {
            this.listdevis = resultat.data.devis
            this.totalRows = resultat.data.devis.length
          });
      }

    },

    devisDeleteConfirmation(item) {
      this.modalDelete = true
      this.detailsDevis = item
    },

    ArchivateDevis(devis) {
      let id = devis._id
      Api().post('devis/archivate/' + id)
        .then(resp => {
          this.getDevis()
        })
    },

    AddEtatUser() {
      Api().post('devis/addetatuser')
        .then(resultat => { })
        .catch(err => { })
    },

    DeletingDevis(id) {
      Api().delete('devis/delete/' + id)
        .then(resultat => {
          if (resultat.data.rep.n == 1) {
            this.modalDelete = false
            this.getDevis()
          }
        })
        .catch(err => { })
    },

    FiltreDate() {
      if (this.du && this.au) {
        switch (store.state.user.role) {
          case 'Admin':
            Api().post('contrat/filtreContrat/periode', {
              du: this.du,
              au: this.au
            })
              .then(resultat => {
                this.listcontrat = resultat.data.contrats
              });
            break;
          case 'SuperAdmin':
            Api().post('contrat/filtreContrat/periode', {
              du: this.du,
              au: this.au
            })
              .then(resultat => {
                this.listcontrat = resultat.data.contrats
              });
            break;
          case 'Agent':
            Api().post('contrat/filtreContrat/courtier/periode/' + store.state.user.societe, {
              du: this.du,
              au: this.au
            })
              .then(resultat => {
                this.listcontrat = resultat.data.contrats
              });
            break;

          case 'assureur':
            Api().post('contrat/filtreContrat/assureur/periode/' + store.state.user.societe, {
              du: this.du,
              au: this.au
            })
              .then(resultat => {
                this.listcontrat = resultat.data.contrats
              });
            break;
          case 'agent général':
            Api().post('contrat/filtreContrat/assureur/periode/' + store.state.user.assureur, {
              du: this.du,
              au: this.au
            })
              .then(resultat => {
                this.listcontrat = resultat.data.contrats
              });
            break;

          default:
            break;
        }
      }


    },

    toggleDetails(item) {
      this.detailsUser = item
      this.editUser = true
    },


    DeleteBrancheCourtier(item) {
      Api().post("/branchecourtier/delete", { id: item._id })
        .then((result) => {
          Api().post("/branchecourtier/get/all/", { userid: item.userid })
            .then((response) => {
              this.configUserDevisAssureur = response.data.partenaires
            })
            .catch((err) => { });
        }).catch((err) => {

        });
    },

    AddbrancheConfig() {
      Api().post("/branchecourtier/create", { configuration: this.configUserDevisAssureur })
        .then((result) => {
          this.configUserDevisAssureur = []
        }).catch((err) => {
          console.log(err)
        });
    },

    pushConfig() {
      if (this.Selectedbranche && this.selectedAssureur) {
        this.configUserDevisAssureur.push({
          branche: this.Selectedbranche,
          assureurs: this.selectedAssureur,
          userid: this.selectedUser.id
        })
        this.Selectedbranche = null
        this.selectedAssureur = []
      }
    },

    loadConfigBranchePopUp(item) {
      this.modalSociete = true
      this.selectedUser = item
      Api().post("/branchecourtier/get/all/", { userid: item.id })
        .then((response) => {
          this.configUserDevisAssureur = response.data.partenaires
        })
        .catch((err) => { });
    },

    setCollapse() {
      this.collapse = "id_" + Date.now() + "_collapse"
    },

    MenuDelete(item) {
      Api().delete("/menu/" + item._id)
        .then((result) => {
          this.getUserMenu(this.detailsUser._id)
        }).catch((err) => {

        });
    },

    pushMenu() {
      if (this.menu_name && this.userSubmenu.length >= 1) {
        this.UserFinalMenu.push({
          user: this.detailsUser.id,
          theme: this.menu_name,
          collapse: this.collapse,
          config: this.userSubmenu
        })
        this.menu_name = null
        this.userSubmenu = []
        this.collapse = null
      } else {
        this.$message.error('Remplissez les champs vides !', this.AlertMessage)
      }
    },

    create_user_menu() {
      if (this.UserFinalMenu.length >= 1) {
        Api().post("/menu/add", this.UserFinalMenu)
          .then((result) => {
            this.$message.success('Opération effectuée !', this.AlertMessage)
            console.log(result.data)
            this.UserFinalMenu = []
          }).catch((err) => {
            console.log(err)
          });
      } else {
        this.$message.error('Le menu ne doit pas être vide !', this.AlertMessage)
      }
    },

    merge() {
      Api().post('/user/merge/userdata', { userid: this.detailsUser.id })
        .then((result) => {
          this.showuser = false
          if (result.data.result) {
            alert('Opération réussie')
          }
        }).catch((err) => {
          console.log('=======err=========')
        });
    },

    detailuser(item) {
      this.getUserMenu(item._id)
      this.detailsUser = item
      this.showuser = true
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList: function () {
      switch (this.$store.state.user.role) {
        case 'Admin':
          Api().get('/user/frontoffice/')
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;
        case 'assureur':
          Api().get('/user/myuser/assureur/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;

        default:
          break;
      }
    },

    UpdateUser(item) {

      let idU = item.id
      Api().post('user/' + idU, item)
        .then(response => {
          if (response.data) {
            this.showAlert()
          }
          this.showAlert()
        })
        .catch(err => {

        })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },

    DeletingUser(id) {
      Api().delete('user/delete/' + id)
        .then(resultat => {
          if (resultat.status == 200) {
            this.modalDelete = false
            this.getUserList()
          }
        })
        .catch(err => { })
    },

    getUserMenu(id) {
      Api().post('/menu/user/menu', { userid: id })
        .then((result) => {
          this.usermenu = result.data.menus
        }).catch((err) => {
          console.log(err)
        });
    },




    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function (e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function (value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
    //this.getContrat()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.getContrat()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";


.detailsSeeAttest li {
  list-style: none !important;
  line-height: 16px;
  font-family: Arial, sans-serif;
}

.AttestPoliceSize {
  font-size: 12px !important;
}

.detailsSee li {
  list-style: none;
  border-bottom: 1px solid #000;
}


.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {

      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }

          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      &>* {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}

.button-filtre {
  padding: 12px !important;
  margin: 0 15px !important;
  background: #C0C7CF !important;
  border: none !important;
  color: #fff !important;
}
</style>
