<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste des<span style="color:#ffa409"> pré contrats</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input placeholder="Rechercher devis..." prefix-icon="el-icon-search" v-model="search" clearable>
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input placeholder="Rechercher devis..." prefix-icon="el-icon-search" v-model="search" clearable>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />

    <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
      <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading">Exporter en Excel</el-button>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table :data="listInPage" style="width: 100%" :height="height" v-if="ready">
        <el-table-column label="Téléphone Client" min-width="150" prop="customer_phone"></el-table-column>
        <el-table-column label="Branche" min-width="200" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope="scope">
            <span class="date">{{ scope.row.prime_ttc.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Créateur" min-width="180" prop="creator">
          <template slot-scope="scope" v-if="$store.state.user.role == 'SuperAdmin' || $store.state.user.role == 'Agent'">
            <span v-if="scope.row.createur" class="date">{{ scope.row.createur.prenom }} - {{ scope.row.createur.nom
            }} </span>
            <span v-else>
              Admin
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailContrat(scope.row)" type="primary" icon="el-icon-view"
              circle></el-button>
            <el-button size="mini" v-loading.fullscreen.lock="modalgenerationloading"
              @click="transformToContrat(scope.row)" :disabled="scope.row.isRegler" type="primary" icon="el-icon-check" circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
        :total="total"></el-pagination>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table :data="listInPage" style="width: 100%" v-if="ready">
        <el-table-column label="Client" min-width="200" prop="client">
          <template slot-scope="scope">
            <span class="date">
              <a href="#" @click="SetDetailUser(scope.row.userid)">
                {{ scope.row.userid.prenom }} - {{ scope.row.userid.nom }}
              </a>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Num devis" min-width="180" prop="numeroDevis"></el-table-column>
        <el-table-column label="Branche" min-width="150" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Plateforme" min-width="150" prop="plateforme">
          <template slot-scope="scope">
            <span class="date" style="color:#ffa409">{{ scope.row.plateforme }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Assureur" min-width="150" prop="partenaireid.societe"></el-table-column>
        <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
          <template slot-scope="scope">
            <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
          <template slot-scope="scope">
            <span>{{ scope.row.prime_ttc.toLocaleString() }} CFA</span>
          </template>
        </el-table-column>
        <el-table-column label="Créateur" min-width="180" prop="creator">
          <template slot-scope="scope" v-if="$store.state.user.role == 'SuperAdmin' || $store.state.user.role == 'Agent'">
            <span v-if="scope.row.createur" class="date">{{ scope.row.createur.prenom }} - {{ scope.row.createur.nom
            }}</span>
            <span v-else>
              Admin
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Actions" prop="action" min-width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailContrat(scope.row)" type="primary" icon="el-icon-view"
              circle></el-button>
            <el-button size="mini" v-loading.fullscreen.lock="modalgenerationloading"
              @click="transformToContrat(scope.row)" type="primary" icon="el-icon-check" circle></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
        :total="total"></el-pagination>
    </div>

    <el-dialog width="70%!important" title="Détails devis" :visible.sync="showContrat"
      v-if="showContrat == true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <!-- <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateSouscription)}}</span></li> -->
                  <li>Date Effet : <span class="to-right assuraf-orange">{{ DateFormater(detailsDevis.dateEffet) }}</span>
                  </li>
                  <li>Date Echéance : <span class="to-right assuraf-orange">{{ DateFormater(detailsDevis.dateEcheance)
                  }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Prime nette : <span class="to-right assuraf-orange">{{ detailsDevis.prime_nette.toLocaleString() }}
                      CFA</span></li>
                  <li>Prime ttc : <span class="to-right assuraf-orange">{{ detailsDevis.prime_ttc.toLocaleString() }}
                      CFA</span></li>
                  <li>Taxe : <span class="to-right assuraf-orange">{{ detailsDevis.taxe.toLocaleString() }} CFA</span>
                  </li>
                  <li>Accessoires : <span class="to-right assuraf-orange">{{ detailsDevis.accessoires.toLocaleString() }}
                      CFA</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%!important" title="Détails devis" :visible.sync="showContrat"
      v-if="showContrat == true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <!-- <li>Date de création : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateSouscription)}}</span></li> -->
                  <li>Date Effet : <span class="to-right assuraf-orange">{{ DateFormater(detailsDevis.dateEffet) }}</span>
                  </li>
                  <li>Date Echéance : <span class="to-right assuraf-orange">{{ DateFormater(detailsDevis.dateEcheance)
                  }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Prime nette : <span class="to-right assuraf-orange">{{ detailsDevis.prime_nette.toLocaleString() }}
                      CFA</span></li>
                  <li>Prime ttc : <span class="to-right assuraf-orange">{{ detailsDevis.prime_ttc.toLocaleString() }}
                      CFA</span></li>
                  <li>Taxe : <span class="to-right assuraf-orange">{{ detailsDevis.taxe.toLocaleString() }} CFA</span>
                  </li>
                  <li>Accessoires : <span class="to-right assuraf-orange">{{ detailsDevis.accessoires.toLocaleString() }}
                      CFA</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%" title="Client" :visible.sync="showUser" v-if="showUser == true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%" title="Client" :visible.sync="showUser" v-if="showUser == true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div>
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{ detailsUser.nom }}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{ detailsUser.prenom }}</span></li>
                  <li>Téléphone : <span class="to-right assuraf-orange">{{ detailsUser.telephone_port }}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{ detailsUser.email }}</span></li>
                  <li>Adresse : <span class="to-right assuraf-orange">{{ detailsUser.adresse }}</span></li>
                  <li>Profession : <span class="to-right assuraf-orange">{{ detailsUser.profession }}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsUser.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%!important" title="Validation du pré contrat" :visible.sync="validateContratModalMicroAuto"
      v-if="!isMobile">
      <div class="grid-content bg-purple" v-if="detailsDevis != null">
        <div v-if="e1 == 0" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
          <h3>Vous souhaitez une assurance pour :</h3>
          <el-row justify="center">
            <el-col>
              <div class="grid-content bg-purple">
                <el-radio @change="getUserCars()" v-model="myCars" size="medium" label="mes_vehicule">Mes
                  véhicules</el-radio>
              </div>
            </el-col>
            <el-col>
              <div class="grid-content bg-purple-light">
                <el-radio @change="getUserCars()" v-model="myCars" label="nouveau">Nouveau véhicule</el-radio>
              </div>
            </el-col>
          </el-row>
        </div>

        <div v-if="e1 == 1 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
          <div>
            <div>
              <h3>Informations du véhicule :</h3>
              <el-row :gutter="20">
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <label>Marque du véhicule</label>
                    <el-select style="width: 100%!important;" v-model="marque" placeholder="Selectionner">
                      <el-option v-for="(items, index) in listMarque" :key="index" :label="items.Marque"
                        :value="items.Marque">
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple-light">
                    <label>Modéle du véhicule</label>
                    <el-select style="width: 100%!important;" v-model="model" placeholder="Selectionner">
                      <el-option v-for="(items, index) in listeModeles" :key="index" :label="items.Type"
                        :value="items.Type">
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple-light">
                    <label>Immatriculation</label>
                    <el-input v-mask="mask" placeholder="Immatriculation" v-model="immatriculation">
                    </el-input>
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="20" style="margin-top:15px!important">
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <label>Carburation</label>
                    <el-radio v-model="carburant" label="Essence">Essence</el-radio>
                    <el-radio v-model="carburant" label="Diésel">Diesel</el-radio>
                    <el-radio v-model="carburant" label="Electrique">Eléctrique</el-radio>
                    <el-radio v-model="carburant" label="Hybride">Hybride</el-radio>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <label>Nombre de chevaux</label>
                    <el-select style="width: 100%!important;" v-model="force" placeholder="Selectionner">
                      <el-option v-for="items in PuissanceFinscaleItems" :key="items" :label="items" :value="items">
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple-light">
                    <label>Nombre de places</label>
                    <el-input type="number" placeholder="Nombre de places" v-model="nbplace">
                      <template slot="append">place(s)</template>
                    </el-input>
                  </div>
                </el-col>
              </el-row>


              <el-row :gutter="20" style="margin-top:15px!important">
                <el-col :span="8">
                  <div class="grid-content bg-purple">
                    <label>Date 1ére mise en circulation</label>
                    <el-date-picker style="width: 100%!important;" @change="yearsDiff()" v-model="dateCircule" type="date"
                      placeholder="Choississez une date" :picker-options="dateBeforeToday">
                    </el-date-picker>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="grid-content bg-purple-light">
                    <label>Âge du véhicule</label>
                    <el-input readonly placeholder="Âge du véhicule" v-model="age">
                      <template slot="append">{{ annee_mois }}</template>
                    </el-input>
                  </div>
                </el-col>

                <el-col :span="8">
                  <div class="grid-content bg-purple" v-if="categorie !== '2_roues'">
                    <label>Valeur actuelle</label>
                    <el-input v-money="money" placeholder="Valeur actuelle" v-model="valeur_venale">
                      <template slot="append">CFA</template>
                    </el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5" style="margin-top: 15px" v-if="e1 == 1">
        <el-button type="danger" round @click="closeContratModal()">Annuler</el-button>
        <el-button type="primary" round @click="createMicroContratAuto()">Valider</el-button>
      </div>
    </el-dialog>

    <el-dialog width="70%!important" title="Validation du pré contrat" :visible.sync="validateContratModalMicroHabitation"
      v-if="!isMobile">
      <div v-if="e1 == 0" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
        <h3>Vous souhaitez une assurance pour :</h3>
        <el-row justify="center">
          <el-col>
            <div class="grid-content bg-purple">
              <el-radio @change="getUserHabitation()" v-model="myHabitations" label="mes_habitations">Mes
                logements</el-radio>
            </div>
          </el-col>
          <el-col>
            <div class="grid-content bg-purple-light">
              <el-radio @change="getUserHabitation()" v-model="myHabitations" label="nouveau">Nouveau logement</el-radio>
            </div>
          </el-col>
        </el-row>
      </div>

      <div v-if="e1 == 1 && !isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
        <div>
          <div class="grid-content bg-purple" v-if="detailsDevis != null">
            <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
              <div>
                <div>
                  <h3>Informations du logement :</h3>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <div>
                        <label>Bien à assurer</label>
                        <el-row :gutter="15" justify="center">
                          <el-col>
                            <div class="grid-content bg-purple">
                              <el-radio v-model="naturelogement" label="appartement">Appartement</el-radio>
                            </div>
                          </el-col>
                          <el-col>
                            <div class="grid-content bg-purple-light">
                              <el-radio v-model="naturelogement" label="maison">Maison</el-radio>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div>
                        <label>Nombre de pièces</label>
                        <el-input type="number" placeholder="Nombre de pièces" v-model="nombrepiece">
                        </el-input>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div v-if="naturelogement == 'appartement'">
                        <label>Superficie (en m2)</label>
                        <el-input type="number" placeholder="Surface developpée" v-model="nombreetage">
                        </el-input>
                      </div>
                      <div v-if="naturelogement == 'maison'">
                        <label>Superficie (en m2)</label>
                        <el-input type="number" placeholder="Surface developpée" v-model="nombreetage">
                        </el-input>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row :gutter="20" style="margin-top:15px!important">
                    <el-col :span="8">
                      <div class="grid-content bg-purple">
                        <label>Selectionner un type de toiture</label>
                        <el-select style="width: 100%!important;" v-model="naturecouverture" placeholder="Selectionner">
                          <el-option v-for="items in optionstoiture" :key="items.text" :label="items.text"
                            :value="items.value"></el-option>
                        </el-select>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content bg-purple-light">
                        <label>Environnement immmédiat</label>
                        <el-select style="width: 100%!important;" v-model="environnement_immediat"
                          placeholder="Selectionner">
                          <el-option v-for="items in optionsenvi" :key="items.text" :label="items.text"
                            :value="items.value"></el-option>
                        </el-select>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="grid-content bg-purple-light">
                        <label>Adresse du logement</label>
                        <el-input placeholder="Adresse du logement" v-model="adresselogement">
                        </el-input>
                      </div>
                    </el-col>
                  </el-row>


                  <el-row :gutter="20" style="margin-top:15px!important">
                    <el-col :span="6">
                      <div>
                        <label>Propriétaire</label>
                        <el-row :gutter="15" justify="center">
                          <el-col>
                            <div class="grid-content bg-purple">
                              <el-radio v-model="qualiteassure" label="locataire">Locataire</el-radio>
                            </div>
                          </el-col>
                          <el-col>
                            <div class="grid-content bg-purple-light">
                              <el-radio v-model="qualiteassure" label="Proprietaire_et_occupant">Propriétaire
                                occupant</el-radio>
                            </div>
                          </el-col>
                          <el-col>
                            <div class="grid-content bg-purple-light">
                              <el-radio v-model="qualiteassure" label="Proprietaire_non_occupant"
                                disabled="true">Propriétaire
                                non-occupant (Nous consulter)</el-radio>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div v-if="qualiteassure === 'locataire'">
                        <label>Montant du loyer</label>
                        <el-input placeholder="Montant" v-model="montantLoyer">
                        </el-input>
                      </div>
                    </el-col>

                    <el-col :span="6">
                      <div>
                        <label>Valeur du contenu à garantir</label>
                        <el-input v-money="money" placeholder="Montant" v-model="valeurcontenu">
                        </el-input>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div>
                        <label>Valeur du bâtiment</label>
                        <el-input v-if="valeurbatiment" placeholder="Montant" v-model="valeurbatiment">
                        </el-input>
                        <el-input v-money="money" v-else placeholder="Montant" v-model="valeurbatiment">
                        </el-input>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5" style="margin-top: 15px" v-if="e1 == 1 && !isMobile">
        <el-button type="danger" round @click="closeContratModal()">Annuler</el-button>
        <el-button type="primary" round @click="createMicroContratHabitation()">Valider</el-button>
      </div>
    </el-dialog>

    <!-- <el-dialog width="70%!important" title="Validation du pré contrat" :visible.sync="validateContratModalMicroVie"
      v-if="!isMobile">
      {{ detailsDevis  }}
     
      <div class="mb-5" style="margin-top: 15px" v-if="e1 == 1 && !isMobile">
        <el-button type="danger" round @click="closeContratModal()">Annuler</el-button>
        <el-button type="primary" round @click="createMicroContratVie()">Valider</el-button>
      </div>
    </el-dialog> -->

    <el-dialog width="70%" title="Mes véhicules" :visible.sync="ModalListeContrat" v-if="ModalListeContrat && !isMobile">
      <el-table :data="userCars" class="padded-table" height="300" style="width: 100%" row-key="id">
        <el-table-column prop="marque" label="Marque" min-width="200"> </el-table-column>
        <el-table-column prop="model" label="Modèle" min-width="80"> </el-table-column>
        <el-table-column prop="nbplace" label="Nombre de places" min-width="150">
        </el-table-column>
        <el-table-column prop="force" label="Force" min-width="100"> </el-table-column>
        <el-table-column prop="dateCircule" label="Date 1ère mise en circule" min-width="200">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateCircule) }}
          </template>
        </el-table-column>
        <el-table-column prop="valeur_venale" label="Valeur actuelle" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.valeur_venale }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="getCarForContrat(scope.row)" type="primary" icon="el-icon-right" circle
              title="Voir détails"></el-button>
            <el-button size="mini" @click="deleteUserCar(scope.row)" type="danger" icon="el-icon-delete" circle
              title="Désarchiver"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog width="90%" title="Mes véhicules" :visible.sync="ModalListeContrat" v-if="ModalListeContrat && isMobile">
      <el-table :data="userCars" class="padded-table" style="width: 100%" row-key="id">
        <el-table-column prop="marque" label="Marque" min-width="150"> </el-table-column>
        <el-table-column prop="model" label="Modèle" min-width="80"> </el-table-column>
        <el-table-column label="Actions" prop="action" min-width="100">
          <template slot-scope="scope">
            <el-button @click="getCarForContrat(scope.row)" type="primary" icon="el-icon-right" size="mini" circle
              title="Voir détails"></el-button>
            <el-button @click="deleteUserCar(scope.row)" type="danger" icon="el-icon-delete" size="mini" circle
              title="Désarchiver"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog width="70%" title="Mes logements" :visible.sync="ModalListeHabitation" v-if="ModalListeHabitation">
      <el-table :data="userHabitations" class="padded-table" height="200" style="width: 100%" row-key="id">
        <el-table-column prop="typeBien" label="Type de bien" min-width="200"> </el-table-column>
        <el-table-column prop="nombrepiece" label="Nombre de pièces" min-width="200"> </el-table-column>
        <el-table-column prop="superficie" label="Superficie" min-width="120">
        </el-table-column>
        <el-table-column prop="naturecouverture" label="Nature couverture" min-width="200"> </el-table-column>
        <el-table-column prop="adresselogement" label="Adresse" min-width="200"> </el-table-column>
        <el-table-column prop="valeurcontenu" label="Valeur du contenu" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.valeurcontenu }} CFA
          </template>
        </el-table-column>
        <el-table-column prop="valeurbatiment" label="Valeur du batiment" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.valeurbatiment }} CFA
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="120" v-if="!isMobile">
          <template slot-scope="scope">
            <el-button @click="getLogementForContrat(scope.row)" type="primary" icon="el-icon-right" circle
              title="Voir détails"></el-button>
            <el-button @click="deleteHabitation(scope.row)" type="danger" icon="el-icon-delete" circle
              title="Désarchiver"></el-button>
          </template>
        </el-table-column>
        <el-table-column label="Actions" prop="action" min-width="120" v-if="isMobile">
          <template slot-scope="scope">
            <el-button @click="getLogementForContrat(scope.row)" type="primary" icon="el-icon-right" circle
              title="Voir détails"></el-button>
            <el-button @click="deleteHabitation(scope.row)" type="danger" icon="el-icon-delete" circle
              title="Désarchiver"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile">
      <el-form ref="form" label-width="120px" label-position="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="userInfo.nom"></el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input placeholder="Votre prenom" v-model="userInfo.prenom"></el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top:15px">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Numéro de téléphone</label>
              <el-input placeholder="Numéro de téléphone" v-model="userInfo.telephone_port"></el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Adresse e-mail</label>
              <el-input placeholder="votre adresse e-mail" v-model="userInfo.email"></el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top:15px">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Sexe</label>
              <el-select style="width: 100%!important;" v-model="userInfo.sexe" placeholder="Selectionner votre sexe">
                <el-option label="Homme" value="homme"></el-option>
                <el-option label="Femme" value="femme"></el-option>
                <el-option label="Entreprise" value="entreprise"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div style="margin-top:15px">
        <el-button type="primary" round @click="createuser()">Créer</el-button>
      </div>
    </el-dialog>

    <el-dialog width="70%" title="Validation du pré contrat Assur'Hospi" :visible.sync="validateContratModalMicroVie"  v-if="validateContratModalMicroVie">
      <pre-contrat-vie  :usersearch="usersearch" :detailsDevis="detailsDevis" ></pre-contrat-vie>
    </el-dialog>
  </div>
</template>
  
<script>


import moment from "moment-timezone"


//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import MarqueVehicule from '../../../common/marqueauto'
import customPassword from "../../../services/password_generator"

import _ from 'underscore'
import {
  mask
} from 'vue-the-mask'
//fin importation assuraf

export default {
  name: "contrat",
  directives: {
        mask,
    },
    components: {
      PreContratVie: () => import('../../../components/micro/pre_contrat_vie'),
    },
  data() {
    return {
      userRole: null,
      userInfo: {
        nom: "",
        prenom: "",
        sexe: "",
        telephone_port: "",
        email: "",
        password: "",
        confirmPass: "",
        role: "",
        plateforme: 'Dash',
        createdby: store.state.user._id,
        created: dayjs(new Date()).format('YYYY-MM-DD')
      },
      AddUser: false,
      contratToStore: null,
      userHabitations: [],
      listHabitations: [],
      myHabitations: "",
      naturelogement: '',
      nombrepiece: '',
      nombreetage: '',
      naturecouverture: '',
      optionstoiture: [{
        value: 'Béton',
        text: 'Béton '
      },
      {
        value: 'Tuile (ardoise)',
        text: 'Tuile (ardoise)'
      },
      {
        value: 'Tôle metal',
        text: 'Tôle metal'
      },
      {
        value: 'Paille',
        text: 'paille'
      },
      ],
      optionsenvi: [{
        value: 'Quartier résidentiel',
        text: 'Quartier résidentiel '
      },
      {
        value: 'Chantiers a proximité',
        text: 'Chantiers a proximité'
      },
      {
        value: 'Proche Usine/Marche/Station Essence',
        text: 'Proche Usine/Marche/Station Essence'
      },
      ],
      environnement_immediat: null,
      adresselogement: '',
      qualiteassure: '',
      montantLoyer: '',
      valeurcontenu: '',
      valeurbatiment: '',


      listMarque: [],
      listeModeles: null,
      ModalListeContrat: false,
      ModalListeHabitation: false,
      listCars: [],
      userCars: [],
      e1: 0,
      myCars: null,
      listContratTempo: [],
      PuissanceFinscaleItems: ['2', '3', '4', '5', '6', '7', '8', '9', '10', 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, '24+'],
      immatriculation: "",
      valeur_venale: null,
      showContrat: false,
      marque: "",
      model: "",
      force: "",
      nbplace: "",
      age: null,
      categorie: "",
      dateBeforeToday: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      dateAfterToday: {
        disabledDate(time) {
          return time.getTime() < (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
        }
      },
      carburant: "",
      usersearch: null,
      annee_mois: null,
      dateCircule: "",
      money: {
        thousands: ' ',
        precision: false,
        masked: false
      },
      mask: 'XX-XXXX-XX',
      listbranche: [],
      detailbranche: [],
      validateContratModalMicroAuto: null,
      validateContratModalMicroHabitation: null,
      validateContratModalMicroVie: false,
      showpack: false,
      nombranche: null,
      fields: [
        { key: 'branche', sortable: true, label: 'Nom branche' },
        { key: 'action', label: 'Action' },
      ],
      modalgenerationloading: false,
      optionsdate: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      ldevis: [],
      du: null,
      au: null,
      listdevis: [],
      modalgeneration: false,
      detailsDevis: [],
      showdevis: false,
      pageOptions: [5, 10, 15],
      modalInfo: {
        title: '',
        content: ''
      },
      userflette: [],
      fletteRows: null,
      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showUser: false,
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.listContratTempo,//users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
    }
  },
  computed: {
    listFiltered() {
      return this.listContratTempo.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
            obj[k] &&
            obj[k]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    /*listFiltered() {
      return this.list.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },*/
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
        this.listFiltered.sort((item1, item2) => {
          let val1 = ""
          let val2 = ""

          val1 = item1[prop]
          val2 = item2[prop]
          if (order === "descending") {
            return val2 < val1 ? -1 : 1
          }
          return val1 < val2 ? -1 : 1
        })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },
    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    isBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs["my-table"].$el;
        if (newVal === true) {
          tableScrollBody.classList.add("overflow-hidden");
        } else {
          tableScrollBody.classList.remove("overflow-hidden");
        }
      }
    },

    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    getUserRoles(){ 
            Api().get('/roles/all')
            .then(response => {
                for(let lr of response.data.AllRoles){
                    if(lr.name=="User"){
                        this.userRole = lr._id
                    }
                }
            }).catch(err => {console.log("failed getAllUserRoles");})
        },

    //Creation d'un user avant de faire son devis
    createuser: function () {
      this.generatePassword()
     
      if (this.userInfo.nom && this.userInfo.prenom && this.userInfo.email && this.userInfo.sexe && this.userInfo.telephone_port) {
        this.userInfo.role = this.userRole
        Api().post('user/register', this.userInfo)
          .then(resultat => {
            //this.HubspotSend(this.userInfo)
            //this.MailChimpInscriptionCompte(this.userInfo)
            if (resultat.status == 200) {
              if (resultat.data.numero) {
                this.$message.error(resultat.data.msg, this.AlertMessage)
              } else {
                this.usersearch = resultat.data.data
                this.AddUser = false
                this.dialogActive(this.detailsDevis)
                this.listInPagePassager = []
                this.successNotifCreateUser()
              }
            } else {
              this.$message.error('Ajout utilisateur échoué !!!', this.AlertMessage)
            }
          })
          .catch(err => {
            this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
          })

      } else {
        this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
      }

    },

    generatePassword: function () {
      this.userInfo.password = customPassword.customPassword(this.nom, this.prenom)
      this.userInfo.confirmPass = this.userInfo.password
      this.userInfo.role = this.userRole
    },

    RoundToMillion(num) {
      var parts = num.split(" ");
      var fac = Math.pow(1000, parts.length - 1)
      return (Math.ceil(parseInt(parts.join("")) / fac) * fac)
    },
    closeContratModal() {
      this.validateContratModalMicroAuto = false
      this.validateContratModalMicroHabitation = false
      this.validateContratModalMicroVie = false
        this.showContrat = false
      this.e1 = 0

    },

    getCarForContrat(item) {
      let detailCar = item

      let microAutoData = {
        contratTmp: this.detailsDevis._id,
        vehicules: [{
          categorie: "categorie 1",
          marque: detailCar.marque,
          model: detailCar.model,
          force: detailCar.force,
          dateCircule: dayjs(detailCar.dateCircule).format('YYYY-MM-DD'),
          nbplace: detailCar.nbplace,
          garanties: this.detailsDevis.garanties[0],
          flotte_mono: "mono",
          immatriculation: detailCar.immatriculation,
          carburation: detailCar.carburation,
          valeur_venale: detailCar.valeur_venale
        }],
        userid: this.usersearch._id,
        createur: this.$store.state.user._id,
        clientid: this.usersearch._id,
        proprietaire: {
          prenom: this.usersearch.prenom,
          nom: this.usersearch.nom
        },
      }

      if (this.$store.state.user.role == 'Agent') {
        microAutoData.courtierid = this.$store.state.user.societe
      }
      this.modalgenerationloading = true
      Api().post('micro/contrat/autofinal', microAutoData)
        .then(result => {
          if (result.data.message) {
            this.contratToStore = result.data.contrat
            this.contratToStore.assureur = result.data.assureur
            this.contratToStore.branche = result.data.Thebranche

            this.$store.dispatch('setNewcontrat', this.contratToStore)
            setTimeout(() => (
              this.modalgenerationloading = false,
              this.$router.go(this.$router.push('/contrat/ok'))
            ), 4000);
          }
        }).catch((error) => {
          this.modalgenerationloading = false
          console.log("error while => " + error);
          console.log(error);
        })
    },

    getLogementForContrat(item) {
      let detailLogement = item
      let habitationData = {
        contratTmp: this.detailsDevis._id,
        habitation: {
          typeBien: detailLogement.typeBien,
          nombrepiece: detailLogement.nombrepiece,
          nombreetage: detailLogement.nombreetage,
          superficie: detailLogement.nombreetage,
          naturecouverture: detailLogement.naturecouverture,
          adresselogement: detailLogement.adresselogement,
          environnement_immediat: detailLogement.environnement_immediat,
          valeurcontenu: detailLogement.valeurcontenu,
          valeurbatiment: detailLogement.valeurbatiment,
          qualiteassure: detailLogement.qualiteassure,
          montaLoyer: detailLogement.montaLoyer,
        },
        userid: this.usersearch._id,
        createur: this.$store.state.user._id,
        clientid: this.usersearch._id,
        proprietaire: {
          prenom: this.usersearch.prenom,
          nom: this.usersearch.nom
        },
      }

      if (this.$store.state.user.role == 'Agent') {
        habitationData.courtierid = this.$store.state.user.societe
      }
      this.modalgenerationloading = true
      Api().post('micro/contrat/habitationfinal', habitationData).then(result => {
        if (result.data.message) {
          this.contratToStore = result.data.contrat
          this.contratToStore.assureur = result.data.assureur
          this.contratToStore.branche = result.data.Thebranche

          this.$store.dispatch('setNewcontrat', this.contratToStore)
          setTimeout(() => (
            this.modalgenerationloading = false,
            this.$router.go(this.$router.push('/contrat/ok'))
          ), 4000);
        }
      })
    },

    deleteUserCar(item) {
      const UserCar = {
        id: item._id
      }

      Api().post("/flette/delete", UserCar)
        .then(response => {
          if (response.status == 200) {
            Api().post("flette/listing/", { userid: this.usersearch._id })
              .then(response => {
                this.userCars = response.data.cars
              })
            this.$message.success('Véhicule supprimé avec succès !', this.AlertMessage)
          }
        })
        .catch(err => { })

    },

    createMicroContratAuto() {
      if (this.marque && this.model && this.force && this.dateCircule && this.nbplace && this.immatriculation && this.carburant && this.valeur_venale) {

        this.modalgenerationloading = true
        let microAutoData = {
          contratTmp: this.detailsDevis._id,
          vehicules: [{
            categorie: "categorie 1",
            marque: this.marque,
            model: this.model,
            force: this.force,
            dateCircule: this.dateCircule,
            nbplace: this.nbplace,
            garanties: this.detailsDevis.garanties[0],
            flotte_mono: "mono",
            immatriculation: this.immatriculation,
            carburation: this.carburant,
            valeur_venale: this.valeur_venale,
          }],
          userid: this.usersearch._id,
          createur: this.$store.state.user._id,
          clientid: this.usersearch._id,
          proprietaire: {
            prenom: this.usersearch.prenom,
            nom: this.usersearch.nom
          },
        }

        if (this.$store.state.user.role == 'Agent') {
          microAutoData.courtierid = this.$store.state.user.societe
        }

        Api().post('micro/contrat/autofinal', microAutoData).then(result => {
          if (result.data.message) {
            this.contratToStore = result.data.contrat
            this.contratToStore.assureur = result.data.assureur
            this.contratToStore.branche = result.data.Thebranche
            this.$store.dispatch('setNewcontrat', this.contratToStore)

            setTimeout(() => (
              this.modalgenerationloading = false,
              this.$router.go(this.$router.push('/contrat/ok'))
            ), 4000);
          }else{
            this.modalgenerationloading = false
          }
        }).catch((error) => {
          this.modalgenerationloading = false
        })
      } else {
        this.$message.error("Tout les champs sont obligatoires !", this.AlertMessage)
      }
    },
    createMicroContratHabitation() {
      if (this.naturelogement && this.nombrepiece && this.nombreetage && this.naturecouverture && this.environnement_immediat && this.adresselogement && this.qualiteassure && this.valeurcontenu && this.valeurbatiment) {

        let habitationData = {
          contratTmp: this.detailsDevis._id,
          habitation: {
            typeBien: this.naturelogement,
            nombrepiece: this.nombrepiece,
            nombreetage: this.nombreetage,
            superficie: this.nombreetage,
            naturecouverture: this.naturecouverture,
            adresselogement: this.adresselogement,
            environnement_immediat: this.environnement_immediat,
            valeurcontenu: this.RoundToMillion(this.valeurcontenu),
            valeurbatiment: Number(this.valeurbatiment.replace(/[^0-9.-]+/g, "")),
            qualiteassure: this.qualiteassure,
            montaLoyer: Number(this.montantLoyer.replace(/[^0-9.-]+/g, "")),
          },
          userid: this.usersearch._id,
          createur: this.$store.state.user._id,
          clientid: this.usersearch._id,
          proprietaire: {
            prenom: this.usersearch.prenom,
            nom: this.usersearch.nom
          },
        }

        if (this.$store.state.user.role == 'Agent') {
          habitationData.courtierid = this.$store.state.user.societe
        }
        this.modalgenerationloading = true
        Api().post('micro/contrat/habitationfinal', habitationData).then(result => {
          if (result.data.message) {
            this.contratToStore = result.data.contrat
            this.contratToStore.assureur = result.data.assureur
            this.contratToStore.branche = result.data.Thebranche
            
            this.$store.dispatch('setNewcontrat', this.contratToStore)
            setTimeout(() => (
              this.modalgenerationloading = false,
              this.$router.go(this.$router.push('/contrat/ok'))
            ), 4000);
          }
        })
      } else {
        this.$message.error("Tout les champs sont obligatoires !", this.AlertMessage)
      }
    },
    createMicroContratVie() {
      // if (this.naturelogement && this.nombrepiece && this.nombreetage && this.naturecouverture && this.environnement_immediat && this.adresselogement && this.qualiteassure && this.valeurcontenu && this.valeurbatiment) {

      //   let habitationData = {
      //     contratTmp: this.detailsDevis._id,
      //     habitation: {
      //       typeBien: this.naturelogement,
      //       nombrepiece: this.nombrepiece,
      //       nombreetage: this.nombreetage,
      //       superficie: this.nombreetage,
      //       naturecouverture: this.naturecouverture,
      //       adresselogement: this.adresselogement,
      //       environnement_immediat: this.environnement_immediat,
      //       valeurcontenu: this.RoundToMillion(this.valeurcontenu),
      //       valeurbatiment: Number(this.valeurbatiment.replace(/[^0-9.-]+/g, "")),
      //       qualiteassure: this.qualiteassure,
      //       montaLoyer: Number(this.montantLoyer.replace(/[^0-9.-]+/g, "")),
      //     },
      //     userid: this.usersearch._id,
      //     createur: this.$store.state.user._id,
      //     clientid: this.usersearch._id,
      //     proprietaire: {
      //       prenom: this.usersearch.prenom,
      //       nom: this.usersearch.nom
      //     },
      //   }

      //   if (this.$store.state.user.role == 'Agent') {
      //     habitationData.courtierid = this.$store.state.user.societe
      //   }

      //   Api().post('micro/contrat/habitationfinal', habitationData).then(result => {
      //     if (result.data.message) {
      //       this.contratToStore = result.data.contrat
      //       this.contratToStore.assureur = result.data.assureur
      //       this.contratToStore.branche = result.data.Thebranche

      //       this.modalgenerationloading = true
      //       this.$store.dispatch('setNewcontrat', this.contratToStore)
      //       setTimeout(() => (
      //         this.modalgenerationloading = false,
      //         this.$router.go(this.$router.push('/contrat/ok'))
      //       ), 4000);
      //     }
      //   })
      // } else {
      //   this.$message.error("Tout les champs sont obligatoires !", this.AlertMessage)
      // }
    },
    getContratTempo() {
      Api().get('/micro/contrat/temporaires')
        .then(resultat => {
          this.listContratTempo = resultat.data.contratTmp
          this.totalRows = this.listContratTempo.length
        });
    },
    detailContrat(item) {
      this.detailsDevis = item
      this.showContrat = true
    },

    SetDetailUser(details) {
      this.detailsUser = details
      this.showUser = true
    },

    transformToContrat(details) {
      this.detailsDevis = details
      if (details.brancheid.branche == "Voyage") {
        localStorage.setItem('preTocontrat', JSON.stringify(details))
        this.$router.go(this.$router.push('/precontrat/validation'))
      } else {
        this.rechercherClient(details)
      }
    },

    dialogActive(element) {
      if (element.brancheid.branche == "Micro-auto") {
        this.validateContratModalMicroAuto = true
      } else if (element.brancheid.branche == "Micro-habitation") {
        this.validateContratModalMicroHabitation = true
      } else if (element.brancheid.branche == "Assur-Hospi") {
        //localStorage.setItem('preTocontrat', JSON.stringify(element))
        // this.$router.go(this.$router.push('/precontrat/validation'))
        this.validateContratModalMicroVie = true
      } else {
        alert("Branche " + element.brancheid + " Non prise en charge")
      }

    },
    rechercherClient(item) {
      Api().get('/user/phone/' + item.customer_phone)
        .then(resultat => {
          if (resultat.data.telephone_port === item.customer_phone) {
            this.usersearch = resultat.data;
            this.dialogActive(item)
          } else {
            if (resultat.data.msg == "User not found") {
              this.getUserRoles()
              this.userInfo.telephone_port = item.customer_phone
              this.AddUser = true
            }
          }
        })
        .catch(e => {
          console.log(e)
            this.getUserRoles()
            this.userInfo.telephone_port = item.customer_phone
            this.AddUser = true
      
        })

    },

    getListVehicule() {
      //this.getUserCars()
      if (this.myCars == 'mes_vehicule') {
        if (this.listCars.length == 0) {
          this.$message.error("Vous n'avez pas de véhicule !", this.AlertMessage)
        } else if (this.listCars.length != 0) {
          this.ModalListeContrat = true
          this.userCars = this.listCars
          this.myCars = 'mes_vehicule'
        }
      } else {
        this.e1 = 1
      }
    },
    getUserHabitation() {
      //this.getUserHabitation()
      Api().post("habitation/myhouse", { userid: this.usersearch._id })
        .then(response => {
          this.userHabitations = response.data.logements

          if (this.myHabitations == 'mes_habitations') {
            if (this.userHabitations.length == 0) {
              this.$message.error("Vous n'avez pas de logement !", this.AlertMessage)
            } else if (this.userHabitations.length != 0) {
              this.ModalListeHabitation = true
              this.userHabitations = this.userHabitations
              this.myHabitations = 'mes_habitations'
            }
          } else if (this.myHabitations == 'nouveau') {
            this.e1 = 1
          }
        });
    },

    deleteHabitation(item) {
      const UserHabitation = {
        habitationid: item._id
      }

      Api().post("/habitation/delete", UserHabitation)
        .then(response => {
          if (response.status == 200) {
            Api().post("habitation/myhouse", { userid: this.usersearch._id })
              .then(response => {
                this.userHabitations = response.data.logements
              });
            this.$message.success("Logement supprimé avec succés !", this.AlertMessage)

          }
        })
        .catch(err => { })

    },

    deleteHabitation(item) {
      const UserHabitation = {
        habitationid: item._id
      }

      Api().post("/habitation/delete", UserHabitation)
        .then(response => {
          if (response.status == 200) {
            Api().post("habitation/myhouse", { userid: this.usersearch._id })
              .then(response => {
                this.userHabitations = response.data.logements
              });
            this.$message.success("Logement supprimé avec succés !", this.AlertMessage)

          }
        })
        .catch(err => { })

    },

    getUserCars() {
      console.log(this.detailsDevis)
      if (this.myCars == 'mes_vehicule') {
        Api().post("flette/listing/", { userid: this.usersearch._id })
          .then(response => {
            this.listCars = response.data.cars

            if (this.listCars.length == 0) {
              this.$message.error("Vous n'avez pas de véhicule !", this.AlertMessage)
            } else if (this.listCars.length != 0) {
              this.ModalListeContrat = true
              this.userCars = this.listCars
              this.myCars = 'mes_vehicule'
            }

          });
      } else if (this.myCars == 'nouveau') {
        this.e1 = 1
      }


    },

    yearsDiff() {
      const date1 = dayjs(this.dateCircule);
      const date2 = dayjs(new Date().toISOString().substr(0, 10));
      let daydiff = date2.diff(date1, 'days');
      this.age = daydiff

      if (this.age <= 29) {
        this.an = this.annee_mois = 'jour(s)'
      } else if (this.age >= 30, this.age <= 360) {
        let year = Math.floor(this.age / 30)
        this.age = year;
        this.an = this.annee_mois = 'mois'
      } else if (this.age >= 360) {
        let year = Math.floor(this.age / 360)
        this.age = year;
        this.an = this.annee_mois = 'an(s)'
      }

    },


    exportToExcel() {
      //API de genereation donne en excel
    },
    toCreateBranche() {
      this.$router.go(this.$router.push('/branche/create'))
    },
    detailbrancheVue(item) {

      this.detailbranche = item
      this.showpack = true
    },
    getBranche() {

      Api().get('/branche')
        .then(resultat => {
          this.listbranche = resultat.data
          this.totalRows = this.listbranche.length
        });
    },

    // Creation de branche
    CreateBranche() {
      if (this.nombranche) {
        let br = {
          branche: this.nombranche,
          user: store.state.user._id
        }

        Api().post('/branche/add', br)
          .then(rep => {
            if (rep.data.message) {
              this.$message.error(rep.data.message, this.AlertMessage)
              this.nombranche = ""
              this.getBranche()

            }
          })
      } else {
        this.$message.error('Le nom de la branche obligatoire !', this.AlertMessage)
      }
    },


    BrancheDeleteConfirmation(item) {
      this.modalDelete = true
      this.detailbranche = item
    },

    Deletingbranche(id) {
      Api().delete('branche/' + id)
        .then(resultat => {
          if (resultat.data.rep == 1) {
            this.modalDelete = false
            this.$message.success('Branche supprimée avec succés !', this.AlertMessage)
            this.getBranche()
          }
        })
    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000
      });
    },

    toggleDetails(item) {
      this.detailsUser = item
      this.editUser = true
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    getUserList: function () {
      switch (this.$store.state.user.role) {
        case 'Admin':
          Api().get('/user/frontoffice/')
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;


        case 'assureur':
          Api().get('/user/myuser/assureur/' + this.$store.state.user.societe)
            .then(resultat => {
              this.listuser = resultat.data
              this.totalRows = resultat.data.length
            });
          break;

        default:
          break;
      }
    },

    UpdateUser(item) {

      let idU = item.id
      Api().post('user/' + idU, item)
        .then(response => {
          if (response.data) {
            this.showAlert()
          }
          this.showAlert()
        })
        .catch(err => {

        })

    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

    userDeleteConfirmation(item) {

      this.modalDelete = true
      this.detailsUser = item

    },

    DeletingUser(id) {
      Api().delete('user/delete/' + id)
        .then(resultat => {
          if (resultat.status == 200) {
            this.modalDelete = false
            this.getUserList()
          }
        })
        .catch(err => { })
    },

    getUserMenu(id) {
      Api().post('/menu/user/menu', { userid: id })
        .then((result) => {
          this.usermenu = result.data.menus
        }).catch((err) => {
          console.log(err)
        });
    },

    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },
    handleResize: _.throttle(function (e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },

  filters: {
    selected: function (value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    //this.init()
    //this.getBranche()
  },
  mounted() {

    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      //this.getBranche()
      this.getContratTempo()
      this.listMarque = _.uniq(MarqueVehicule, _.property('Marque'))
      this.listeModeles = _.uniq(MarqueVehicule, _.property('Type'))
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }

}
</script>
  
<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>
  
<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {

      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }

          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      &>* {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
  