<template>
    <vue-scroll class="page-devis-voyage" >
        <div class="page-header">
            <h1 v-if="this.msgMicroMessage = true">La validation du pre contrat s'est <span style="color:#ffa409">bien passé</span>
				<theme-picker style="float:right"></theme-picker>
			</h1>
			<h1 v-else >Le paiement s'est <span style="color:#ffa409">bien passé</span>
				<theme-picker style="float:right"></theme-picker>
			</h1>
		</div>

        <resize-observer @notify="__resizeHanlder"/>
        
        <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-if="Contrat_provisoire">
            <div style="margin-top:15px">
                <el-row :gutter="15" type="flex" class="row-bg">
                      <el-col :span="12">
                        <div>
                            <h3>Numéro de devis : <span style="color:#ffa409">{{Contrat_provisoire.numero_police}}</span></h3>
                            <h3>Numéro de police : <span style="color:#ffa409">{{Contrat_provisoire.numero_police}}</span></h3>
                        </div>
                    </el-col>
                </el-row>
                 <el-row :gutter="15" type="flex" class="row-bg" justify="center">
                      <el-col :span="24">
                        <div>
                            <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="telecharger_attestation(Contrat_provisoire)" type="primary" round>Mon attestation provisoire</el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
import * as echarts from "echarts"
import Chance from "chance"
const chance = new Chance()

//debut importation assuraf
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
    name: "devis_voyage",
    
    data() {
        return {
            modalgenerationloading:false,
            sonam:"SONAM GENERALE ASSURANCES",
            notif: null,
            Contrat_provisoire: null,
            modalDownload: false,
            user_info: null,
            voidField: false,
            Deb: null,
            selectedcarbu: '',
            msgMicroMessage : false,
            types: [
                'text',
                'text',
                'number',
            ],
            show: true,
            //fin initialisation des models d'assuraf
            chart: null,
            pie: null,
            gridData: []
        }
    },
    mounted() {
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
            this.getContratByClientId()
            this.user_info = store.state.user
        } else {
            this.$router.go(this.$router.push('/login'))
        }
        window.addEventListener("resize", this.__resizeHanlder)
    },
   
    methods: {
          getContratByClientId() {
            Api().get('contrat/' + this.$store.state.Newcontrat._id)
                .then(response => {
                    this.Contrat_provisoire = response.data.contrats
                })
        },

        DateFormater: function (TheDate) {
            if (TheDate) {

                let laDate = new Date(TheDate);
                let TheDay = laDate.getDate();
                let TheMonth = laDate.getMonth() + 1;
                let TheYear = laDate.getFullYear();

                if (TheMonth < 10 && TheDay < 10) {
                    return '0' + TheDay + "-0" + TheMonth + "-" + TheYear;
                } else if (TheMonth < 10 && TheDay >= 10) {
                    return TheDay + "-0" + TheMonth + "-" + TheYear;
                } else if (TheMonth > 10 && TheDay < 10) {
                    return '0' + TheDay + "-" + TheMonth + "-" + TheYear;
                }

            }

        },

        telecharger_attestation: function (contrat) {
            if (contrat.brancheid.branche == 'Voyage' && contrat.partenaireid.societe==this.sonam) {
                let url = contrat.lien_contrat
                let WinOpener = null
                setTimeout(() => (
                    WinOpener = window.open(),
                    WinOpener.location = url
                ), 7000);
            } else {
                this.modalgenerationloading = true
                Api().get('contrat/attestation/download/' + contrat._id)
                    .then(response => {
                        let url = response.data.lien
                        let WinOpener = null
                        setTimeout(() => (
                            this.modalgenerationloading = false,
                            WinOpener = window.open(),
                            WinOpener.location = url
                        ), 7000);
                    })
            }

        },

        /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
        onlyNumber($event) {
            let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
            if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
                $event.preventDefault();
            }
        },
        AlertMessage() {
        this.$message({
          showClose: false,
          duration : 5000
        });
      },
       
        onReset(evt) {
            evt.preventDefault();
            this.user.email = '';
            this.user.name = '';
            this.user.food = null;
            this.user.checked = [];
            this.show = false;
            this.$nextTick(() => {
                this.show = true
            });
        },
        __resizeHanlder: _.throttle(function(e) {
            if (this.chart) {
                this.chart.resize()
            }
            if (this.pie) {
                this.pie.resize()
            }
        }, 700),
       
    }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }
                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }
                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }
                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
</style>
