
export default function CarTypeBuilder(car) 
{
    if(car.categorie=='categorie 1' && car.carosserie==null && car.tonnage==null){
        return {
            categorie: 'categorie 1',
            genre : 'VP'
        }
    }

    if(car.carosserie=='carosserie tourisme' && car.tonnage=='3,5 Tonnes' && car.optionUtilitaire=='materiel_perso'){
        return {
            categorie: 'categorie 2',
             genre : 'TPC'
        }
    }

    if(car.carosserie=='autres carosseries' && car.tonnage=='3,5 Tonnes' && car.optionUtilitaire=='materiel_perso'){
        return {
            categorie: 'categorie 2',
            genre : 'TPC3T500'
        }
    }

    if(car.carosserie=='autres carosseries' && car.tonnage=='Plus de 3,5 Tonnes' && car.optionUtilitaire=='materiel_perso'){
        return  {
            categorie: 'categorie 2',
            genre : 'TPC3T500P'
        }
    }

    if(car.carosserie=='autres carosseries' && car.tonnage=='3,5 Tonnes' && car.optionUtilitaire=='materiel_tiers'){
        return  {
            categorie: 'categorie 3',
            genre : 'TPM3T500'
        }
    }

    if(car.carosserie=='autres carosseries' && car.tonnage=='Plus de 3,5 Tonnes' && car.optionUtilitaire=='materiel_tiers'){
        return  {
            categorie: 'categorie 3',
            genre : 'TPM3T500P'
        }
    }

    if(car.volumetrie2_roues==50){
        return  {
            categorie: 'categorie 5',
            genre : '2RCYC'
        }
    }

    if(car.volumetrie2_roues==125){
        return  {
            categorie: 'categorie 5',
            genre : '2RSCO'
        }
    }

    if(car.volumetrie2_roues>125){
        return  {
            categorie: 'categorie 5',
            genre : '2RMOT'
        }
    }

    if(car.volumetrie2_roues==401){
        return  {
            categorie: 'CATEGORIE 5',
            genre : '2RSID'
        }
    }
}