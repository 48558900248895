<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
      <div class="page-header">
        <h1>Liste des contrats<span style="color:#ffa409"> express</span>
          <theme-picker style="float:right"></theme-picker>
        </h1>
      </div>
  
       <div class="toolbar-box flex align-center" v-if="!isMobile">
        <div class="box grow">
          <el-input
              placeholder="Rechercher contrat express..."
              prefix-icon="el-icon-search"
              v-model="search"
              clearable
          >
          </el-input>
        </div>
        <div class="box grow">
          <!--        A ne pas supprimer-->
        </div>
      </div>
  
      <div v-if="isMobile" style="margin-bottom:15px">
        <el-row :gutter="15">
          <el-col :span="24" style="margin-top:15px">
            <el-input
              placeholder="Rechercher contrat express..."
              prefix-icon="el-icon-search"
              v-model="search"
              clearable>
          </el-input>
          </el-col>
        </el-row>
      </div>
  
      <div class="toolbar-box flex align-center" style="margin-top: -15px" v-if="!isMobile">
        <div class="box grow">
          <h3>Recherche par période de création</h3>
          <el-date-picker
              v-model="du"
              type="date"
              format="yyyy/MM/dd"
              placeholder="Choississez une date">
          </el-date-picker>
          <el-date-picker
              style="margin-left:15px"
              v-model="au"
              type="date"
              format="yyyy/MM/dd"
              placeholder="Choississez une date">
          </el-date-picker>
          <el-button class="button-filtre" type="primary" @click="FiltreDate" icon="el-icon-search" circle></el-button>
        </div>
      </div>
      <div class="toolbar-box flex align-center" style="margin-top: -15px" v-if="isMobile">
        <div class="box grow">
          <h3>Recherche par période de création</h3>
          <el-date-picker
              v-model="du"
              type="date"
              format="yyyy/MM/dd"
              placeholder="Choississez une date">
          </el-date-picker>
          <el-date-picker
              style="margin-top:15px"
              v-model="au"
              type="date"
              format="yyyy/MM/dd"
              placeholder="Choississez une date">
          </el-date-picker>
          <el-button class="button-filtre" type="primary" @click="FiltreDate" icon="el-icon-search" circle></el-button>
        </div>
      </div>
  
      <resize-observer @notify="handleResize" />

      <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>
  
      <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
        <el-table
            :data="listInPage"
            style="width: 100%"
            :height="height"
            v-if="ready"
            @selection-change="handleSelectionChange"
        >
        <el-table-column label="Date Souscription" fixed min-width="150" prop="dateSouscription">
            <template slot-scope ="scope">
              <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
            </template>
          </el-table-column>
        <el-table-column label="Client" min-width="200" prop="client">
            <template slot-scope ="scope">
              <span class="date"> <a href="#" @click="SetDetailUser(scope.row.user)">{{scope.row.user.prenom}} - {{scope.row.user.nom}}</a></span>
            </template>
          </el-table-column>
          <el-table-column label="Numéro police" min-width="180" prop="numero_police"></el-table-column>
          <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
          <el-table-column label="Assureur" min-width="150" prop="partenaireid">
            <template slot-scope ="scope" v-if="scope.row.partenaireid.societe.length <=10">
              <span class="date">{{scope.row.partenaireid.societe}}</span>
            </template>
            <template slot-scope ="scope" v-else>
              <span class="date">{{scope.row.partenaireid.societe.substring(0,10) + '...'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
            <template slot-scope ="scope">
              <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
            <template slot-scope ="scope">
              <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
            <template slot-scope ="scope">
              <span class="date">{{ scope.row.prime_ttc.toLocaleString() }} CFA</span>
            </template>
          </el-table-column>
          <el-table-column label="Partenaire" min-width="150" prop="courtierid.societe"></el-table-column>
          <el-table-column label="Créateur" min-width="150" prop="creator">
            <template slot-scope ="scope" v-if="$store.state.user.role=='Admin' ||  $store.state.user.role=='Agent'">
              <span v-if="scope.row.createur" class="date">{{scope.row.createur.prenom}} - {{scope.row.createur.nom}}</span>
              <span v-else>
                Admin
              </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Actions" prop="action" min-width="80">
            <template slot-scope="scope">
              <el-button size="mini" @click="detailcontrat(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            </template>
          </el-table-column>
        </el-table>
  
        <el-pagination
            v-if="ready"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="pagination.layout"
            :total="total"
        ></el-pagination>
      </div>
  
      <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
        <el-table
            :data="listInPage"
            style="width: 100%"
            v-if="ready"
            @selection-change="handleSelectionChange"
        >
        <el-table-column label="Date Souscription" min-width="150" prop="dateSouscription">
            <template slot-scope ="scope">
              <span class="date">{{ DateFormater(scope.row.dateSouscription) }}</span>
            </template>
          </el-table-column>
        <el-table-column label="Client" min-width="200" prop="client">
            <template slot-scope ="scope">
              <span class="date"> <a href="#" @click="SetDetailUser(scope.row.user)">{{scope.row.user.prenom}} - {{scope.row.user.nom}}</a></span>
            </template>
          </el-table-column>
          <el-table-column label="Numéro police" min-width="180" prop="numero_police"></el-table-column>
          <el-table-column label="Branche" min-width="120" prop="brancheid.branche"></el-table-column>
          <el-table-column label="Assureur" min-width="150" prop="partenaireid">
            <template slot-scope ="scope" v-if="scope.row.partenaireid.societe.length <=10">
              <span class="date">{{scope.row.partenaireid.societe}}</span>
            </template>
            <template slot-scope ="scope" v-else>
              <span class="date">{{scope.row.partenaireid.societe.substring(0,10) + '...'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="Date d'effet" min-width="150" prop="dateEffet">
            <template slot-scope ="scope">
              <span class="date">{{ DateFormater(scope.row.dateEffet) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Date écheance" min-width="150" prop="dateEcheance">
            <template slot-scope ="scope">
              <span class="date">{{ DateFormater(scope.row.dateEcheance) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Prime TTC" min-width="150" prop="prime_ttc">
            <template slot-scope ="scope">
              <span class="date">{{ scope.row.prime_ttc.toLocaleString() }} CFA</span>
            </template>
          </el-table-column>
          <el-table-column label="Courtier" min-width="150" prop="courtierid.societe"></el-table-column>
          <el-table-column label="Créateur" min-width="150" prop="creator">
            <template slot-scope ="scope" v-if="$store.state.user.role=='Admin' ||  $store.state.user.role=='Agent'">
              <span v-if="scope.row.createur" class="date">{{scope.row.createur.prenom}} - {{scope.row.createur.nom}}</span>
              <span v-else>
                Admin
              </span>
            </template>
          </el-table-column>
           <el-table-column label="Actions" prop="action" min-width="80">
            <template slot-scope="scope">
              <el-button size="mini" @click="detailcontrat(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            </template>
          </el-table-column>
        </el-table>
  
        <el-pagination
            v-if="ready"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="pagination.layout"
            :total="total"
        ></el-pagination>
      </div>
  
      <el-dialog width="70%!important" title="Détails contrat" :visible.sync="showcontrat" v-if="showcontrat==true && !isMobile">
        <el-row :gutter="15">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Date de Souscription : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateSouscription)}}</span></li>
                    <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{detailsContrat.numero_police}}</span></li>
                    <li v-if="detailsContrat.brancheid.branche=='Voyage' && detailsContrat.increment_voyage">
                      Numéro Increment Voyage : <span class="to-right assuraf-orange">{{detailsContrat.increment_voyage}}</span></li>
                    <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEffet)}}</span></li>
                    <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEcheance)}}</span></li>
                    <li>Prime Nette : <span class="to-right assuraf-orange">{{detailsContrat.prime_nette.toLocaleString()}}</span></li>
                    <li>Prime TTC : <span class="to-right assuraf-orange">{{detailsContrat.prime_ttc.toLocaleString()}}</span></li>
                    <li>Taxe : <span class="to-right assuraf-orange">{{detailsContrat.taxe.toLocaleString()}}</span></li>
                    <li>Accessoires : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                    <li v-if="detailsContrat.partner_creator!=null">Partenaires : <span class="to-right assuraf-orange">{{detailsContrat.partner_creator}}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsContrat.user.prenom.toUpperCase()}}</span></li>
                    <li>Nom : <span class="to-right assuraf-orange">{{detailsContrat.user.nom.toUpperCase()}}</span></li>
                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsContrat.user.telephone_port}}</span></li>
                    <li>Email : <span class="to-right assuraf-orange">{{detailsContrat.user.email}}</span></li>
                    <li>Date inscription : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                    <li>
                      <img :src="detailsContrat.user.photo" alt="" width="200">
                    </li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
  
       <el-dialog width="90%!important" title="Détails contrat" :visible.sync="showcontrat" v-if="showcontrat==true && isMobile">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Date de Souscription : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateSouscription)}}</span></li>
                    <li>Numéro Proposition Assuraf : <span class="to-right assuraf-orange">{{detailsContrat.numero_police}}</span></li>
                    <li v-if="detailsContrat.brancheid.branche=='Voyage' && detailsContrat.increment_voyage">
                      Numéro Increment Voyage : <span class="to-right assuraf-orange">{{detailsContrat.increment_voyage}}</span></li>
                    <li>Date Effet : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEffet)}}</span></li>
                    <li>Date Echéance : <span class="to-right assuraf-orange">{{DateFormater(detailsContrat.dateEcheance)}}</span></li>
                    <li>Prime Nette : <span class="to-right assuraf-orange">{{detailsContrat.prime_nette.toLocaleString()}}</span></li>
                    <li>Prime TTC : <span class="to-right assuraf-orange">{{detailsContrat.prime_ttc.toLocaleString()}}</span></li>
                    <li>Taxe : <span class="to-right assuraf-orange">{{detailsContrat.taxe.toLocaleString()}}</span></li>
                    <li>Accessoires : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                    <li v-if="detailsContrat.partner_creator!=null">Partenaires : <span class="to-right assuraf-orange">{{detailsContrat.partner_creator}}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsContrat.user.prenom.toUpperCase()}}</span></li>
                    <li>Nom : <span class="to-right assuraf-orange">{{detailsContrat.user.nom.toUpperCase()}}</span></li>
                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsContrat.user.telephone_port}}</span></li>
                    <li>Email : <span class="to-right assuraf-orange">{{detailsContrat.user.email}}</span></li>
                    <li>Date inscription : <span class="to-right assuraf-orange">{{detailsContrat.accessoires.toLocaleString()}}</span></li>
                    <li>
                      <img :src="detailsContrat.user.photo" alt="" width="200">
                    </li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
  
      <el-dialog width="70%!important" title="Détails client" :visible.sync="showUser" v-if="showUser==true && !isMobile">
        <el-row :gutter="15">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                    <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                    <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>
                      <img :src="detailsUser.photo" alt="" width="200">
                    </li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
  
      <el-dialog width="90%!important" title="Détails client" :visible.sync="showUser" v-if="showUser==true && isMobile">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Nom : <span class="to-right assuraf-orange">{{detailsUser.nom}}</span></li>
                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsUser.prenom}}</span></li>
                    <li>Téléphone : <span class="to-right assuraf-orange">{{detailsUser.telephone_port}}</span></li>
                    <li>Email : <span class="to-right assuraf-orange">{{detailsUser.email}}</span></li>
                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsUser.adresse}}</span></li>
                    <li>Profession : <span class="to-right assuraf-orange">{{detailsUser.profession}}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>
                      <img :src="detailsUser.photo" alt="" width="200">
                    </li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
  </template>
  
  <script>
  
  
  import _ from "lodash"
  import moment from "moment-timezone"
  
  //debut importation assuraf
  import dayjs from 'dayjs'
  import Api from '../../../services/Api'
  import store from '../../../store'
  //fin importation assuraf
  
  export default {
    name: "StyledTablePage",
    data() {
        return {
            optionsdate: {
                format: 'YYYY/MM/DD',
                useCurrent: false,
            },
            devisclient: false,
            username: null,
            paramclient: null,
            data: {},
            html: '',
            du: null,
            au: null,
            showUser: false,
            detailsContrat: [],
            totalRows: null,
            perPage: 8,


            modalgenerationloading : false,
            show_comment_contrat:false,
            set_comment_contrat:false,
            commentcount:null,
            commentlist:null,
            comment:{userid:null, comment_text:null, contratid:null, createdat:null},
            JsonView:false,
            Delete_reason:null,
            checked: null,
            user_name: true,
            client_name: false,
            isToday: false,
            isNotToday: false,
            Sunu: false,
            Salama : false,
            Allianz : false,
            Askia: false,
            Axa: false,
            Amsa : false,
            modalgeneration: false,
            modalNumPolice: false,
            modal_Delete_ok: false,
            modalNumPoliceAttest: false,
            NumPolice: null,
            NomPrenom: null,
            PrenomSurCarte: null,
            NomSurCarte: null,
            categorie: null,
            NumPoliceAttest: null,
            NumTalon: null,
            
            listcontrat: [],
            userid: null,
            userRole: null,
            showcontrat: false,
            showAttestation: false,
            notification_text: null,
            sonam:"SONAM GENERALE ASSURANCES",
    
            HeaderTableauVehicules: [
            {
                key: 'marque',
                label: 'Marque'
            },
            {
                key: 'model',
                label: 'Model'
            },
            {
                key: 'force',
                label: 'Puissance Fiscale'
            },
            {
                key: 'nbplace',
                label: 'Nombre de place'
            },
            {
                key: 'valeur',
                label: 'Valeur neuf'
            },
            {
                key: 'valeur_venale',
                label: 'Valeur venale'
            },
            {
                key: 'immatriculation',
                label: 'Immatriculation'
            },
            {
                key: 'carburation',
                label: 'Carburation'
            },
            ],
            fields: [
            {
                key: 'dateSouscription',
                label: 'Date Souscription'
            },
            {
                key: 'brancheid.branche',
                label: 'Branche'
            },
            {
                key: 'numero_police',
                label: 'Numero'
            },
            {
                key: 'partenaireid',
                label: 'Assureur'
            },
            {
                key: 'courtierid.societe',
                label: 'Partenaire'
            },
            {
                key: 'dateEffet',
                label: 'Effet'
            },
            {
                key: 'dateEcheance',
                label: 'Echéance'
            },
            {
                key: 'prime_ttc',
                label: 'Prime TTC'
            },
            {
                key: 'client',
                label: 'Client'
            },
            {
                key: 'creator',
                label: 'Createur'
            },
            {
                key: 'action',
                label: 'Action'
            },
            ],
            modalInfo: {
            title: '',
            content: ''
            },
            MenuHeader:[
            {key: "theme",label: "Libéllé"},
            {key: "collapse",label: "Collapse"},
            {key: "sousmenu",label: "Sous menu"},
            {key: "action", label:"action"}
        ],
        userflette:[],
        fletteRows:null,
        carFields:[
            {key:'marque',label:'Marque', sortable: true, sortDirection: 'desc'},
            {key:'model',label:'Model', sortable: true, sortDirection: 'desc'},
            {key:'immatriculation',label:'Immatriculation', sortable: true, sortDirection: 'desc'},
            {key:'carburation',label:'Carburation', sortable: true, sortDirection: 'desc'},
            {key:'added_date',label:"Date d'ajout"},
            {key:'action',label:'Action'},
        ],
        
        
        
        
            editUser : false,
            Selectedbranche:null,
            selectedAssureur:[],
            configUserDevisAssureur:null,
            assureurs:[],
            branchelist:null,
            modalSociete: false,
            configHead:[
            { key:"branche", label:"branche"},
            { key:"assureurs", label:"assureur"},
            { key:"action", label:"Action"}
            ],
            selectedUser:null,
            listeAssureurs: null,
            usermenu:[],
            collapse:null,
            menu_name:null,
            showConfigMenu:false,
            submenu:null,
            userSubmenu:[],
            UserFinalMenu:[],
    
            Mycounter: 0,
            dismissSecs: 3,
           
            UpdateOk: false,
            deleteConfirmation: false,
            modalDelete: false,
            dismissCountDown: 0,
            detailsUser: [],
            listuser: null,
            filter: null,
            showuser: false,
            voidField: false,
            isMobile: false,
            ready: false,
            width: 0,
            height: "auto",
            loading: false,
            search: "",
            pagination: {
            page: 1,
            size: 100,
            sizes: [10, 15, 20, 30, 50, 100],
            layout: "total, ->, prev, pager, next, jumper, sizes",
            small: false
            },
            list: this.listcontrat, //users,
            editMode: false,
            itemsChecked: [],
            dialogUserVisible: false,
            currentId: 0,
            moment
        }
    },
    computed: {
      listFiltered() 
      {
        if(this.search.length >= 3){
            return this.listcontrat.filter(obj => {
              let ctrl = false
              for (let k in obj) 
              {
                /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                    ctrl = true
                    /*on le push dans le tableau */
                    //this.listcontrat.push(obj) 
                }else{
                   /* Si la valeur courante est un tableau, on le refiltre atrribut par attribut */
                  if(Array.isArray(obj[k])){
                    obj[k].filter(nobj=>{
                      for(let l in nobj){
                          /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                          if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                            ctrl = true
                            /* on le push dans le tableau */
                            //this.listcontrat.push(obj)
                          }
                      }
                    })
                     /* Si la valeur courante n'est ni String, ni Tableau mais Object */
                  }else if(!Array.isArray(obj[k]) && typeof obj[k]=="object" && obj[k]!=null){
                    /*== On crée un nouveau tablequ vide ==*/
                    let my_new_array = []
  
                    /*== On push la valeur courante dans le nouveau tableau ==*/ 
                    my_new_array.push(obj[k])
                    
                    /*On refiltre le nouveau tableau atrribut par attribut*/
                    my_new_array.filter(nobj=>{
                      for(let l in nobj){
                          /* Si la valeur courante est un String, et correspond à la valeur recherchée */
                          if (nobj[l] && nobj[l].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1){
                            ctrl = true
                            
                            /* on le push dans le tableau */
                            //this.listcontrat.push(obj)
                          }
                      }
                    })
                  }
                }
              }
              return ctrl
            })
        }else{
           return this.listcontrat.filter(obj => {
            let ctrl = false
            for (let k in obj) 
            {
              if (obj[k] && obj[k].toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
              ctrl = true
            }
            return ctrl
          })
        }
  
      },
      /*listFiltered() {
        return this.list.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            if (
                obj[k] &&
                obj[k]
                    .toString()
                    .toLowerCase()
                    .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true
          }
          return ctrl
        })
      },*/
      listSortered() {
        let prop = this.sortingProp
        let order = this.sortingOrder
        return [].concat(
            this.listFiltered.sort((item1, item2) => {
              let val1 = ""
              let val2 = ""
  
              val1 = item1[prop]
              val2 = item2[prop]
              if (order === "descending") {
                return val2 < val1 ? -1 : 1
              }
              return val1 < val2 ? -1 : 1
            })
        )
      },
      listInPage() {
        let from = (this.currentPage - 1) * this.itemPerPage
        let to = from + this.itemPerPage * 1
        //return this.listSortered.slice(from, to)
        return this.listFiltered.slice(from, to)
      },
      total() {
        return this.listcontrat.length
      },
  
      listFilteredAuto() {
        return this.detailsContrat.details[0].vehicules.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            if (
                obj[k] &&
                obj[k]
                    .toString()
                    .toLowerCase()
                    .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true
          }
          return ctrl
        })
      },
      /*listFilteredAuto() {
        return this.list.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            if (
                obj[k] &&
                obj[k]
                    .toString()
                    .toLowerCase()
                    .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true
          }
          return ctrl
        })
      },*/
      listSorteredAuto() {
        let prop = this.sortingProp
        let order = this.sortingOrder
        return [].concat(
            this.listFilteredAuto.sort((item1, item2) => {
              let val1 = ""
              let val2 = ""
  
              val1 = item1[prop]
              val2 = item2[prop]
              if (order === "descending") {
                return val2 < val1 ? -1 : 1
              }
              return val1 < val2 ? -1 : 1
            })
        )
      },
      listInPageAuto() {
        let from = (this.currentPage - 1) * this.itemPerPage
        let to = from + this.itemPerPage * 1
        //return this.listSortered.slice(from, to)
        return this.listFilteredAuto.slice(from, to)
      },
      totalAuto() {
        return this.listFilteredAuto.length
      },
  
  
      currentPage: {
        get() {
          return this.pagination.page
        },
        set(val) {
          this.pagination.page = val
        }
      },
      itemPerPage() {
        return this.pagination.size
      },
      selectedItems() {
        return this.itemsChecked.length || 0
      }
    },
    watch: {
      itemPerPage(val) {
        this.ready = false
        this.currentPage = 1
  
        setTimeout(() => {
          this.ready = true
        }, 500)
      },
      search(val) {
        this.currentPage = 1
      }
    },
    methods: {
      exportToExcel(){
          //API de genereation donne en excel
        },
          DateFormatter: function (TheDate) {
              if (TheDate) {
                  return dayjs(TheDate).format('DD-MM-YYYY')
              }
          },
  
          detailcontrat(item) {
              this.detailsContrat = item
              this.showcontrat = true
          },
          DateFormater: function (TheDate) {
              if (TheDate) {
                  return dayjs(TheDate).format('DD-MM-YYYY')
              }
          },
          
          SetDetailUser(details) 
          {
              this.detailsUser = details
              this.showUser = true
          },
  
           getContrat() {
  
              if (this.$store.state.user.role == 'agent général') {
                  Api().get('/contrat/assureur/' + this.$store.state.user.societe)
                      .then(resultat => {
                          this.listcontrat = resultat.data.contrats
                          this.totalRows = resultat.data.contrats.length
                      });
  
              } else if (this.$store.state.user.role == 'Agent') {
                  Api().get('/contrat/courtier/' + this.$store.state.user.societe)
                      .then(resultat => {
                          this.listcontrat = resultat.data.contrats
                          this.totalRows = resultat.data.contrats.length
                      });
  
              } else if (this.$store.state.user.role == 'Assureur') {
                  Api().get('/contrat/assureur/' + this.$store.state.user.societe)
                      .then(resultat => {
                          this.listcontrat = resultat.data.contrats
                          this.totalRows = resultat.data.contrats.length
                      });
  
              } else if (this.$store.state.user.role == 'Admin') {
                  Api().get('/contrat/express')
                      .then(resultat => {
                          this.listcontrat = resultat.data.contrats
                          this.totalRows = resultat.data.contrats.length
                      });
              }
  
          },
  
          FiltreDate() {
              if (this.du && this.au) {
                  switch (store.state.user.role) {
                      case 'Admin':
                          Api().post('contrat/filtreContrat/periode', {
                                  du: this.du,
                                  au: this.au
                              })
                              .then(resultat => {
                                  this.listcontrat = resultat.data.contrats
                              });
                          break;
  
                      case 'Agent':
                          Api().post('contrat/filtreContrat/courtier/periode/' + store.state.user.societe, {
                                  du: this.du,
                                  au: this.au
                              })
                              .then(resultat => {
                                  this.listcontrat = resultat.data.contrats
                              });
                          break;
  
                      case 'assureur':
                          Api().post('contrat/filtreContrat/assureur/periode/' + store.state.user.societe, {
                                  du: this.du,
                                  au: this.au
                              })
                              .then(resultat => {
                                  this.listcontrat = resultat.data.contrats
                              });
                          break;
                      case 'agent général':
                          Api().post('contrat/filtreContrat/assureur/periode/' + store.state.user.assureur, {
                                  du: this.du,
                                  au: this.au
                              })
                              .then(resultat => {
                                  this.listcontrat = resultat.data.contrats
                              });
                          break;
  
                      default:
                          break;
                  }
              }
  
          },
          
  
  
  
  
  
  
  
  
  
      AlertMessage() {
        this.$message({
          showClose: false,
          duration : 5000
        });
      },
  
      detaildevis(item) {
  
        this.detailsDevis = item
        this.showdevis = true
      },
  
      // Fonction de download du devis en fichier pdf
      Downloa_devis: function (devis) {
        this.modalgenerationloading = true;
        Api().get('devis/download/' + devis._id)
            .then(response => {
              //this.modalgeneration = true
              let url = response.data.lien
              setTimeout(() => (
                  this.modalgenerationloading = false,
                      window.open(url)), 7000);
            })
      },
  
      Edit_devis: function (devis) {
  
        switch (devis.brancheid.branche) {
          case 'Automobile':
            Api().get('devis/' + devis._id)
                .then(response => {
                  this.$store.dispatch('setDevisReopen', response.data.devis)
                  this.$router.go(this.$router.push({
                    name: 'update_devis_auto'
                  }))
                })
  
            break;
  
          case 'Habitation':
            this.$router.go(this.$router.push({
              name: 'update_devis_habitation'
            }))
            break;
  
          case 'Voyage':
            this.$router.go(this.$router.push({
              name: 'update_devis_voyage'
            }))
            break;
  
        }
      },
  
  
      Remover() {
        Api().post('sinistre/remove')
            .then(rep => {})
      },
  
      getDevis() {
  
        if (this.$store.state.user.role == 'agent général') {
          Api().get('/devis/assureur/' + this.$store.state.user.societe)
              .then(resultat => {
                this.listdevis = resultat.data.devis
                this.totalRows = this.listdevis.length
              });
        } else if (this.$store.state.user.role == 'Agent') {
          Api().get('/devis/courtier/' + this.$store.state.user.societe)
              .then(resultat => {
                this.listdevis = resultat.data.devis
                this.totalRows = resultat.data.devis.length
              });
  
        } else if (this.$store.state.user.role == 'Assureur') {
          Api().get('/devis/assureur/' + this.$store.state.user.societe)
              .then(resultat => {
                this.listdevis = resultat.data.devis
                this.totalRows = resultat.data.devis.length
              });
        } else if (this.$store.state.user.role == 'Admin') {
          Api().get('/devis/')
              .then(resultat => {
                this.listdevis = resultat.data.devis
                this.totalRows = resultat.data.devis.length
              });
        }
  
      },
  
      devisDeleteConfirmation(item) {
        this.modalDelete = true
        this.detailsDevis = item
      },
  
      ArchivateDevis(devis) {
        let id = devis._id
        Api().post('devis/archivate/' + id)
            .then(resp => {
              this.getDevis()
            })
      },
  
      AddEtatUser() {
        Api().post('devis/addetatuser')
            .then(resultat => {})
            .catch(err => {})
      },
  
      DeletingDevis(id) {
        Api().delete('devis/delete/' + id)
            .then(resultat => {
              if (resultat.data.rep.n == 1) {
                this.modalDelete = false
                this.getDevis()
              }
            })
            .catch(err => {})
      },
  
      FiltreDate() {
              if (this.du && this.au) {
                  switch (store.state.user.role) {
                      case 'Admin':
                          Api().post('contrat/filtreContrat/periode', {
                                  du: this.du,
                                  au: this.au
                              })
                              .then(resultat => {
                                  this.listcontrat = resultat.data.contrats
                              });
                          break;
  
                      case 'Agent':
                          Api().post('contrat/filtreContrat/courtier/periode/' + store.state.user.societe, {
                                  du: this.du,
                                  au: this.au
                              })
                              .then(resultat => {
                                  this.listcontrat = resultat.data.contrats
                              });
                          break;
  
                      case 'assureur':
                          Api().post('contrat/filtreContrat/assureur/periode/' + store.state.user.societe, {
                                  du: this.du,
                                  au: this.au
                              })
                              .then(resultat => {
                                  this.listcontrat = resultat.data.contrats
                              });
                          break;
                      case 'agent général':
                          Api().post('contrat/filtreContrat/assureur/periode/' + store.state.user.assureur, {
                                  du: this.du,
                                  au: this.au
                              })
                              .then(resultat => {
                                  this.listcontrat = resultat.data.contrats
                              });
                          break;
  
                      default:
                          break;
                  }
              }
  
  
          },
  
      toggleDetails(item){
        this.detailsUser = item
        this.editUser = true
      },
  
  
  
  
  
  
  
      DeleteBrancheCourtier(item) {
        Api().post("/branchecourtier/delete", {id:item._id})
            .then((result) => {
              Api().post("/branchecourtier/get/all/", {userid:item.userid})
                  .then((response) => {
                    this.configUserDevisAssureur = response.data.partenaires
                  })
                  .catch((err) => {});
            }).catch((err) => {
  
        });
      },
  
      AddbrancheConfig() {
        Api().post("/branchecourtier/create", {configuration:this.configUserDevisAssureur})
            .then((result) => {
              this.configUserDevisAssureur = []
            }).catch((err) => {
          console.log(err)
        });
      },
  
      pushConfig() {
        if(this.Selectedbranche && this.selectedAssureur)
        {
          this.configUserDevisAssureur.push({
            branche:this.Selectedbranche,
            assureurs:this.selectedAssureur,
            userid: this.selectedUser.id
          })
          this.Selectedbranche  = null
          this.selectedAssureur = []
        }
      },
  
      loadConfigBranchePopUp(item) {
        this.modalSociete = true
        this.selectedUser = item
        Api().post("/branchecourtier/get/all/", {userid:item.id})
            .then((response) => {
              this.configUserDevisAssureur = response.data.partenaires
            })
            .catch((err) => {});
      },
  
      setCollapse(){
        this.collapse = "id_"+Date.now()+"_collapse"
      },
  
      MenuDelete(item)
      {
        Api().delete("/menu/"+item._id)
            .then((result) => {
              this.getUserMenu(this.detailsUser._id)
            }).catch((err) => {
  
        });
      },
  
      pushMenu()
      {
        if(this.menu_name && this.userSubmenu.length >= 1)
        {
          this.UserFinalMenu.push({
            user : this.detailsUser.id,
            theme:this.menu_name,
            collapse:this.collapse,
            config:this.userSubmenu
          })
          this.menu_name   = null
          this.userSubmenu = []
          this.collapse    = null
        }else{
          this.$message.error('Remplissez les champs vides !', this.AlertMessage)
        }
      },
  
      create_user_menu()
      {
        if(this.UserFinalMenu.length >=1 )
        {
          Api().post("/menu/add", this.UserFinalMenu)
              .then((result) => {
                this.$message.success('Opération effectuée !', this.AlertMessage)
                console.log(result.data)
                this.UserFinalMenu = []
              }).catch((err) => {
            console.log(err)
          });
        }else{
          this.$message.error('Le menu ne doit pas être vide !', this.AlertMessage)
        }
      },
  
      merge(){
        Api().post('/user/merge/userdata', {userid:this.detailsUser.id})
            .then((result) => {
              this.showuser = false
              if(result.data.result){
                alert('Opération réussie')
              }
            }).catch((err) => {
          console.log('=======err=========')
        });
      },
  
      detailuser(item) {
        this.getUserMenu(item._id)
        this.detailsUser = item
        this.showuser = true
      },
  
      ready: function () {
        var self = this;
  
        setInterval(function () {
          self.$data.ticker = Date.now();
        }, 1000);
      },
  
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
  
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      },
  
      getUserList:function(){
        switch (this.$store.state.user.role) {
          case 'Admin':
            Api().get('/user/frontoffice/')
                .then( resultat => {
                  this.listuser = resultat.data
                  this.totalRows = resultat.data.length
                });
            break;
  
  
          case 'assureur':
            Api().get('/user/myuser/assureur/'+this.$store.state.user.societe)
                .then( resultat => {
                  this.listuser = resultat.data
                  this.totalRows = resultat.data.length
                });
            break;
  
          default:
            break;
        }
      },
  
      UpdateUser(item) {
  
        let idU = item.id
        Api().post('user/' + idU, item)
            .then(response => {
              if (response.data) {
                this.showAlert()
              }
              this.showAlert()
            })
            .catch(err => {
  
            })
  
      },
  
      DateFormater: function (TheDate) {
        if (TheDate) {
          return dayjs(TheDate).format('DD-MM-YYYY')
        }
      },
  
      userDeleteConfirmation(item) {
  
        this.modalDelete = true
        this.detailsUser = item
  
      },
  
      DeletingUser(id) {
        Api().delete('user/delete/' + id)
            .then(resultat => {
              if (resultat.status == 200) {
                this.modalDelete = false
                this.getUserList()
              }
            })
            .catch(err => {})
      },
  
      getUserMenu(id){
        Api().post('/menu/user/menu', {userid:id})
            .then((result) => {
              this.usermenu = result.data.menus
              console.log(result.data.menus)
            }).catch((err) => {
          console.log(err)
        });
      },
  
  
  
  
  
  
  
  
      calcDims() {
        const tableWrapper = document.getElementById("table-wrapper")
        if (tableWrapper) this.width = tableWrapper.clientWidth
  
        if (!this.isMobile && tableWrapper) {
          this.height = tableWrapper.clientHeight - 44
        }
  
        if (this.width < 480) {
          this.pagination.small = true
          this.pagination.layout = "prev, pager, next"
        } else if (this.width >= 480 && this.width < 700) {
          this.pagination.small = false
          this.pagination.layout = "prev, pager, next, ->, sizes"
        } else {
          this.pagination.small = false
          this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
        }
  
        this.ready = true
      },
      handleResize: _.throttle(function(e) {
        this.ready = false
        this.width = 0
        setTimeout(this.calcDims, 1000)
      }, 500),
      handleSelectionChange(val) {
        this.itemsChecked = val
      },
      init() {
        if (window.innerWidth <= 768) this.isMobile = true
      }
    },
    filters: {
      selected: function(value, sel) {
        if (!value) return ""
        if (!sel) return value
  
        value = value.toString()
        sel = sel.toString()
  
        const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
        if (startIndex !== -1) {
          const endLength = sel.length
          const matchingString = value.substr(startIndex, endLength)
          return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
        }
  
        return value
      }
    },
    created() {
      this.init()
      //this.getContrat()
    },
    mounted() {
      if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
           Api().get('/user/clients')
                  .then(resultat => {
                      this.listuser = resultat.data
  
                  });
        this.getContrat()
      } else {
        this.$router.go(this.$router.push('/login'))
      }
  
      //ie fix
      if (!window.Number.parseInt) window.Number.parseInt = parseInt
  
      this.calcDims()
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import "../../../assets/scss/_variables";
  
  
  .detailsSeeAttest li {
      list-style: none !important;
      line-height: 16px;
      font-family: Arial, sans-serif;
  }
  
  .AttestPoliceSize {
      font-size: 12px !important;
  }
  
  .detailsSee li {
      list-style: none;
      border-bottom: 1px solid #000;
  }
  
  
  .page-table {
    &.overflow {
      overflow: auto;
    }
  
    .toolbar-box {
      &.hidden {
        visibility: hidden;
      }
    }
  
    .table-box {
      overflow: hidden;
  
      &.hidden {
        visibility: hidden;
      }
    }
  }
  </style>
  
  <style lang="scss">
  @import "../../../assets/scss/_variables";
  
  .page-table {
    .toolbar-box {
      margin-bottom: 10px;
  
      .card-shadow--medium {
        &.el-input,
        &.el-select {
          border-radius: 4px;
  
          input {
            border-color: transparent;
            background-color: lighten($background-color, 2%);
            color: $text-color;
  
            &:hover {
              border-color: #c0c4cc;
            }
            &:focus {
              border-color: $text-color-accent;
            }
          }
        }
  
        &.el-button {
          border-color: transparent;
        }
      }
    }
  
    .clickable {
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;
    }
  
    .sel-string {
      .sel {
        background: transparentize($text-color, 0.8);
        border-radius: 5px;
        //text-transform: uppercase;
      }
    }
  }
  
  @media (max-width: 768px) {
    .page-table {
      .toolbar-box {
        display: block;
        overflow: hidden;
        font-size: 80%;
        padding-bottom: 10px;
  
        & > * {
          display: inline-block;
          min-width: 120px;
          height: 22px;
          //background: rgba(0, 0, 0, 0.04);
          margin-bottom: 16px;
        }
      }
    }
  }
  .button-filtre{
    padding: 12px!important;
    margin : 0 15px!important;
    background: #C0C7CF!important;
    border:none!important;
    color:#fff!important;
  }
  </style>
  