<template>
  <vue-scroll class="page-devis-habitaion">
    <div class="page-header">
      <h1>Création de devis <span style="color: #ffa409">Flotte</span></h1>
      <el-button @click="download_tempalte_file()" type="primary" text flat small round  v-loading.fullscreen.lock="modalgenerationloading"> 
        Télécharger le Template
      </el-button>
    </div>
    <resize-observer @notify="handleResize" />

    <div
      v-if="hidesearchClient"
      class="card-base card-shadow--medium bg-white black-text ph-5 p-20"
    >
      <div style="margin-top: 15px">
        <h3>Rechercher client</h3>
        <el-row :gutter="15" justify="center">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-radio
                v-model="clientsearch"
                @change="check_client_search()"
                label="téléphone"
                >Numéro de téléphone</el-radio
              >
              <el-radio
                v-model="clientsearch"
                @change="check_client_search()"
                label="email"
                >Adresse e-mail</el-radio
              >
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top: 15px">
          <el-col :span="10">
            <el-input
              v-if="NumberInput"
              type="text"
              v-model="telephoneclient"
              placeholder="Votre numéro de téléphone"
              class="input-with-select"
            >
              <el-button
                v-if="clientsearch === 'téléphone'"
                @click="rechercherClient()"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
            <el-input
              v-if="EmailInput"
              v-model="emailclient"
              placeholder="Votre adresse e-mail"
              class="input-with-select"
            >
              <el-button
                v-if="clientsearch === 'email'"
                @click="rechercheClientByMail()"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </el-col>
          <el-col :span="10">
            <div v-if="clientOk">
              Client :
              <span style="font-weight: bold; color: #ffa409">{{
                usersearch.prenom + " " + usersearch.nom
              }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 15px" v-if="clientnonOk">
        <div style="margin-top: 15px">
          Aucun client trouvé
          <el-button @click="AddUser = true" type="primary" round
            >Créer</el-button
          >
        </div>
      </div>
      <div class="black-text pt-20" v-if="clientOk">
        <form enctype="multipart/form-data" novalidate>
          <div class="dropbox">
            <input
              type="file"
              name="fileExcel"
              @change="
                filesChange($event.target.files);
                fileCount = $event.target.files.length;
              "
              :disabled="isSuccess"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              class="input-file"
            />
            <p v-if="isInitial || isSaving">
              Déposer le fichier excel ici pour commencer<br />
              ou bien cliquer pour charger
            </p>
            <p v-if="isSuccess">chargement complet !!!</p>
            <p v-if="isSaving || isSuccess">
              <el-button type="success" round class="float-right">{{
                file.name
              }}</el-button>
            </p>
          </div>
        </form>
        <div>
          <el-button
            v-if="isSaving"
            type="primary"
            round
            class="float-right mt-5"
            @click="valide()"
          >
            Valider
          </el-button>
        </div>
      </div>
    </div>

    <div
      class="table-box box grow mt-15"
      id="table-wrapper"
      v-loading="!ready"
      v-if="showTable"
    >
      <el-table
        :data="listInPage"
        style="width: 100%"
        :height="height"
        v-if="ready"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          label="Tonnage"
          min-width="100"
          prop="tonnage"
        ></el-table-column>

        <el-table-column
          label="Categorie"
          min-width="100"
          prop="categorie"
        ></el-table-column>

        <el-table-column
          label="Volumetrie"
          min-width="150"
          prop="volumetrie2_roues"
        ></el-table-column>

        <el-table-column
          label="Marque"
          min-width="150"
          prop="marque"
        ></el-table-column>

        <el-table-column
          label="Carosserie"
          min-width="150"
          prop="carosserie"
        ></el-table-column>

        <el-table-column
          label="Model"
          min-width="150"
          prop="model"
        ></el-table-column>

        <el-table-column
          label="Force"
          min-width="150"
          prop="force"
        ></el-table-column>

        <el-table-column label="Date création" prop="created" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.garanties }}
          </template>
        </el-table-column>

        <el-table-column
          label="Date circulation"
          prop="dateCircule"
          min-width="150"
        >
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateCircule) }}
          </template>
        </el-table-column>
         <el-table-column
          label="Date création"
          prop="created"
          min-width="150"
        >
          <template slot-scope="scope">
            {{ DateFormater(scope.row.created) }}
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="Actions"
          prop="action"
          min-width="140"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="test(scope.row)"
              icon="el-icon-view"
              circle
            ></el-button>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              circle
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
              title="Supprimer"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="ready"
        :small="pagination.small"
        :current-page.sync="pagination.page"
        :page-sizes="pagination.sizes"
        :page-size.sync="pagination.size"
        :layout="pagination.layout"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      width="70%"
      title="Créer un client"
      :visible.sync="AddUser"
    >
      <el-form ref="form" label-width="120px" label-position="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Nom</label>
              <el-input placeholder="Votre nom" v-model="nom"></el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Prénom</label>
              <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <label>Numéro de téléphone</label>
              <el-input
                placeholder="Numéro de téléphone"
                v-model="telephoneclient"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <label>Adresse e-mail</label>
              <el-input
                placeholder="votre adresse e-mail"
                v-model="emailclient"
              ></el-input>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top: 15px">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <label>Sexe</label>
              <el-select
                style="width: 100% !important"
                v-model="genre"
                placeholder="Selectionner votre sexe"
              >
                <el-option label="Homme" value="homme"></el-option>
                <el-option label="Femme" value="femme"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div style="margin-top: 15px">
        <el-button type="primary" round @click="createuser()">Créer</el-button>
      </div>
    </el-dialog>
  </vue-scroll>
</template>

<script>
import Api from "../../../services/Api";
import store from "../../../store";
import dayjs from "dayjs";
import lib from "../../../libs/index";
import customPassword from "../../../services/password_generator"

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

const validatePhone = (phone) => {
  if (!phone.length) {
    return { valid: false, error: "Le numéro de téléphone est obligatoire." };
  } else {
    return { valid: true, error: null };
  }
};

const validateEmail = (email) => {
  if (!email.length) {
    return { valid: false, error: "L'adresse e-mail est obligatoire!" };
  } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    return { valid: false, error: "Veuillez entrer un email valide!" };
  } else {
    return { valid: true, error: null };
  }
};

export default {
  name: "Upload",
  data: () => ({
    nom: null,
    prenom: null,
    telephoneclient: null,
    emailclient: null,
    genre: null,
 datenaissance: null,
            profession: null,
password1 : null,
    hidesearchClient: true,
    NumberInput: false,
    EmailInput: false,
    emailclient: null,
    clientOk: false,
    clientnonOk: false,
    userid: null,
    usersearch: {},
    AddUser: false,
    telephoneclient: null,
    errors: {},
    tableData: [],
    showTable: false,
    file: null,

    fileExcel: [],
    filter: null,
    uploadedFiles: [],
    uploadError: null,
    currentStatus: null,
    ready: false,
    height: "auto",
    width: 0,
    search: "",
    loading: false,
    clientsearch: "",
    pagination: {
      page: 1,
      size: 100,
      sizes: [10, 15, 20, 30, 50, 100],
      layout: "total, ->, prev, pager, next, jumper, sizes",
      small: false,
    },
    modalgenerationloading :false,
  }),
  computed: {
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.pagination.page = val;
      },
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage;
      let to = from + this.itemPerPage * 1;
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to);
    },
    total() {
      return this.listFiltered.length;
    },
    listFiltered() {
      return this.tableData.filter((obj) => {
        let ctrl = false;
        for (let k in obj) {
          if (
            obj[k] &&
            obj[k]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true;
        }
        return ctrl;
      });
    },
    itemPerPage() {
      return this.pagination.size;
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  watch: {
    itemPerPage(val) {
      this.ready = false;
      this.currentPage = 1;

      setTimeout(() => {
        this.ready = true;
      }, 500);
    },
    search(val) {
      this.currentPage = 1;
    },
  },
  methods: {
    download_tempalte_file()
    {
      this.modalgenerationloading = true;
      let url = lib.s3_endpoint+"uploads/Flotte.xlsx"
      let WinOpener = null;
      setTimeout(() => ((this.modalgenerationloading = false),(WinOpener = window.open()),(WinOpener.location = url)), 7000);
    },
    
    rechercheClientByMail() {
      if (this.emailclient) {
        const validEmail = validateEmail(this.emailclient);
        this.errors.email = validEmail.error;

        if (validEmail.valid == true) {
          Api()
            .get("/user/email/" + this.emailclient)
            .then((resultat) => {
              if (resultat.data.email === this.emailclient) {
                this.clientOk = true;
                this.clientnonOk = false;
                this.usersearch = resultat.data;
              }
            })
            .catch((e) => {
              this.clientnonOk = true;
              this.clientOk = false;
              this.usersearch = null;
            });
        } else {
          this.$message.error(
            "Veuillez entrer un email valide !",
            this.AlertMessage
          );
        }
      } else {
        this.$message.error("Adresse e-mail obligatoire !", this.AlertMessage);
      }
    },

    rechercherClient() {
      if (this.telephoneclient) {
        const validPhone = validatePhone(this.telephoneclient);
        this.errors.phone = validPhone.error;

        if (validPhone.valid == true) {
          Api()
            .get("/user/phone/" + this.telephoneclient)
            .then((resultat) => {
              if (resultat.data.telephone_port === this.telephoneclient) {
                this.clientOk = true;
                this.clientnonOk = false;
                this.usersearch = resultat.data;
              }
            })
            .catch((e) => {
              this.clientnonOk = true;
              this.clientOk = false;
              this.usersearch = null;
            });
        } else {
          this.$message.error(
            "Le numéro de téléphone doit contenir 9 chiffres au minimum !",
            this.AlertMessage
          );
        }
      } else {
        this.$message.error(
          "Numéro de téléphone obligatoire !",
          this.AlertMessage
        );
      }
    },

    check_client_search() {
      if (this.clientsearch == "téléphone") {
        this.NumberInput = true;
        this.EmailInput = false;
        this.emailclient = null;
        this.clientOk = false;
        this.clientnonOk = false;
      } else if (this.clientsearch == "email") {
        this.NumberInput = false;
        this.EmailInput = true;
        this.telephoneclient = null;
        this.clientOk = false;
        this.clientnonOk = false;
      }
    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format("DD/MM/YYYY");
      }
    },

    test(ok) {
      console.log(ok);
    },

    handleSelectionChange(val) {
      this.itemsChecked = val;
    },

    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper");
      if (tableWrapper) this.width = tableWrapper.clientWidth;

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44;
      }

      if (this.width < 480) {
        this.pagination.small = true;
        this.pagination.layout = "prev, pager, next";
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false;
        this.pagination.layout = "prev, pager, next, ->, sizes";
      } else {
        this.pagination.small = false;
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes";
      }

      this.ready = true;
    },

    handleResize: _.throttle(function (e) {
      this.ready = false;
      this.width = 0;
      setTimeout(this.calcDims, 1000);
    }, 500),
    
    AlertMessage() {
      this.$message({
        showClose: false,
        duration: 5000,
      });
    },

    reset() {
      this.showTable = false;
      this.tableData = [];
      this.file = null;
      this.fileExcel = [];
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    save() {},
    filesChange(fileList) {
      if (!fileList.length) return;

      this.file = fileList[0];

      const fileExt = this.file.name.substring(this.file.name.lastIndexOf("."));

      const validExts = new Array(".xlsx", ".xls");

      if (validExts.indexOf(fileExt) < 0) {
        this.$message.error(
          "fichier invalide, les fichiers valides sont : " +
            validExts.toString(),
          this.AlertMessage
        );

        return false;
      } else {
        this.currentStatus = STATUS_SAVING;
      }
    },

    // ============================================================================

    upload(formData) {
      const photos = formData.getAll("photos");
      const promises = photos.map((x) =>
        this.getImage(x).then((img) => ({
          id: img,
          originalName: x.name,
          fileName: x.name,
          url: img,
        }))
      );
      return Promise.all(promises);
    },

    getImage(file) {
      return new Promise((resolve, reject) => {
        const fReader = new FileReader();
        const img = document.createElement("img");

        fReader.onload = () => {
          img.src = fReader.result;
          resolve(getBase64Image(img));
        };

        fReader.readAsDataURL(file);
      });
    },

    getBase64Image(img) {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = img.src;
      return dataURL;
    },

    valide() {
      const formData = new FormData();
      formData.append("excel", this.file);
      formData.append("userid", this.usersearch._id);

      Api()
        .post("/tempflotte/add", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((result) => {
          this.$message.success(
            "Fichier excel uploadé avec success !",
            this.AlertMessage
          );
          console.log(result.data)
          this.$router.push({
            name: "devisflotte",
            params: { id: result.data.vehicules[0].reference },
          });
          this.tableData = result.data.vehicules;
          this.currentStatus = STATUS_SUCCESS;
        })
        .catch((err) => {
          this.$message.error(
            "Formatage du fichier non reconnu !",
            this.AlertMessage
          );
          console.log(err);
        });
    },

    //Creation d'un user avant de faire son devis
    createuser: function () {
      this.generatePassword();
      if (
        this.nom &&
        this.prenom &&
        this.emailclient &&
        this.genre &&
        this.telephoneclient
      ) {
        const user = {
          nom: this.nom,
          prenom: this.prenom,
          datenaissance: this.datenaissance,
          profession: this.profession,
          adresse: this.adresse,
          sexe: this.genre,
          telephone_port: this.telephoneclient,
          email: this.emailclient,
          password: this.password1,
          confirmPass: this.password1,
          role: "User",
          createdby: store.state.user._id,
          photo: null,
          piece_identite: null,
          numero_piece: null,
          created: dayjs(new Date()).format("YYYY-MM-DD"),
        };
        Api()
          .post("user/register", user)
          .then((resultat) => {
            this.HubspotSend(user);
            this.MailChimpInscriptionCompte(user);
            if (resultat.status == 200) {
              if (resultat.data.numero) {
                this.$message.error(resultat.data.msg, this.AlertMessage);
              } else {
                this.$store.dispatch("setClient", resultat.data);
                this.usersearch = resultat.data;
                this.usersearch._id = resultat.data._id
                this.AddUser = false;
                this.clientnonOk = false;
                this.clientOk = true;
              }
            } else {
              this.$message.error(
                "Ajout utilisateur échoué !",
                this.AlertMessage
              );
            }
          })
          .catch((err) => {
            this.$message.error(
              "Ajout utilisateur échoué !",
              this.AlertMessage
            );
          });
      } else {
        this.$message.error(
          "Tous les champs sont obligatoires !",
          this.AlertMessage
        );
      }
    },

      HubspotSend(param)
        {
            let data = {
                "email": param.email,
                "firstname": param.prenom,
                "lastname": param.nom,
                "phone": param.telephone_port,
                "inscrit": "Dash"
            };
            Api().post('/testimonial/hubspot', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        MailChimpInscriptionCompte(param)
        {
            let data = {
                "email": param.email,
                "FNAME": param.prenom,
                "LNAME": param.nom,
                "PHONE": param.telephone_port,
            };
            Api().post('/testimonial/mailchimp', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

    generatePassword: function () {
      this.password1 = customPassword.customPassword(this.nom, this.prenom)
    },
  },

  created() {},
  mounted() {
    this.reset();
    this.calcDims();
  },
};
</script>

<style lang="scss" scope>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 20px 0;
}
</style>