<template>
    <vue-scroll class="page-devis-auto" >
        <div class="page-header">
			<h1>Simulation de <span style="color:#ffa409">Calcul de Puissance fiscale</span></h1>
		</div>

        <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <el-row :gutter="12">
                <el-col :span="12">
                    <div v-if="categorie !== '2_roues'" style="margin-top:15px">
                        <div>
                            <h3>Carburation :</h3>
                            <el-row :gutter="15" justify="center">
                                <el-col>
                                    <div class="grid-content bg-purple">
                                        <el-radio v-model="carburant" label="Essence">Essence</el-radio>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="grid-content bg-purple-light">
                                        <el-radio v-model="carburant" label="Diésel">Gasoil</el-radio>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                        <h3>Cylindrée :</h3>
                        <label>Cylindrée du moteur</label>
                        <el-input @keypress="onlyNumber()" type="number" placeholder="Saisir le cylindree en" v-model="cylindre">
                        <template slot="append">cm3</template>
                        </el-input>
                    </div>
                </el-col>
            </el-row>

            <el-divider v-if="this.resultatSim != null"></el-divider>

            <el-row :gutter="12">
                <el-col :span="24" v-if="this.resultatSim != null">
                    <div class="text-center grid-content bg-purple-light">
                        <p>Résultat :</p>
                        <h3>Puissance fiscale : <span style="color: #ffa409;">{{ resultatSim }}</span></h3>
                    </div>
                </el-col>
            </el-row>
               
            <el-divider></el-divider>

            <el-row>
                <div style="margin-top:15px">
                <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="calculPuissanceFiscale()" type="primary" round>Simuler</el-button>
            </div>
            </el-row>
        </div>
    </vue-scroll>
</template>

<script>
//debut importation assuraf
import Api from '../../../services/Api'
import store from '../../../store'
import _ from 'underscore'

export default {
    name: "calcul_puissance_ficale",
   
    data() {
        return {
            resultatSim : null, 
            cylindre: null,
            carburant : null,
            simulatorResult : null,
            userRole: '',
            modalgenerationloading : false,
            isMobile: false,
            errors : {},
            chart: null,
            pie: null,
        }
    },
    created() {
        this.init()
    },
    mounted() {
        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
            this.getUserRoles()
        } else {
            this.$router.go(this.$router.push('/login'))
        }
        window.addEventListener("resize", this.__resizeHanlder)
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }

        if (!this.pie) {
            return
        }

        window.removeEventListener("resize", this.__resizeHanlder)
        this.chart.dispose()
        this.pie.dispose()
        this.chart = null
        this.pie = null
    },
    methods: {
        calculPuissanceFiscale() {
            //=============== calcule Puissance fiscale function ==============
            if(this.carburant){
                    if(this.cylindre){
                        this.modalgenerationloading = true
                        if(this.carburant == "Essence") {
                            setTimeout(() => (
                                this.resultatSim =Math.round(this.resultatSim = (this.cylindre * 5.71) / 1000),
                                this.modalgenerationloading = false,
                                this.ClearForm()
                            ), 2000);
                        } else {
                            setTimeout(() => (
                                this.resultatSim = Math.round(this.resultatSim = (this.cylindre * 4.019) / 1000),
                                this.modalgenerationloading = false,
                                this.ClearForm()
                            ), 2000);
                        }
                    }else {
                        this.$message.error('La cylindrée du moteur en cm3 est obligatoire !', this.AlertMessage)
                    }
            }else {
                this.$message.error('La carburation du véhicule est obligatoire !', this.AlertMessage)
            }
        },

        getUserRoles(){ 
            Api().get('/roles/all')
            .then(response => {
                this.listRoles = response.data.AllRoles
                for(let lr of response.data.AllRoles){
                    if(lr.name=="User"){
                        this.userRole = lr._id
                    }
                }
            }).catch(err => {console.log("failed getAllUserRoles");})
        },

        init() {
        if (window.innerWidth <= 768) this.isMobile = true
        },

        /*=============Filtre sans le + ni lettre sur les champs de type number ===========*/
        onlyNumber($event) {
            let NumOnly = ($event.NumOnly ? $event.NumOnly : $event.which);
            if ((NumOnly < 48 || NumOnly > 57) && NumOnly !== 46) {
                $event.preventDefault();
            }
        },

        AlertMessage() {
        this.$message({
          showClose: false,
          duration : 5000
        });
        },

        ClearForm: function () {
            this.cylindre = ''
            this.carburant = null
        },

        testVar:function(val){
             if (val!=null)
            {
                return Number(val.replace(/[^0-9.-]+/g, ""));
            } else {
                return "0";
            }
        },

        __resizeHanlder: _.throttle(function(e) {
            if (this.chart) {
                this.chart.resize()
            }
            if (this.pie) {
                this.pie.resize()
            }
        }, 700),
    }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables.scss";
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }
                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }
                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }
                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
</style>
