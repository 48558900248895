<template>
	<div class="page-ecommerce-product-detail scrollable only-y">
		
		<el-breadcrumb separator="/" class="themed">
			<el-breadcrumb-item>Simulation</el-breadcrumb-item>
			<el-breadcrumb-item>Liste</el-breadcrumb-item>
			<el-breadcrumb-item> <span style="color:#ffa409">{{listdevis.length}} </span>Offre(s) de simulation</el-breadcrumb-item>
		</el-breadcrumb>

        <el-row class="mt-30">
            <el-col>
                <div class="item-box card-shadow--medium" style="height: 100px;">
                    <div class="actions-box mt-15 ml-10">
                        <el-row :gutter="15">
                            <el-col :span="6">
                                <div class="grid-content bg-purple">
                                    <label>Durée (en mois)</label>
                                    <el-select style="width:100%" v-model="duree" placeholder="Selectionner">
                                        <el-option v-for="item in DureeMois" :key="item" :label="item" :value="item"></el-option>
                                    </el-select>
                                </div>
                            </el-col>
                            <el-col :span="6" style="margin-top: 20px;">
                                <el-button v-loading.fullscreen.lock="loading" @click="getPrimeSimulate()" type="primary">Simuler</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-col>
        </el-row>
    
		<el-row class="mt-30" v-if="!isMobile">
			<el-col>
				<div class="item-box card-shadow--medium" style="margin-top:20px" v-for="(assur,index) in listdevis" :key="index">
					<el-row>
						<el-col class="el-col-lg-4 el-col-xl-4">
							<div class="gallery-box" style="margin-left: 5%;margin-top: 20%;">
								<div>
									<img width="130" height="90" :src="assur.image" style="border-radius: 10%;">
								</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="16" :xl="16">
							<div class="detail-box">
                                <!-- <div class="mt-3 actions-box">
                                    <el-row :gutter="15">
                                        <el-col :span="6">
                                            <div class="grid-content bg-purple">
                                                <label>Durée (en mois)</label>
                                                <el-select style="width:100%" v-model="duree" placeholder="Selectionner">
                                                    <el-option v-for="item in DureeMois" :key="item" :label="item" :value="item"></el-option>
                                                </el-select>
                                            </div>
                                        </el-col>
                                        <el-col :span="6" style="margin-top: 20px;">
                                            <el-button @click="getPrimeSimulate(assur)" type="primary">Simuler</el-button>
                                        </el-col>
                                    </el-row>
								</div> -->

                                

                                <!-- <h2 class="title">Numéro Police : <span class="assuraf-orange">{{assur.police}}</span></h2> -->
                               
								<h2 class="title">Prime TTC sur <span style="color: #ffa409;">{{ $store.state.devis.duree }} mois</span> est de : <span class="assuraf-orange">{{assur.tarif.toLocaleString()}} FCFA</span></h2>
								<div class="description">
									frais de dossier inclus
								</div>
                                <el-divider></el-divider>
                                <div class="mt-3 actions-box">
                                    <el-button 
                                        v-loading.fullscreen.lock="modalgenerationloading" 
                                        class="themed mr-10 mb-10" 
                                        style="background:#ffa409;
                                        color:#fff" 
                                        v-if="$store.state.user.role=='Admin' || $store.state.user.role=='SuperAdmin'"
                                        type="button" @click="getSimuleToDevis(assur)">
                                        <i class="mdi mdi-cash-multiple"></i> Transformer en devis
                                    </el-button>
								</div>     
							</div>
						</el-col>
					</el-row>

                   
				</div>
			</el-col>
		</el-row>




        <!-- The pop-up for display and reduce devis prices
             The pop-up for display and reduce devis prices
             The pop-up for display and reduce devis prices  -->
        <el-dialog width="100%" style="margin-left:8%!important;margin-right:8%!important" title="Réduction spéciale" :visible.sync="modalReductionSpeciale">
            <div v-for="(car,indexCar) in CarDetailsArray" :key="indexCar">
             <el-row :gutter="20">
                        <el-col :span="24">
                            <div class="grid-content bg-purple">
                                <span>Marque & Modèle : <span style="color:#ffa409">{{car.marque+'-'+car.model}}</span></span>
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <div class="grid-content bg-purple">
                                <span>Immatriculation : <span style="color:#ffa409">{{car.immatriculation}}</span></span>
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <div class="grid-content bg-purple">
                               <span> Prime TTC sans réduction : <span style="color:red">{{oldPrimeTT.toLocaleString()}}</span> CFA</span>
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <div class="grid-content bg-purple">
                               <span>Prime TTC avec réduction : <span style="color:red">{{newprime_ttc.toLocaleString()}}</span> CFA</span>
                            </div>
                        </el-col>
                    </el-row>
                  <table style="width:100%;margin-top:20px">
                    <thead>
                        <th class="border text-center">Garantie(s)</th>
                        <th class="border text-center">Prime sans réduction</th>
                        <th class="border text-center">Taux de réduction</th>
                        <th class="border text-center">Prime après réduction</th>
                    </thead>
                    <tr  v-for="(gar, indexGar) in car.Detail_Prime" :key="indexGar">
                        <td class="border pl-3">
                            {{gar.garantie}}
                        </td>

                        <td class="border pl-3">
                            {{gar.prime.toLocaleString()}} CFA
                        </td>

                        <td class="border pl-3 pt-1 pb-1">
                            <span class="ml-2"> 
                                Cliquez pour selectionner 
                            </span>
                            <select 
                                style="width: 100%!important;" 
                                v-model="gar.PourcentageReduction" 
                                class="border pl-2 pr-2" 
                                @change="ReductionCalculation(gar)">
                                <option  value="0" key="0">0%</option>
                                <option v-for="n in 100" :value="n" :key="n">{{n}}%</option>
                            </select>
                        </td>

                        <td class="border pl-3" v-if="gar.primeAfterReduction != null">
                           {{gar.primeAfterReduction.toLocaleString()}} CFA
                        </td>    
                    </tr>
                </table>
            </div>
            <div style="margin-top:20px">
                <el-button v-if="newprime_ttc!=0" class="themed mr-10 mb-10 ml-0" type="primary" @click="UpdateDevisAfetrSpecialredution()"><i class="mdi mdi-cash-minus"></i> Valider réduction spéciale</el-button>
            </div>
        </el-dialog>

        <!-- POPUP LIEN DE PAIEMENT
             POPUP LIEN DE PAIEMENT
             POPUP LIEN DE PAIEMENT  -->
        <el-dialog title="LIEN DE PAIEMENT" :visible.sync="ThePaymentLinkModal">
            <el-form style="border-radius: 30px">
            <el-form-item label="Lien">
                <el-input readonly v-model="ThePaymentLink"></el-input>
            </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog title="LIEN DE DEVIS" :visible.sync="showRefLink">
            <el-form style="border-radius: 30px">
                <el-form-item label="Lien">
                    <el-input readonly v-model="reference_link"></el-input>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog title="Transformation de la simulation" :visible.sync="SearchUserForDevis">
            <vue-scroll>
                <div v-if="hidesearchClient" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
                    <div style="margin-top: 15px;">
                        <h3>Rechercher client</h3>
                        <el-row :gutter="15" justify="center">
                                <el-col :span="12">
                                    <div class="grid-content bg-purple">
                                        <el-radio v-model="clientsearch" @change="check_client_search()" label="téléphone">Numéro de téléphone</el-radio>
                                        <el-radio v-model="clientsearch" @change="check_client_search()" label="email">Adresse e-mail</el-radio>
                                    </div>
                                </el-col>
                        </el-row>

                        <el-row :gutter="15" style="margin-top:15px">
                            <el-col :span="10">
                                <el-input v-if="NumberInput" type="text" v-model="telephoneclient" placeholder="Votre numéro de téléphone" class="input-with-select">
                                    <el-button v-if="clientsearch ==='téléphone'" @click="rechercherClient()" slot="append" icon="el-icon-search"></el-button>
                                </el-input>
                                <el-input v-if="EmailInput" v-model="emailclient" placeholder="Votre adresse e-mail" class="input-with-select">
                                    <el-button v-if="clientsearch ==='email'" @click="rechercheClientByMail()" slot="append" icon="el-icon-search"></el-button>
                                </el-input>
                            </el-col>
                        </el-row>
                    </div> 

                    <div style="margin-top: 15px;" v-if="clientOk">
                        <div v-if="shownameclient" style="margin-top: 15px;">Client : <span style="font-weight: bold;color: #ffa409;">{{usersearch.prenom+' '+usersearch.nom}}</span><br><el-button style="margin-top:13px" @click="hidesearch" type="primary" round>Continuer</el-button></div>
                    </div>

                    <div style="margin-top: 15px;" v-if="clientnonOk">
                        <div style="margin-top: 15px;">Aucun client trouvé <el-button @click="AddUser=true" type="primary" round>Créer</el-button></div>
                    </div>
                </div>


                <div v-if="forUserCarOrNewCar" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" style="margin-top: 15px;">
                    <h3>Vous souhaitez une assurance </h3>
                    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
                        <el-row justify="center">
                            <el-col>
                            <div class="grid-content bg-purple">
                                <el-radio @change="getListVehicule()" v-model="myCars" size="medium" label="mes_vehicule">A partir d'un véhicule du client</el-radio>
                            </div>
                            </el-col>
                            <el-col><div class="grid-content bg-purple-light">
                                <el-radio @change="getListVehicule()" v-model="myCars" label="nouveau">A partir d'un nouveau véhicule</el-radio>
                            </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>

                <div v-if="infosSupOfVehicle" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" style="margin-top: 15px;">
                    <h3>Informations supplémentaires du véhicules</h3>
                    <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
                        <el-row :gutter="15">
                            <el-col :span="24">
                                <div v-if="categorie != '2_roues'">
                                    <div>
                                        <h3>Marque et modèle:</h3>
                                        <el-row :gutter="15">
                                            <el-col :span="8">
                                                <div class="grid-content bg-purple">
                                                    <label>Marque du véhicule</label>
                                                    <el-select style="width: 100%!important;" v-model="marque" placeholder="Selectionner">
                                                        <el-option
                                                        v-for="(items, index) in listMarque"
                                                        :key="index"
                                                        :label="items.Marque"
                                                        :value="items.Marque">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </el-col>
                                            <el-col :span="8">
                                            <div class="grid-content bg-purple-light">
                                                <label>Modéle du véhicule</label>
                                                <el-select style="width: 100%!important;" v-model="model" placeholder="Selectionner">
                                                    <el-option
                                                    v-for="(items, index) in listeModeles"
                                                    :key="index"
                                                    :label="items.Type"
                                                    :value="items.Type">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            </el-col>
                                            <el-col :span="8">
                                                <div class="grid-content bg-purple-light">
                                                <label>Immatriculation</label>
                                                <el-input maxlength="10" @input="validateImmatriculation" placeholder="Immatriculation" v-model="immatriculation">
                                                </el-input>
                                            </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>

                                <div v-if="categorie == '2_roues'">
                                    <div>
                                        <h3>Marque :</h3>
                                        <el-row :gutter="20">
                                            <el-col :span="12">
                                                <div class="grid-content bg-purple">
                                                    <label>Marque du 2 roues</label>
                                                    <el-select style="width: 100%!important;" v-model="marque" placeholder="Selectionner">
                                                        <el-option
                                                        v-for="(items, index) in listeMarque2roues"
                                                        :key="index"
                                                        :label="items.Marque"
                                                        :value="items.Marque">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </el-col>
                                            <el-col :span="12">
                                                <div class="grid-content bg-purple-light">
                                                    <label>Modéle du 2 roues</label>
                                                    <el-select style="width: 100%!important;" v-model="model" placeholder="Selectionner">
                                                        <el-option
                                                        v-for="(items, index) in listeModeles2roues"
                                                        :key="index"
                                                        :label="items.Type"
                                                        :value="items.Type">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>

                            </el-col>
                            <!-- <el-col :span="8">
                                <div class="grid-content bg-purple-light">
                                    <label>Immatriculation</label>
                                    <el-input maxlength="10" @input="validateImmatriculation" placeholder="Immatriculation" v-model="immatriculation">
                                    </el-input>
                                </div>
                            </el-col> -->
                        </el-row>

                        <el-row>
                                <div style="margin-top:15px">
                                <!-- <el-button style="float:left" @click="e1 =0" round>Retour</el-button> -->
                                <el-button @click="passage_devis" type="primary" round>Créer devis</el-button>
                            </div>
                        </el-row>
                    </div>
                </div>
            </vue-scroll>
        </el-dialog>

        <el-dialog width="70%" title="Mes véhicules" :visible.sync="ModalListeContrat" v-if="ModalListeContrat">
           <el-table :data="userCars" class="padded-table" height="300" style="width: 100%" row-key="id">
                  <el-table-column prop="marque" label="Marque" min-width="200"> </el-table-column>
                  <el-table-column prop="model" label="Modèle" min-width="180"> </el-table-column>
                  <el-table-column prop="nbplace" label="Places" min-width="100"></el-table-column>
                  <el-table-column prop="force" label="Force" min-width="100"> </el-table-column>
                    <el-table-column fixed="right" label="Actions" prop="action" min-width="100">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="getCarForDevis(scope.row)" type="primary" icon="el-icon-right" circle title="Voir détails"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
        </el-dialog>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile">
            <el-form ref="form"  label-width="120px" label-position="top">
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Nom</label>
                        <el-input placeholder="Votre nom" v-model="nom"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                <div class="grid-content bg-purple-light">
                    <label>Prénom</label>
                    <el-input placeholder="Votre prenom" v-model="prenom"></el-input>
                </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="12">
                    <div class="grid-content bg-purple">
                        <label>Numéro de téléphone</label>
                        <el-input placeholder="Numéro de téléphone" v-model="telephoneclient"></el-input>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="grid-content bg-purple-light">
                        <label>Adresse e-mail</label>
                        <el-input placeholder="votre adresse e-mail" v-model="emailclient"></el-input>
                    </div>
                </el-col>
            </el-row>

             <el-row :gutter="20" style="margin-top:15px">
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <label>Sexe</label>
                         <el-select style="width: 100%!important;" v-model="genre" placeholder="Selectionner votre sexe">
                            <el-option label="Homme" value="homme"></el-option>
                            <el-option label="Femme" value="femme"></el-option>
                            <el-option label="Entreprise" value="entreprise"></el-option>
                        </el-select>
                    </div>
                </el-col>
            </el-row>
            </el-form>
            <div style="margin-top:15px">
                 <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>
	</div>
</template>

<script>

import Api from '../../../services/Api'
import store from '../../../store'
import dayjs from 'dayjs'
import MarqueVehicule from '../../../common/marqueauto'
import Marque2roues from '../../../common/2roues'
import _ from 'underscore'
import customPassword from "../../../services/password_generator"

const validatePhone = phone => {
if(!phone.length){
  return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
}else{
  return { valid: true, error: null };
}

};

const validateEmail = email => {
if (!email.length) {
  return {valid: false, error: "L'adresse e-mail est obligatoire!"};
} else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
  return { valid: false, error: "Veuillez entrer un email valide!" };
} else {
  return { valid: true, error: null };
}
};

export default {
	name: 'Paiement_liste',
	data () {
		return {
            AddUser : false,
            nom: null,
            prenom: null,
            genre: null,
            adresse : null,
            userRole : '',
            datenaissance: null,
            profession: null,
            piece_identite: null,
            numero_piece: null,
            generatePassword : null,
            password1 : null,
            getSimulData: null,
            myCars : null,
            infosSupOfVehicle : false,
            forUserCarOrNewCar : false,
            SearchUserForDevis : false,
            loading :false,
            hidesearchClient : false,
            clientsearch : null,
            telephoneclient : null,
            emailclient : null,
            clientnonOk: false,
            clientOk: false,
            NumberInput : false,
            EmailInput : false,
            errors : {},
            shownameclient : true,
            marque : null,
            model : null,
            listMarque: [],
            listeMarque2roues: [],
            listeModeles2roues : null,
            listMarqueprime: [],
            selectedMarque: null,
            popupAutre : false,
            immatriculation :null,
            listCars : [],
            userCars : [],
            ModalListeContrat : false,
            carDetails : {},



            getVehicule: null,
            DureeMois: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            duree: null,
            // Data of ASSURAF - Data of ASSURAF - Data of ASSURAF
            reference_link:'',
            showRefLink:false,
            modalgenerationloading : false,
            isMobile :false,
            mode_paiement: null,
            mode_livraison: null,
            listdevis: [],
            assureurs: [],
            branche: null,
            modalgeneration: false,
            modalSendByMail: false,
            brancheTocheck: null,
            ThePaymentLink: null,
            ThePaymentLinkModal: false,
            ThePaymentLinkModalError: false,
            modalReductionSpeciale:false,
            DevisForReduction:null,
            CarDetailsArray:[],
            PourcentageReduction:null,
            newprime_ttc:0,
            oldPrimeTT:0,
            ToastTabSucess: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'success',
                className: 'custom-success-class'
            }, // Customized options for success toast
            ToastTabError: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'error'
            }, // Customized options for error toast
            // FIN Data of ASSURAF - Data of ASSURAF - Data of ASSURAF

			rate: 4,
			qnt: 1,
			activeTab: 'description'
		}
	},
    methods : {
        getUserRoles(){ 
            Api().get('/roles/all')
            .then(response => {
                this.listRoles = response.data.AllRoles
                for(let lr of response.data.AllRoles){
                    if(lr.name=="User"){
                        this.userRole = lr._id
                    }
                }
            }).catch(err => {console.log("failed getAllUserRoles");})
        },

        generatePassword: function () {
            this.password1 = customPassword.customPassword(this.nom, this.prenom)
        },
        //Creation d'un user avant de faire son devis
        createuser: function () {
            this.generatePassword()
            if (this.nom && this.prenom && this.emailclient && this.genre && this.telephoneclient) {
                const user = {
                    nom: this.nom,
                    prenom: this.prenom,
                    datenaissance: this.datenaissance,
                    profession: this.profession,
                    adresse: this.adresse,
                    sexe: this.genre,
                    telephone_port: this.telephoneclient,
                    email: this.emailclient,
                    password: this.password1,
                    confirmPass: this.password1,
                    role:  this.userRole,
                    createdby:store.state.user._id,
                    photo: this.file,
                    piece_identite: this.piece_identite,
                    numero_piece: this.numero_piece,
                    plateforme : 'Dash',
                    created: dayjs(new Date()).format('YYYY-MM-DD')
                }
                Api().post('user/register', user)
                        .then(resultat => {
                            this.HubspotSend(user)
                            this.MailChimpInscriptionCompte(user)
                            if (resultat.status == 200) {
                                if(resultat.data.numero){
                                    this.$message.error(resultat.data.msg, this.AlertMessage)
                                }else{
                                    this.$store.dispatch('setClient', resultat.data.data)
                                    this.usersearch = resultat.data.data
                                    this.AddUser = false
                                    this.clientnonOk = false
                                    this.hidesearchClient = false
                                    this.e1 = 0
                                }
                            } else {
                                this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                            }
                })
                .catch(err => {
                    this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                })
            } else {
                this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
            }
        },

        getCarForDevis (item){
            this.carDetails = item
            if(this.getSimulData.vehicul[0].nbplace === Number(this.carDetails.nbplace.replace(/[^0-9.-]+/g, ""))) {
                if(this.getSimulData.vehicul[0].valeur_venale != null){
                    if(this.carDetails.valeur_venale === this.getSimulData.vehicul[0].valeur_venale) {
                        this.ModalListeContrat = false
                        this.infosSupOfVehicle = true
                        this.marque = this.carDetails.marque
                        this.model = this.carDetails.model
                        this.immatriculation = this.carDetails.immatriculation
                    } else {
                        this.$message.error('La valeur actuelle du véhicule est différent de celle de la simulation !', this.AlertMessage)
                    }
                }else {
                    this.ModalListeContrat = false
                    this.infosSupOfVehicle = true
                    this.marque = this.carDetails.marque
                    this.model = this.carDetails.model
                    this.immatriculation = this.carDetails.immatriculation
                }
            } else {
                this.$message.error('Le nombre de place du véhicule est différent de celui de la simulation !', this.AlertMessage)
            }
          
        },
        getUserCars(){
            Api().post("flette/listing/", {userid: this.userCarId})
                .then(response => {
                this.listCars = response.data.cars
                });
        },
        getListVehicule(){
            if(this.myCars == 'mes_vehicule'){
                this.infosSupOfVehicle = false
                if(this.listCars.length == 0){
                    this.$message.error("Vous n'avez pas de véhicule !", this.AlertMessage)
                }else{
                this.ModalListeContrat = true
                this.userCars = this.listCars
                this.myCars = 'mes_vehicule'
                }
            }else if(this.myCars == 'nouveau') {
                this.infosSupOfVehicle = true
            }
            this.getUserCars()
        },

        validateImmatriculation() {
            const regex = /^[A-Za-z0-9]{2}-[A-Za-z0-9]{4}-[A-Za-z0-9]{2}$/;
            if (!regex.test(this.immatriculation)) {
            this.immatriculation = this.immatriculation.replace(/[^A-Za-z0-9-]/g, '');
            }
        },

        onChangeSelectedMarque: function (mark) {
            if (typeof mark == 'string') {
                if (mark == 'AUTRE') {
                    this.popupAutre = true;
                    this.marque = ''
                } else {
                    let selMarque = _.where(MarqueVehicule, {
                        'Marque': mark
                    })
                    this.listeModeles = _.uniq(selMarque, _.property('Type'))
                    this.selectedMarque = mark
                }
            }

        },

         onChangeSelectedMarque2roues: function (mark) {
            if (typeof mark == 'string') {
                if (mark == "AUTRE") {
                    this.popupAutre = true;
                    this.marque = ''
                } else {
                    let selMarque = _.where(Marque2roues, {
                        'Marque': mark
                    })
                    this.listeModeles2roues = _.uniq(selMarque, _.property('Type'))
                    this.listeModeles2roues [0] = "AUTRE"
                    this.selectedMarque = mark

                }
            } else if (typeof mark == 'object') {
                if (mark.Marque == 'AUTRE') {
                    this.popupAutre = true;
                    this.marque = ''
                } else {
                    let selMarque = _.where(Marque2roues, {
                        'Marque': mark
                    })
                    this.listeModeles2roues = _.uniq(selMarque, _.property('Type'))
                    this.listeModeles2roues[0] = "AUTRE";
                    this.selectedMarque = mark.Marque
                    this.marque = this.selectedMarque
                    this.selectedMarque = mark
                }
            }

        },

        hidesearch(){
            this.hidesearchClient = false
            this.forUserCarOrNewCar = true
        }, 
        rechercherClient() {
        if(this.telephoneclient){
          const validPhone = validatePhone(this.telephoneclient);
          this.errors.phone = validPhone.error;

          if(validPhone.valid == true){
            Api().get('/user/phone/' + this.telephoneclient)
                .then(resultat => {
                  if (resultat.data.telephone_port === this.telephoneclient) {
                    this.clientOk = true
                    this.clientnonOk = false
                    this.usersearch = resultat.data;

                    this.userCarId = this.usersearch._id

                    let useridentity = this.usersearch._id
                    this.$store.dispatch('setClient', resultat.data)

                     Api().post("flette/listing/", {userid: useridentity})
                        .then(response => {
                            this.listCars = response.data.cars
                    });
                  }

                })
                .catch(e => {
                  this.clientnonOk = true
                  this.clientOk = false
                  this.telephoneC = this.telephoneclient;
                })
          } else {
            this.$message.error('Le numéro de téléphone doit contenir 9 chiffres au minimum !', this.AlertMessage)
          }
        }else {
          this.$message.error('Numéro de téléphone obligatoire !', this.AlertMessage)
        }


      },

        rechercheClientByMail(){
            if(this.emailclient){

              const validEmail = validateEmail(this.emailclient);
              this.errors.email = validEmail.error;

              if(validEmail.valid == true){
                Api().get('/user/email/' +this.emailclient)
                    .then(resultat =>{
                      if (resultat.data.email === this.emailclient) {
                        this.clientOk = true
                        this.clientnonOk = false
                        this.usersearch = resultat.data;
                        this.userCarId = this.usersearch._id

                        let useridentity = this.usersearch._id
                        this.$store.dispatch('setClient', resultat.data)
                         Api().post("flette/listing/", {userid: useridentity})
                        .then(response => {
                            this.listCars = response.data.cars
                    });
                      }
                    }).catch(e => {
                  this.clientnonOk = true
                  this.clientOk = false
                  this.emailC = this.emailclient;
                })
              } else {
                this.$message.error('Veuillez entrer un email valide !', this.AlertMessage)
              }
            }else {
            this.$message.error('Adresse e-mail obligatoire !', this.AlertMessage)

            }
        },

        check_client_search(){
            if(this.clientsearch == 'téléphone'){
                this.NumberInput = true 
                this.EmailInput = false
                this.emailclient = null
                this.clientOk = false
                this.clientnonOk = false
            } else if(this.clientsearch == 'email'){
                this.NumberInput = false 
                this.EmailInput = true
                this.telephoneclient = null
                this.clientOk = false
                this.clientnonOk = false
            }
        },

        getPrimeSimulate(){
            let item = this.listdevis[0]
            // let assureurs = 

            if(this.duree) {
                this.dateEffet =item.dateEffet;
                let inter = dayjs(this.dateEffet).add(this.duree, 'month').format('YYYY-MM-DD');
                item.dateEcheance = dayjs(inter).add(-1, 'day').format('YYYY-MM-DD')
                let vehicul = []
                let partenaires = []

                for(let assur of this.assureurs){
                    partenaires.push(assur.societe)
                }



                let vehicule = {
                    categorie   : item.vehicul[0].categorie,
                    tonnage     : this.tonnage,
                    optionUtilitaire  : null,
                    volumetrie2_roues : null,
                    optionPersoTrans  : item.vehicul[0].optionPersoTrans,
                    carburation     : item.vehicul[0].carburation,
                    marque          : null,
                    carosserie      : null,
                    model           : null,
                    force           : item.vehicul[0].force,
                    dateCircule     : dayjs(item.vehicul[0].dateCircule).format('YYYY-MM-DD') ,
                    age             : item.vehicul[0].age,
                    annee_mois      : item.vehicul[0].annee_mois,
                    nbplace         : item.vehicul[0].nbplace,
                    valeur          : item.vehicul[0].valeur,
                    valeur_venale   : item.vehicul[0].valeur_venale,
                    immatriculation : null,
                    flotte_mono     : 'mono',
                    garanties : item.vehicul[0].garanties
                }

                vehicul.push(vehicule)



                let OneautoToSimule = {
                dateEffet: item.dateEffet,
                dateEcheance: item.dateEcheance,
                createur: this.$store.state.user._id,
                branche: "Automobile",
                duree: this.duree,
                dateSouscription: dayjs(new Date()).format('YYYY-MM-DD'),
                vehicule: vehicul,
                assureurs: partenaires,
                plateforme : 'Dash',
            }
            this.loading = true

            Api().post('simulator/add', OneautoToSimule)
                .then(result => {
                    if (result.data.message) {
                        let data = {
                            ...result.data,
                            duree: this.duree,
                        }
                        
                        this.$store.dispatch('setDevis', data)
                        setTimeout(() => (
                                this.loading = false,
                                this.$router.go(this.$router.push({name: 'liste_devis_simulation'}))
                            ), 4000);
                    }
                })
            }else {
                this.$message.error('La durée est obligatoire !', this.AlertMessage)
            }
        }, 

        getVehiculeInfo() {
            for(let i of this.listdevis) {
                for(let info of i.vehicul){
                    this.getVehicule = info
                }
            }
        },

        groupedLinkByMail(params)
            {
                const  data={
                    reference:params.reference,
                    userid:params.userid._id,
                    userid: params.userid,
                    brancheid: params.brancheid,
                }
                
                Api().post("/devis/reference/bymail",data)
                .then((result) => {
                    this.reference_link = result.data.link,
                    this.showRefLink = true
                    if(result.data.message=='OK')
                    {
                        this.$message.success("Le lien a bien été envyé par mail !", this.AlertMessage)
                    }else{
                        this.$message.error("L'envoi du lien a échouée !" , this.AlertMessage)
                    }
                }).catch((err) => {

                });
        },

        AlertMessage() {
            this.$message({
                showClose: false,
                duration : 5000
            });
        },

        init() {
            if (window.innerWidth <= 768) this.isMobile = true
        },

        go_express_payment(contrat){
            localStorage.setItem("contrat_for_express_payment", contrat.police)
             this.$router.go(this.$router.push({name: 'paiement_cash' }))
        },

        ReductionCalculation(gar)
        {
            let Reduction = (gar.prime * gar.PourcentageReduction)/100
            gar.primeAfterReduction = gar.prime - Reduction
           
            const NewDetails = this.DevisForReduction.vehicul
            let newPrimeNette = 0
            
            for(let v of NewDetails)
            {
                for(let det of v.Detail_Prime)
                {
                    if(det.primeAfterReduction >0)
                    {
                        newPrimeNette = newPrimeNette + det.primeAfterReduction
                    }else
                    {
                        newPrimeNette = newPrimeNette + det.prime
                    }
                }
            }
           
            let Newtaxe =  Math.ceil(((newPrimeNette + this.DevisForReduction.accessoires)*10)/100)
            this.newprime_ttc = newPrimeNette + Newtaxe + this.DevisForReduction.fga +this.DevisForReduction.accessoires 
        },

        //Special reduction Pop-up loader function
        PopupSpecialReductionLoading(devis){
            this.oldPrimeTT = devis.tarif
            this.DevisForReduction = devis
            this.CarDetailsArray = this.DevisForReduction.vehicul
            this.modalReductionSpeciale = true
        },
        getSimuleToDevis(devis){
            this.SearchUserForDevis = true
            this.hidesearchClient = true
            this.getSimulData = devis
        },

        passage_devis () {    
            this.getSimulData.vehicul[0].marque = this.marque,
            this.getSimulData.vehicul[0].model = this.model,
            this.getSimulData.vehicul[0].immatriculation = this.immatriculation,
            this.getSimulData.userid = this.usersearch._id

            let OneautoToSave = {
                userid: this.usersearch._id,
                proprietaire: {
                    prenom: this.usersearch.prenom,
                    nom: this.usersearch.nom
                },
                dateEffet: this.getSimulData.dateEffet,
                dateEcheance: this.getSimulData.dateEcheance,
                createur: this.$store.state.user._id,
                branche: this.getSimulData.branche,
                duree: this.$store.state.devis.duree,
                dateSouscription: dayjs(new Date()).format('YYYY-MM-DD'),
                vehicule: this.getSimulData.vehicul,
                assureurs: [this.getSimulData.nom],
                plateforme : 'Dash',
            }  

            this.modalgenerationloading = true

            Api().post('devis/add', OneautoToSave)
                .then(response => {
                    if(response.data.message) {
                        this.$store.dispatch('setDevis', response.data)
                        setTimeout(() => (
                                    this.modalgenerationloading = false,
                                    this.$router.go(this.$router.push({
                                    name: 'liste_devis_paiement'
                                }))), 4000
                                );
                    }   

                   
                })
        },

        // Fonction de download du devis en fichier pdf 
        Downloa_devis (devis) {
            this.modalgenerationloading = true;
            Api().get('devis/download/' + devis.id)
                .then(response => {
                    let url = response.data.lien
                    let WinOpener = null
                    setTimeout(() => (
                        this.modalgenerationloading = false,  
                        WinOpener = window.open(),
                        WinOpener.location = url
                    ), 7000);
                })
        },

        // Fonction d'envois du devis par email
        devisParMail (params) {
            Api().get('devis/mydevisbyemail/' + params.id)
                .then(response => {
                    if (response.data.info) {
                        //this.modalSendByMail = true
                        this.$message.success("Votre devis vous a été envoyé par mail !", this.AlertMessage)
                    }
                })
        },

        PaymentLink: function (params) {
            let ObjetToSend = {
                userid: params.userid,
                devisid: params.id,
                brancheid: params.brancheid,
                partenaireid: params.partenaireid,
            }
            Api().get('/paymentLink/devis/' + params.id)
                .then(resp => {
                    if (resp.data.link.length == 1) {
                        this.ThePaymentLinkModal = true
                        this.ThePaymentLink = resp.data.link[0].contractLink
                    } else {
                        Api().post('/paymentLink/', ObjetToSend)
                            .then(response => {
                                if (response.data.message == 'OK') {
                                    this.$message.success("Un lien de paiement vous a été envoyé par mail !", this.AlertMessage)
                                    this.ThePaymentLink = response.data.link
                                }
                            })
                    }
                })
                .catch(e => {
                    this.ThePaymentLinkModalError = true
                    this.$message.error("Erreur de connexion, veuillez réessayer !", this.AlertMessage)
                })

        },

        //Update devis after Special Reduction
        UpdateDevisAfetrSpecialredution:function(){
            const NewDetails = this.DevisForReduction.vehicul
            let newPrimeNette = 0
            
            for(let v of NewDetails)
            {
                for(let det of v.Detail_Prime)
                {
                    if(det.primeAfterReduction)
                    {
                        newPrimeNette = newPrimeNette + det.primeAfterReduction
                        det.prime = det.primeAfterReduction
                    }else
                    {
                        newPrimeNette = newPrimeNette + det.prime
                        det.prime = det.prime
                    }
                }
            }
           
            let Newtaxe =  Math.ceil(((newPrimeNette + this.DevisForReduction.accessoires)*10)/100)
            this.newprime_ttc = newPrimeNette + Newtaxe + this.DevisForReduction.fga +this.DevisForReduction.accessoires            
            
            const DevisForUpdate = {
                id: this.DevisForReduction.id,
                primeTTC: this.newprime_ttc,
                primeNette: newPrimeNette,
                taxe: Newtaxe,
                vehicules: this.DevisForReduction.vehicul
            }
            Api().post('/devis/auto/special/reduction', DevisForUpdate)
            .then((result) => {
                if(result.data.status)
                {
                    this.$toasted.show(result.data.msg, this.ToastTabSucess)
                    setTimeout(() => (
                        this.$router.go(this.$router.push({  name: 'listdevis' })),
                        window.open(url)), 1000);
                }else{
                    this.$toasted.show(result.data.msg, this.ToastTabError)
                }
            }).catch((err) => {
                 console.log(err)
            });

        }
    },
    watch: {
        marque (val) {
            this.onChangeSelectedMarque(val)
            this.onChangeSelectedMarque2roues(val)
        },
    },
    beforeDestroy () {
        localStorage.removeItem('PaymentLink')
    },
	mounted() {
        this.init()
         if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
            this.getUserRoles()
            let dernierDevis = []
            if (this.$store.state.devis) {
                dernierDevis        = this.$store.state.devis.result.devis
                this.assureurs      = this.$store.state.devis.result.partenaire
                let branche         = this.$store.state.devis.result.branche
                this.brancheTocheck = this.$store.state.devis.result.branche.branche

                if (branche.branche == 'Habitation') { // Test if Branche==Habitation

                    for (let ass of this.assureurs) {
                        for (let dev of dernierDevis) {
                            if (ass._id == dev.partenaireid) {
                                this.listdevis.push({
                                    id: dev._id,
                                    image: ass.logo,
                                    userid: dev.userid,
                                    police:dev.numeroDevis,
                                    brancheid: dev.brancheid,
                                    branche:'Habitation',
                                    partenaireid: ass._id,
                                    nom: ass.societe,
                                    tarif: dev.prime_ttc,
                                    prime_nette: dev.prime_nette,
                                    dateEffet: dev.dateEffet,
                                    dateEcheance: dev.dateEcheance,
                                    typeBien: dev.typeBien,
                                    naturecouverture: dev.naturecouverture,
                                    valeurbatiment: dev.valeurbatiment,
                                    valeurcontenu: dev.valeurcontenu,
                                    qualiteassure: dev.qualiteassure,
                                    nombrepiece: dev.nombrepiece,
                                    nombreetage: dev.nombreetage,
                                    garanties: dev.garanties,
                                    optionAss: ['Assistance', 'Remplacement', 'Orientation sinistre'],
                                    reference:dev.reference
                                })
                            }
                        }
                    }
                } else if (branche.branche == 'Voyage') { // Dans le cas de voyage on cree un tableau avec des libelles pour voyage
                    for (let ass of this.assureurs) {
                        for (let dev of dernierDevis) {
                            if (ass._id == dev.partenaireid) {
                                this.listdevis.push({
                                    id: dev._id,
                                    image: ass.logo,
                                    police:dev.numeroDevis,
                                    userid: dev.userid,
                                    brancheid: dev.brancheid,
                                    branche:'Voyage',
                                    partenaireid: ass._id,
                                    nom: ass.societe,
                                    tarif: dev.prime_ttc,
                                    prime_nette: dev.prime_nette,
                                    dateEffet: dev.dateEffet,
                                    dateEcheance: dev.dateEcheance,
                                    nbpassager: dev.nbpassager,
                                    pays_domicile: dev.vpays_domicile,
                                    destination: dev.destination,
                                    garanties: dev.garanties,
                                    optionAss: ['Assistance', 'Remplacement', 'Orientation sinistre'],
                                    reference:dev.reference
                                    // Autres completer a la suite
                                })
                            }
                        }
                    }
                } else if (branche.branche == 'Automobile') { 
                    this.listMarque = _.uniq(MarqueVehicule, _.property('Marque'))
                    this.listeModeles = _.uniq(MarqueVehicule, _.property('Type'))
                    this.listeMarque2roues = _.uniq(Marque2roues, _.property(('Marque')))
                    this.listeModeles2roues = _.uniq(Marque2roues, _.property('Type'))// Dans le cas de voyage on cree un tableau avec des libelles pour Auto
                    for (let ass of this.assureurs) {
                        for (let dev of dernierDevis) {
                            if (ass._id.toString() == dev.partenaireid.toString()) {
                                this.listdevis.push({
                                    id: dev._id,
                                    image: ass.logo,
                                    police:dev.numeroDevis,
                                    userid: dev.userid,
                                    brancheid: dev.brancheid,
                                    branche:'Automobile',
                                    partenaireid: ass._id,
                                    nom: ass.societe,
                                    tarif: dev.prime_ttc,
                                    prime_nette: dev.prime_nette,
                                    taxe:dev.taxe,
                                    fga:dev.fga,
                                    accessoires:dev.accessoires,
                                    dateEffet: dev.dateEffet,
                                    dateEcheance: dev.dateEcheance,
                                    vehicul: dev.vehicules,
                                    garanties: dev.garanties,
                                    reference:dev.reference
                                })

                            }
                        }
                    }
                } else if(branche.branche == 'Santé') {

for (let ass of this.assureurs)
{
    for (let dev of dernierDevis)
    {
        if (ass._id == dev.partenaireid)
        {

            this.listdevis.push({
                id: dev._id,
                numero: dev.numeroDevis,
                image: ass.logo,
                nom: ass.societe,
                branche:'Santé',
                tarif: dev.prime_ttc,
                prime_nette: dev.prime_nette,
                dateEffet: dev.dateEffet,
                dateEcheance: dev.dateEcheance,

                
            })
        }
    }
}
}

this.getVehiculeInfo()

            }

        } else {
            this.$router.go(this.$router.push('/login'))
        }
	}
}
</script>

<style lang="scss">
@import '../../../assets/scss/_variables';

.assuraf-orange {
    color: #ffa409!important
}
.mobile-btn{
    width:180px;
    margin-top:5px;
}
.page-ecommerce-product-detail {
	.item-box {
		border-radius: 4px;
		overflow: hidden;
        background : #fff;

		.main-photo {
			position: relative; 
			overflow: hidden;
			background: white;
			padding: 30px;

			img {
				width: 100%;
			}
		}
		.detail-box {
			padding: 30px;
			position: relative; 

			.title {
				margin: 0;
			}

			.price-box {
				margin-top: 20px;
				margin-bottom: 30px;

				.discounted-price {
					color: $text-color-accent;
					font-weight: bold;
					font-size: 25px;
					margin-right: 20px;
					display: inline-block;
				}
				.normal-price {
					opacity: .5;
					text-decoration: line-through;
					text-decoration-color: $text-color-accent;
					margin-right: 10px;
					display: inline-block;
				}
				.discount {
					color: $text-color-accent;
					display: inline-block;
				}
			}

			.actions-box {
				margin-top: 30px;

				.el-input-number {
					width: 100px;
			
					.el-input__inner {
						color: $text-color-accent;
						background-color: transparent;
						border-color: $text-color-accent;
						font-family: inherit;
						font-weight: bold;
						padding-left: 5px;
						padding-right: 45px;
					}
				}

				.el-button {
					font-family: inherit;
					margin-left: 0;
				}
			}
		}
	}
}

</style>


