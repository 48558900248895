<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Module de gestions 
        <span v-if="encaissementStep" style="color:#ffa409">Bordereaux des encaissements</span>
        <span v-if="versementStep" style="color:#ffa409">Bordereaux des reversement</span>
        <span v-if="commissionStep" style="color:#ffa409">Bordereaux des commissions</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
      
    </div>
    <div class="selector">
      <div class="selecotr-item">
          <input @change="showEncaissementComponent()" v-model="choix" value="encaisement" type="radio" id="encaissement" name="selector" class="selector-item_radio" checked>
          <label for="encaissement" class="selector-item_label">Bordereaux des Encaissements</label>
      </div>
      <div class="selecotr-item">
          <input @change="showReversementComponent()" v-model="choix" value="reversement" type="radio" id="reversement" name="selector" class="selector-item_radio">
          <label for="reversement" class="selector-item_label">Bordereaux des Reversement</label>
      </div>
      <div class="selecotr-item">
          <input @change="showCommisionComponent()" v-model="choix" value="commission" type="radio" id="commissions" name="selector" class="selector-item_radio">
          <label for="commissions" class="selector-item_label">Bordereaux des Commissions</label>
      </div>
  </div>
   
    <resize-observer @notify="__resizeHanlder" />
      <div style="margin-top: 20px; height: auto;">
              <transition name="el-zoom-in-top">
                  <versementComponent v-if="versementStep"></versementComponent>
                  <encaisementComponent v-if="encaissementStep"></encaisementComponent>
                  <commissionComponent v-if="commissionStep"></commissionComponent>
              </transition>
          
      </div>
   
    
  </div>
</template>

<script>


import _ from "lodash"
//debut importation assuraf
import dayjs from 'dayjs'
import encaisementComponent from "./encaissementComponent.vue";
import versementComponent from "./versementComponent.vue";
import commissionComponent from "./commissionComponent.vue";
//fin importation assuraf

export default {
  name: "bordereaux",
  components: {
      encaisementComponent,
      versementComponent,
      commissionComponent,
  },
  data() {
    return {
      commissionStep: false,
      encaissementStep: true,
      versementStep: false,
      choix : 'encaisement',
      striped: true,
      text: "LAODING BORDERAUX",
      currentTab: "Encaisement",
      tabs: [
          {
          key: "Encaisement",
          value: "Bordereaux des Encaissements",
          class: "box bg-primary",
          icon: "fas fa-money-bill-alt",
          variant: "bg-primary",
          },
          {
          key: "Versement",
          value: "Bordereaux des Reversements",
          class: "box  bg-primary",
          icon: "fa fa-handshake-o float-right",
          variant: "bg-versement",
          },
          {
          key: "Commission",
          value: "Bordereaux des Commissions",
          class: "box bg-primary",
          icon: "fas fa-box",
          variant: "bg-commission",
          },
      ],








   
      modalInfo: {
        title: '',
        content: ''
      },
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      modalDelete: false,
      dismissCountDown: 0,
    
      filter: null,
      showuser: false,
      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
    }
  },
  computed: {
      currentTabComponent() {
          return this.currentTab.toLowerCase() + "Component";
      }
  },
  watch: {
    isBusy(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs["my-table"].$el;
        if (newVal === true) {
          tableScrollBody.classList.add("overflow-hidden");
        } else {
          tableScrollBody.classList.remove("overflow-hidden");
        }
      }
    },

    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    showEncaissementComponent(){
      if(this.choix = 'encaisement'){
        this.encaissementStep = true;
        this.commissionStep = false;
        this.versementStep = false;
      }
    },
    showCommisionComponent(){
      if(this.choix = 'commission'){
        this.encaissementStep = false;
        this.commissionStep = true;
        this.versementStep = false;
      }
    },
    showReversementComponent(){
      if(this.choix = 'reversement'){
        this.encaissementStep = false;
        this.commissionStep = false;
        this.versementStep = true;
      }
    },

    AlertMessage() {
      this.$message({
        showClose: false,
        duration : 5000
      });
    },

    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },

   
    handleResize: _.throttle(function(e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #409EFF;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}

.selector{
  position:relative;
  width:100%;
  background-color:#F3F7FA;
  height:60px;
  display:flex;
  justify-content:space-around;
  align-items:center;
  border-radius:9999px;
  box-shadow:0 0 16px rgba(0,0,0,.0);
}
.selecotr-item{
  position:relative;
  flex-basis:calc(95% / 3);
  //height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
}
.selector-item_radio{
  appearance:none;
  display:none;
}
.selector-item_label{
  position:relative;
  height:80%;
  width:100%;
  text-align:center;
  border-radius:9999px;
  line-height:300%;
  font-weight:600;
  transition-duration:.5s;
  transition-property:transform, color, box-shadow;
  transform:none;
  cursor: pointer;
}
.selector-item_radio:checked + .selector-item_label{
  background-color:#ffa409;
  color:#fff;
  box-shadow:0 0 4px rgba(0,0,0,.0),0 2px 4px rgba(0,0,0,.3);
  transform:translateY(-2px);
}
@media (max-width:480px) {
.selector{
  width: 90%;
}
}










.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {
      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }
          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
