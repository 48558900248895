<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
      <div class="page-header">
        <h1>Liste des <span style="color:#ffa409">sinistres</span>
        </h1>
      </div>
  
      <div class="toolbar-box flex align-center" v-if="!isMobile">
        <div class="box grow">
          <el-input
              placeholder="Rechercher sinistre..."
              prefix-icon="el-icon-search"
              v-model="search"
              clearable
          >
          </el-input>
        </div>
        <div class="box grow">
          <!--        A ne pas supprimer-->
        </div>
      </div>
  
      <div v-if="isMobile" style="margin-bottom:15px">
        <el-row :gutter="15">
          <el-col :span="24" style="margin-top:15px">
            <el-input
              placeholder="Rechercher sinistre..."
              prefix-icon="el-icon-search"
              v-model="search"
              clearable>
          </el-input>
          </el-col>
        </el-row>
      </div>
  
      <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>
  
      <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
        <el-table
            :row-class-name="tableRowClassName"
            :data="listInPage"
            style="width: 100%"
            :height="height"
            v-if="ready"
        >
          <el-table-column label="Date création" min-width="150" fixed prop="created">
            <template slot-scope ="scope">
              <span class="date">{{ DateFormater(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Branche" min-width="150" prop="branche"></el-table-column>
          <el-table-column label="Etat Sinistre" min-width="180" prop="etat"></el-table-column>
          <el-table-column label="Assureur" min-width="200" prop="assureur"></el-table-column>
          <el-table-column label="Police Contrat" min-width="200" prop="contrat.numero_police"></el-table-column>
          <el-table-column label="Client" min-width="200" prop="objet_assistance">
            <template slot-scope ="scope">
              <span class="date">{{ scope.row.userid.prenom }} - {{scope.row.userid.nom}}</span>
            </template>
          </el-table-column>
          <el-table-column label="PV Constat" min-width="200" prop="objet_assistance">
            <template slot-scope ="scope">
              <span class="date"><a :href="scope.row.document_pv" v-if="scope.row.document_pv" target="_blank">Télécharger</a></span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Actions" prop="action" min-width="80">
            <template slot-scope="scope">
              <el-button size="mini" @click="detaildevis(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            </template>
          </el-table-column>
        </el-table>
  
        <el-pagination
            v-if="ready"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="pagination.layout"
            :total="total"
        ></el-pagination>
      </div>
  
      <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
        <el-table
            :row-class-name="tableRowClassName"
            :data="listInPage"
            style="width: 100%"
            v-if="ready">
          <el-table-column label="Date création" min-width="150" prop="created">
            <template slot-scope ="scope">
              <span class="date">{{ DateFormater(scope.row.created) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Branche" min-width="150" prop="branche"></el-table-column>
          <el-table-column label="Etat Sinistre" min-width="180" prop="etat"></el-table-column>
          <el-table-column label="Assureur" min-width="200" prop="assureur"></el-table-column>
          <el-table-column label="Police Contrat" min-width="200" prop="contrat.numero_police"></el-table-column>
          <el-table-column label="Client" min-width="200" prop="objet_assistance">
            <template slot-scope ="scope">
              <span class="date">{{ scope.row.userid.prenom }} - {{scope.row.userid.nom}}</span>
            </template>
          </el-table-column>
          <el-table-column label="PV Constat" min-width="200" prop="objet_assistance">
            <template slot-scope ="scope">
              <span class="date"><a :href="scope.row.document_pv" v-if="scope.row.document_pv" target="_blank">Télécharger</a></span>
            </template>
          </el-table-column>
          <el-table-column label="Actions" prop="action" min-width="80">
            <template slot-scope="scope">
              <el-button size="mini" @click="detaildevis(scope.row)" type="primary" icon="el-icon-view" circle></el-button>
            </template>
          </el-table-column>
        </el-table>
  
        <el-pagination
            v-if="ready"
            :small="pagination.small"
            :current-page.sync="pagination.page"
            :page-sizes="pagination.sizes"
            :page-size.sync="pagination.size"
            :layout="pagination.layout"
            :total="total"
        ></el-pagination>
      </div>
  
      <el-dialog width="70%" title="Détails du sinistre" :visible.sync="showdevis" v-if="showdevis && !isMobile">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div slot="header" class="clearfix" v-if="detailsDevis.etat=='Résolu'">
                  <span>Résolu</span>
                </div>
  
                <div slot="header" class="clearfix" v-if="detailsDevis.etat=='Pris en compte'">
                  <span>Pris en compte</span>
                </div>
  
                <div slot="header" class="clearfix" v-if="detailsDevis.etat=='Non pris en compte'">
                  <span>Non pris en compte</span>
                </div>
  
                <div v-if="this.$store.state.user.role=='Admin'">
                  <div slot="header" class="clearfix" v-if="detailsDevis.vueAssureur">
                    <span>{{ detailsDevis.vueAssureur }}</span>
                  </div>
  
                  <div slot="header" class="clearfix">
                    <span>Pas encore vu par l'assureur</span>
                  </div>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
  
        <el-row :gutter="15" style="margin-top:15px">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div  >
                  <ul style="list-style:none">
                    <li>Nom : <span class="to-right assuraf-orange">{{detailsDevis.userid.nom}}</span></li>
                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsDevis.userid.prenom}}</span></li>
                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsDevis.userid.adresse}}</span></li>
                    <li>profession : <span class="to-right assuraf-orange">{{detailsDevis.userid.profession}}</span></li>
                    <li>Date d'inscription : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.userid.created)}}</span></li>
                    <li>Assuré chez : <span class="to-right assuraf-orange">{{detailsDevis.assureur}}</span></li>
                    <li>Branche assurance : <span class="to-right assuraf-orange">{{detailsDevis.branche}}</span></li>
                  </ul>
                </div>
                <div style="text-align: center!important;">
                  <img v-if="detailsDevis.userid.photo" class="justify-center" :src="detailsDevis.userid.photo" width="100" alt="photo de profil">
                  <img v-else src="../../../assets/images/logo_assuraf.png" width="100" alt="">
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div>
                  <ul style="list-style:none">
                    <li>Police assurance : <span class="to-right assuraf-orange">{{detailsDevis.contrat.numero_police}}</span></li>
                    <li>Date création : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.created)}}</span></li>
                    <li>Date de survenance : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateSurvenance)}}</span></li>
                    <li>Heure de survenance : <span class="to-right assuraf-orange">{{detailsDevis.heureSurvenance}}</span></li>
                    <li>Constat établi par : <span class="to-right assuraf-orange">{{detailsDevis.mandat}}</span></li>
                    <li v-for="item in detailsDevis.dommage" :key="item">Dommages : <span class="to-right assuraf-orange">{{item}}</span></li>
                    <li>Circonstance Sinistres : <span class="to-right assuraf-orange">{{detailsDevis.Circonstance_Sinistres}}</span></li>
                    <li>Détails Dommage : <span class="to-right assuraf-orange">{{detailsDevis.Details_Dommages}}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
  
        <el-row :gutter="15" style="margin-top: 15px" v-if="detailsDevis.photos.length> 0">
           <el-col :span="24">
              <label>Photo du sinistre</label>
              <el-card class="box-card">
                <el-row :gutter="20" type="flex" justify="center">
                  <el-col :span="6" v-for="(pic, index) in detailsDevis.photos" :key="index">
                      <img class="border" :src="pic" :alt="pic" style="width:100%"  @click="VisualizeSinistrePicture(pic)">
                  </el-col>
                </el-row>
              </el-card>
          </el-col>
        </el-row>
  
        <div class="mt-5">
          <el-button v-loading.fullscreen.lock="modalgenerationloading" type="primary" round @click="ToPdf(detailsDevis)">Télécharger le PDF</el-button>
          <el-button v-if="detailsDevis.etat =='Non pris en compte'" type="success" round @click="SetSinistreToResolu(detailsDevis._id)">Prendre en compte</el-button>
          <el-button v-if="detailsDevis.etat =='Pris en compte' || detailsDevis.etat == 'Résolu'" type="primary" round @click="SetSinistreToNonResolu(detailsDevis._id)">Mettre en Non pris en compte</el-button>
          <el-button v-if="detailsDevis.etat =='Pris en compte'" type="success" round @click="ResoudreSinistre(detailsDevis._id)">Mettre en Résolu</el-button>
        </div>
      </el-dialog>
  
      <el-dialog width="90%" title="Détails du sinistre" :visible.sync="showdevis" v-if="showdevis && isMobile">
        <el-row :gutter="15">
          <el-col :span="24">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div slot="header" class="clearfix" v-if="detailsDevis.etat=='Résolu'">
                  <span>Résolu</span>
                </div>
  
                <div slot="header" class="clearfix" v-if="detailsDevis.etat=='Pris en compte'">
                  <span>Pris en compte</span>
                </div>
  
                <div slot="header" class="clearfix" v-if="detailsDevis.etat=='Non pris en compte'">
                  <span>Non pris en compte</span>
                </div>
  
                <div v-if="this.$store.state.user.role=='Admin'">
                  <div slot="header" class="clearfix" v-if="detailsDevis.vueAssureur">
                    <span>{{ detailsDevis.vueAssureur }}</span>
                  </div>
  
                  <div slot="header" class="clearfix">
                    <span>Pas encore vu par l'assureur</span>
                  </div>
                </div>
              </el-card>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div style="text-align: center!important;">
                  <img class="justify-center" :src="detailsDevis.userid.photo" width="100" alt="photo de profil">
                </div>
                <div  >
                  <ul style="list-style:none">
                    <li>Nom : <span class="to-right assuraf-orange">{{detailsDevis.userid.nom}}</span></li>
                    <li>Prénom : <span class="to-right assuraf-orange">{{detailsDevis.userid.prenom}}</span></li>
                    <li>Adresse : <span class="to-right assuraf-orange">{{detailsDevis.userid.adresse}}</span></li>
                    <li>profession : <span class="to-right assuraf-orange">{{detailsDevis.userid.profession}}</span></li>
                    <li>Date d'inscription : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.userid.created)}}</span></li>
                    <li>Assuré chez : <span class="to-right assuraf-orange">{{detailsDevis.assureur}}</span></li>
                    <li>Branche assurance : <span class="to-right assuraf-orange">{{detailsDevis.branche}}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top:15px">
            <div class="grid-content bg-purple">
              <el-card class="box-card">
                <div  >
                  <ul style="list-style:none">
                    <li>Police assurance : <span class="to-right assuraf-orange">{{detailsDevis.contrat.numero_police}}</span></li>
                    <li>Date création : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.created)}}</span></li>
                    <li>Date de survenance : <span class="to-right assuraf-orange">{{DateFormater(detailsDevis.dateSurvenance)}}</span></li>
                    <li>Heure de survenance : <span class="to-right assuraf-orange">{{detailsDevis.heureSurvenance}}</span></li>
                    <li>Constat établi par : <span class="to-right assuraf-orange">{{detailsDevis.mandat}}</span></li>
                    <li v-for="item in detailsDevis.dommage" :key="item">Dommages : <span class="to-right assuraf-orange">{{item}}</span></li>
                    <p></p>
                    <li>  Circonstance Sinistres :  <span class="to-right assuraf-orange">{{detailsDevis.Circonstance_Sinistres}}</span></li>
                    <p></p>
                    <p></p>
                    <li> Détails Dommage : <span class="to-right assuraf-orange">{{detailsDevis.Details_Dommages}}</span></li>
                  </ul>
                </div>
              </el-card>
            </div>
          </el-col>
           <el-col :span="24" style="margin-top:15px" v-if="detailsDevis.photos.length> 0">
              <el-card class="box-card mt-2"  v-for="(pic, index) in detailsDevis.photos" :key="index">
                <el-row type="flex" justify="center">
                    <img class="border" :src="pic" :alt="pic" style="width:100%"  @click="VisualizeSinistrePicture(pic)">
                </el-row>
              </el-card>
          </el-col>
        </el-row>
  
        <div class="mt-5">
          <el-button v-loading.fullscreen.lock="modalgenerationloading" type="primary" round @click="ToPdf(detailsDevis)">Télécharger le PDF</el-button>
        </div>
        <div class="mt-5">
          <el-button v-if="detailsDevis.etat =='Non pris en compte'" type="success" round @click="SetSinistreToResolu(detailsDevis._id)">Prendre en compte</el-button>
        </div>
        <div class="mt-5">
          <el-button v-if="detailsDevis.etat =='Pris en compte' || detailsDevis.etat == 'Résolu'" type="primary" round @click="SetSinistreToNonResolu(detailsDevis._id)">Mettre en Non pris en compte</el-button>
        </div>
        <div class="mt-5">
          <el-button v-if="detailsDevis.etat =='Pris en compte'" type="success" round @click="ResoudreSinistre(detailsDevis._id)">Mettre en Résolu</el-button>
        </div>
      </el-dialog>
  
      <el-dialog :visible.sync="Detail_Image_Sinistre">
       <el-row type="flex" justify="center">
          <el-col >
            <img :src="pictureForDetails" alt="" width="100%" srcset="" class="border">
          </el-col>
        </el-row>
      </el-dialog>
    </div>
  </template>
  
  <script>
  
  
  import _ from "lodash"
  import moment from "moment-timezone"
  
  //debut importation assuraf
  import dayjs from 'dayjs'
  import Api from '../../../services/Api'
  import store from '../../../store'
  //fin importation assuraf
  
  export default {
    name: "StyledTablePage",
    data() {
      return {
        Detail_Image_Sinistre : false,
        pictureForDetails:null,
        listSinistre: [],
        modalgenerationloading : false,
        optionsdate: {
          format: 'YYYY/MM/DD',
          useCurrent: false,
        },
        ldevis: [],
        du: null,
        au: null,
        listdevis: [],
        modalgeneration: false,
        detailsDevis: [],
        showdevis: false,
        pageOptions: [5, 10, 15],
        modalInfo: {
          title: '',
          content: ''
        },
        userflette:[],
        fletteRows:null,
        editUser : false,
        Selectedbranche:null,
        selectedAssureur:[],
        username: null,
        data: {},
        html: '',
        Mycounter: 0,
        dismissSecs: 3,
        totalRows: null,
        perPage: 8,
        UpdateOk: false,
        deleteConfirmation: false,
        modalDelete: false,
        dismissCountDown: 0,
        detailsUser: [],
        listuser: null,
        filter: null,
        showuser: false,
        voidField: false,
        isMobile: false,
        ready: false,
        width: 0,
        height: "auto",
        loading: false,
        search: "",
        pagination: {
          page: 1,
          size: 100,
          sizes: [10, 15, 20, 30, 50, 100],
          layout: "total, ->, prev, pager, next, jumper, sizes",
          small: false
        },
        list:  this.listSinistre,//users,
        editMode: false,
        itemsChecked: [],
        dialogUserVisible: false,
        currentId: 0,
        moment
      }
    },
    computed: {
      listFiltered() {
        return this.listSinistre.filter(obj => {
          let ctrl = false
          for (let k in obj) {
            if (
                obj[k] &&
                obj[k]
                    .toString()
                    .toLowerCase()
                    .indexOf(this.search.toLowerCase()) !== -1
            )
              ctrl = true
          }
          return ctrl
        })
      },
    
      listSortered() {
        let prop = this.sortingProp
        let order = this.sortingOrder
        return [].concat(
            this.listFiltered.sort((item1, item2) => {
              let val1 = ""
              let val2 = ""
  
              val1 = item1[prop]
              val2 = item2[prop]
              if (order === "descending") {
                return val2 < val1 ? -1 : 1
              }
              return val1 < val2 ? -1 : 1
            })
        )
      },
  
      listInPage() {
        let from = (this.currentPage - 1) * this.itemPerPage
        let to = from + this.itemPerPage * 1
        return this.listFiltered.slice(from, to)
      },
     
     total() {
        return this.listFiltered.length
      },
  
      currentPage: {
        get() {
          return this.pagination.page
        },
        set(val) {
          this.pagination.page = val
        }
      },
  
      itemPerPage() {
        return this.pagination.size
      },
      selectedItems() {
        return this.itemsChecked.length || 0
      }
    },
    watch: {
      itemPerPage(val) {
        this.ready = false
        this.currentPage = 1
  
        setTimeout(() => {
          this.ready = true
        }, 500)
      },
      search(val) {
        this.currentPage = 1
      }
    },
    methods: {
      exportToExcel(){
          //API de genereation donne en excel
        },
      tableRowClassName({row, rowIndex}) {
          if(!row) return 
          if(row.vueAdmin === false) return 'warning-row'
      },
      
      DateFormater: function (TheDate) {
        if (TheDate) {
          return dayjs(TheDate).format('DD-MM-YYYY')
        }
      },
  
      detaildevis(item) {
        if(this.$store.state.user.role=='agent général' || this.$store.state.user.role=='Assureur'){
          Api().get('sinistre/tovue/'+item._id)
              .then(response=>{
                this.detailsDevis = item
                this.showdevis = true
                this.getSinistre()
              })
              .catch(err=>{
                this.detailsDevis = item
                this.showdevis = true
                this.getSinistre()
              })
        }
        if (this.$store.state.user.role=='Admin' || this.$store.state.user.role=='SuperAdmin') {
          Api().get('/sinistre/update/vueadmin/'+item._id)
              .then(response=>{
                this.detailsDevis = item
                this.showdevis = true
              })
              .catch(err=>{
                this.detailsDevis = item
                this.showdevis = true
              })
        }
  
      },
  
      /*Sinistre Picture visualisation */
      VisualizeSinistrePicture(pic){
        this.pictureForDetails = pic
        this.Detail_Image_Sinistre = true
      },
  
      rowClass(item, type) {
        if (!item) {
          return 'success-row';
        } else if (item.vueAdmin ===  false) {
          return 'success-row';
        }
        return '';
      },
  
      getSinistre(){
        if (this.$store.state.user.role=='agent général')
        {
          Api().get('/sinistre/assureur/'+this.$store.state.user.societe)
              .then( resultat => {
                this.listSinistre = resultat.data.sinistres
                this.totalRows = resultat.data.sinistres.length
              });
        }else if(this.$store.state.user.role=='Agent')
        {
          Api().get('/sinistre/courtier/'+this.$store.state.user.societe)
              .then( resultat => {
                this.listSinistre = resultat.data.sinistres
                this.totalRows = resultat.data.sinistres.length
              });
  
        }else if(this.$store.state.user.role=='Assureur')
        {
          Api().get('/sinistre/assureur/'+this.$store.state.user.societe)
              .then( resultat => {
                this.listSinistre = resultat.data.sinistres
                this.totalRows = resultat.data.sinistres.length
              })
        }else if (this.$store.state.user.role=='Admin' || this.$store.state.user.role=='SuperAdmin'){
          Api().get('/sinistre/listing')
              .then(resultat => {
                this.listSinistre = resultat.data.sinistres
                console.log('LISTE SINISTRE',this.listSinistre)
                this.totalRows = resultat.data.sinistres.length
              });
        }
  
      },
  
      //Sinistre en PDF
      ToPdf(Sinistre){
        let id = Sinistre._id
        this.modalgenerationloading = true;
        Api().get('sinistre/generate/'+id)
            .then(rep=>{
              let url = rep.data.lien
              setTimeout(() => (
                  //this.modalgeneration=false,
                  this.modalgenerationloading = false,
                      window.open(url)), 7000);
            }).catch(err=>{
  
        })
      },
  
      SetSinistreToResolu(id){
        Api().get('/sinistre/toprisencompte/'+id)
            .then(response=>{
              this.getSinistre()
              this.showdevis=false
            }).catch(err=>{
  
        })
      },
  
      ResoudreSinistre(id){
        Api().get('/sinistre/toresolu/'+id)
            .then(response=>{
              this.getSinistre()
              this.showdevis=false
            }).catch(err=>{
  
        })
      },
  
      SetSinistreToNonResolu(id){
        Api().get('/sinistre/tonoresolu/'+id)
            .then(response=>{
              this.getSinistre()
              this.showdevis=false
            })
      },
  
      AssistanceDeleteConfirmation (item){
        this.modalDelete = true
        this.detailAssistance = item
      },
  
  
      Remover(){
  
        Api().post('/sinistre/remove')
            .then(resultat => {
  
            })
            .catch(err=>{
            })
      },
  
      DevisRemover(){
  
        Api().delete('/contrat/deleteall')
            .then(resultat => {
              alert(JSON.stringify(resultat.data))
            })
            .catch(err=>{
              alert(JSON.stringify(err))
            })
      },
  
  
      calcDims() {
        const tableWrapper = document.getElementById("table-wrapper")
        if (tableWrapper) this.width = tableWrapper.clientWidth
  
        if (!this.isMobile && tableWrapper) {
          this.height = tableWrapper.clientHeight - 44
        }
  
        if (this.width < 480) {
          this.pagination.small = true
          this.pagination.layout = "prev, pager, next"
        } else if (this.width >= 480 && this.width < 700) {
          this.pagination.small = false
          this.pagination.layout = "prev, pager, next, ->, sizes"
        } else {
          this.pagination.small = false
          this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
        }
  
        this.ready = true
      },
  
      init() {
        if (window.innerWidth <= 768) this.isMobile = true
      }
    },
    created() {
      this.init()
      //this.getSinistre()
    },
    mounted() {
      if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
        this.getSinistre()
      } else {
        this.$router.go(this.$router.push('/login'))
      }
  
      if (!window.Number.parseInt) window.Number.parseInt = parseInt
  
      this.calcDims()
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import "../../../assets/scss/_variables";
  .el-table .warning-row {
      background: #FFB8C8;
    }
  .page-table {
    &.overflow {
      overflow: auto;
    }
  
    .toolbar-box {
      &.hidden {
        visibility: hidden;
      }
    }
  
    .table-box {
      overflow: hidden;
  
      &.hidden {
        visibility: hidden;
      }
    }
  }
  </style>
  
  <style lang="scss">
  @import "../../../assets/scss/_variables";
  
  .page-table {
    .toolbar-box {
      margin-bottom: 10px;
  
      .card-shadow--medium {
        &.el-input,
        &.el-select {
          border-radius: 4px;
  
          input {
            border-color: transparent;
            background-color: lighten($background-color, 2%);
            color: $text-color;
  
            &:hover {
              border-color: #c0c4cc;
            }
            &:focus {
              border-color: $text-color-accent;
            }
          }
        }
  
        &.el-button {
          border-color: transparent;
        }
      }
    }
  
    .clickable {
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;
    }
  
    .sel-string {
      .sel {
        background: transparentize($text-color, 0.8);
        border-radius: 5px;
        //text-transform: uppercase;
      }
    }
  }
  
  @media (max-width: 768px) {
    .page-table {
      .toolbar-box {
        display: block;
        overflow: hidden;
        font-size: 80%;
        padding-bottom: 10px;
  
        & > * {
          display: inline-block;
          min-width: 120px;
          height: 22px;
          //background: rgba(0, 0, 0, 0.04);
          margin-bottom: 16px;
        }
      }
    }
  }
  </style>
  