<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>
        Edition <span style="color:#ffa409"> d'attestation</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <el-col :span="24" class="form-container">
        <el-form ref="formRef" label-width="150px"> 
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="Responsabilité Civile" prop="responsabiliteCivile">
                <el-input-number v-model="responsabiliteCivile" :min="0" style="width:100% "/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Date d'effet" prop="dateEffet">
                <el-date-picker v-model="dateEffet" type="date" placeholder="Sélectionner une date"  style="width:100% "/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
            <!-- Police -->
              <el-form-item label="Police" prop="police">
                <el-input v-model="police" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <!-- Attestation -->
              <el-form-item label="Durée" prop="duree">
                <el-input v-model="duree" disabled/>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row>
            <el-col :span="12">
                <el-form-item label="Périodicité" prop="periodicite">
                    <el-input v-model="periodicite" disabled/>
                </el-form-item>
            
            </el-col>
            <el-col :span="12">
                <el-form-item label="Type de Personne" prop="typePersonne" v-if="typePersonne !=null">
                  <el-select v-model="typePersonne" placeholder="Choisir un type"  style="width:100%">
                    <el-option label="Physique" value="physique"></el-option>
                    <el-option label="Morale" value="morale"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="Type de Personne" prop="typePersonne" v-else>
                    <el-input v-model="typePersonne"/>
                </el-form-item>
            </el-col>
          </el-row>
        
          <el-divider content-position="center"><b>Souscripteur</b></el-divider>
            <el-row :gutter="15">
                <el-col :span="12">
                  <el-form-item label="Nom" prop="souscripteur_nom">
                    <el-input v-model="souscripteur_nom" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Prénom" prop="souscripteur_prenom">
                    <el-input v-model="souscripteur_prenom" />
                  </el-form-item>
              </el-col>
            </el-row>
            
            <!-- <el-row :gutter="15">
              <el-col :span="12">
                <el-form-item label="Cellulaire" prop="souscripteur_cellulaire">
                  <el-input v-model="souscripteur_cellulaire" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Email" prop="souscripteur_email">
                  <el-input v-model="souscripteur_email" type="email" />
                </el-form-item>
              </el-col>
            </el-row> -->
          <!-- Fin souscripteur -->

          <el-divider content-position="center"><b>Bénéficiaire</b></el-divider>
          <el-row>
            <el-col :span="12">
              <el-form-item label="Nom" prop="beneficiaire_nom">
                <el-input v-model="beneficiaire_nom" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Prénom" prop="beneficiaire_prenom">
                <el-input v-model="beneficiaire_prenom" />
              </el-form-item>
            </el-col>
          </el-row>
        
          <!-- <el-row>
            <el-col :span="12">
              <el-form-item label="Cellulaire" prop="beneficiaire_cellulaire">
                <el-input v-model="beneficiaire_cellulaire" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Email" prop="beneficiaire_email">
                <el-input v-model="beneficiaire_email" type="email" />
              </el-form-item>
            </el-col>
          </el-row> -->
          <!-- Fin bénéficiaire -->

          <el-divider content-position="center"><b>Véhicule</b></el-divider>
            <el-row>
              <el-col :span="12">
                <el-form-item label="Marque" prop="vehicule_marque">
                  <el-input v-model="vehicule_marque" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Modèle" prop="vehicule_modele">
                  <el-input v-model="vehicule_modele" />
                </el-form-item>
              </el-col>
            </el-row>
            
            <el-row>
              <el-col :span="12">
                <el-form-item label="Immatriculation" prop="vehicule_immatriculation">
                  <el-input v-model="vehicule_immatriculation" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Puissance Fiscale" prop="vehicule_puissanceFiscale">
                  <el-input-number v-model="vehicule_puissanceFiscale" :min="1" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="Mise en circulation" prop="vehicule_dateMiseCirculation">
                  <el-date-picker v-model="vehicule_dateMiseCirculation" type="date" placeholder="Sélectionner une date" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Nombre de places" prop="vehicule_nombrePlace">
                  <el-input-number v-model="vehicule_nombrePlace" :min="1" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row> 
              <el-col :span="12">
                <el-form-item label="Valeur Neuve" prop="vehicule_valeurNeuve">
                  <el-input-number v-model="vehicule_valeurNeuve" :min="0" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Valeur Actuelle" prop="vehicule_valeurActuelle">
                  <el-input-number v-model="vehicule_valeurActuelle" :min="0" />
                </el-form-item>
              </el-col>
            </el-row>
            
            <el-row>
              <el-col :span="12">
                <el-form-item label="Carburation" prop="vehicule_energie">
                  <el-select v-model="vehicule_energie" placeholder="Choisir un type"  style="width:100%">
                    <el-option label="Essence" value="ESSENCE"></el-option>
                    <el-option label="Diesel" value="DIESEL"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Type de vehicule" prop="vehicule_genre">
                  <el-input v-model="vehicule_genre" />
                </el-form-item>
              </el-col>
            </el-row>

          <el-form-item label="Châssis" prop="vehicule_chassis">
            <el-input v-model="vehicule_chassis" />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm">Soumettre</el-button>
          </el-form-item>
        </el-form>
      
    </el-col>
    
    <!--#################### Thanks view popup ######################-->
    <el-dialog title="Atestation Digitale" width="50%" v-if="modalNumPolice" :visible.sync="modalNumPolice">
      <div class="grid-content bg-purple">
          <div>
            <el-alert title="Voici les liens vers les attestations digitales. 📄" type="success"></el-alert>
          </div>
          <br>
          <div>
            <el-row :gutter="15">
              <el-col :span="10" :offset="2" class="p-3 rounded">
                <a :href="finalData.finaleAttestation.linkAttestation" target="_blank">Télécharger l'attestation digital</a>
              </el-col>
              <el-col :span="10" :offset="2" class="p-3 rounded">
                <a :href="finalData.finaleAttestation.linkCarteBrune" target="_blank">Télécharger la carte brune</a>
              </el-col>
            </el-row>
            <br>
            <el-row :gutter="15">
              <el-col :span="20" :offset="2" class="p-3 rounded">
                <div class="grid-content bg-purple">
                  <el-input type="textarea"  :rows="2"  placeholder="Entrez quelque chose" v-model="smsText"> </el-input>
                </div>
                <el-button type="primary" class="mt-6" @click="sendSms()" round>Envoyer par sms</el-button>
              </el-col>
            </el-row>
          </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Api from '../../../services/Api'
import CarTypeBuilder from '../../../services/carTypeBuilder'
const dayjs = require('dayjs')

export default 
{
  data() {
    return {
      finaleAttestation: null,
      smsText:'',
      finalData : null,
      modalNumPolice : false,
      isMobile: false,
      attestationId: null,
      contratId :'',
      responsabiliteCivile: null,
      dateEffet: "",
      police: "",
      duree: "",
      periodicite: "MOIS",
      typePersonne: "",
      souscripteur_nom: "",
      souscripteur_prenom: "",
      souscripteur_cellulaire: "",
      souscripteur_email: "",
      beneficiaire_nom: "",
      beneficiaire_prenom: "",
      beneficiaire_cellulaire: "",
      beneficiaire_email: "",
      vehicule_immatriculation: "",
      vehicule_marque: "",
      vehicule_modele: "",
      vehicule_puissanceFiscale: null,
      vehicule_dateMiseCirculation: "",
      vehicule_nombrePlace: null,
      vehicule_valeurNeuve: null,
      vehicule_valeurActuelle: null,
      vehicule_energie: "",
      vehicule_genre: "",
      vehicule_chassis: "",
      vehicule_categorie: "",
      dataGetter : null,
    };
  },
  methods: {

    sendSms(){
      let data ={
          phone_number : this.dataGetter.data.user.telephone_port ,
          userid : this.dataGetter.data.user._id,
          sms_objet : "Lien d'attestation digital auto",
          message : this.smsText,
      }

      Api().post('/smsservice/send', data).then((result)=>{
        this.$message.success('Attestation envoyée avec succès !', this.AlertMessage)
        this.$router.push({name:"contrat_encours"})
      }).catch((err) => {
          this.$message.error('Error HTTP !', this.AlertMessage)
          console.log("Error HTTP :", err);
      });
    },

    smsTextBuilder(item){
      let texte_sms_builder = `Votre talon Auto est disponible via ce lien ${item.finaleAttestation.linkAttestation}. Assuraf.com vous remercie`
      this.smsText = texte_sms_builder
    },

    getContractForAttestationEdit(id) 
    {
      console.log("Getting contract for attestation edit");
      if(id) {
        Api().post('/contrat/get/editattestation', { id: id }).then((result) => {
          let beneficiaire = result.data.contrat.devisid.proprietaire;
          let souscripteur = result.data.user;
          let vehicule = result.data.contrat.details[0].vehicules[0]

          this.dataGetter = result;
          this.responsabiliteCivile             = result.data.full_rc_price
          this.dateEffet                        = result.data.contrat.dateEffet
          this.police                           = result.data.contrat.numero_police
          this.duree                            = this.dateDiff(result.data.contrat.dateEcheance, result.data.contrat.dateEffet)
          this.typePersonne                     = this.userTypeBuild(this.dataGetter.data.user.sexe)
          this.souscripteur_nom                 = souscripteur.nom;
          this.souscripteur_prenom              = souscripteur.prenom;
          this.souscripteur_cellulaire          = souscripteur.telephone_port;
          this.beneficiaire_nom                 = beneficiaire.nom;
          this.beneficiaire_prenom              = beneficiaire.prenom;
          this.vehicule_immatriculation         = vehicule.immatriculation;
          this.vehicule_marque                  = vehicule.marque;
          this.vehicule_modele                  = vehicule.model;
          this.vehicule_puissanceFiscale        = vehicule.force;
          this.vehicule_dateMiseCirculation     = vehicule.dateCircule;
          this.vehicule_nombrePlace             = vehicule.nbplace;
          this.vehicule_valeurNeuve             = vehicule.valeur;
          this.vehicule_valeurActuelle          = vehicule.valeur_venale;
          this.vehicule_energie                 = vehicule.carburation;
          this.vehicule_genre                   = CarTypeBuilder(vehicule).genre //.vehicule_genre;
          this.vehicule_chassis                 = vehicule.vehicule_chassis;
          this.vehicule_categorie               = CarTypeBuilder(vehicule).categorie 

        }).catch((err) => {
          this.$message.error('Error HTTP !', this.AlertMessage)
          console.log("Error HTTP :", err);
        });
      }else{
        console.log("ID de l'attestation non trouvé");
      }
    },

    submitForm() {
      let dataAttestation ={
        "assureur": this.dataGetter.data.contrat.partenaireid.societe,
        "contratId":this.attestationId,
        "responsabiliteCivile": this.responsabiliteCivile,
        "dateEffet": this.dateEffet,
        "police": this.police,
        "duree": this.duree,
        "periodicite": this.periodicite,
        "typePersonne": this.typePersonne,

        "souscripteur_nom": this.souscripteur_nom,
        "souscripteur_prenom": this.souscripteur_prenom,
        "souscripteur_cellulaire": this.souscripteur_cellulaire,
        "souscripteur_email": this.souscripteur_email,

        "beneficiaire_nom": this.beneficiaire_nom,
        "beneficiaire_prenom": this.beneficiaire_prenom,
        "beneficiaire_cellulaire": this.beneficiaire_cellulaire,
        "beneficiaire_email": this.beneficiaire_email,

        "vehicule_immatriculation": this.vehicule_immatriculation,
        "vehicule_marque": this.vehicule_marque,
        "vehicule_modele": this.vehicule_modele,
        "vehicule_puissanceFiscale": this.vehicule_puissanceFiscale,
        "vehicule_dateMiseCirculation": this.vehicule_dateMiseCirculation,
        "vehicule_nombrePlace": this.vehicule_nombrePlace,
        "vehicule_valeurNeuve": this.vehicule_valeurNeuve,
        "vehicule_valeurActuelle": this.vehicule_valeurActuelle,
        "vehicule_energie": this.vehicule_energie,
        "vehicule_genre": this.vehicule_genre,
        "vehicule_chassis": this.vehicule_chassis,
        "vehicule_categorie": this.vehicule_categorie
      }

      Api().post('/attestation_digitale/create/', dataAttestation)
      .then((result) => {
        this.$message.success('Attestation modifiée avec succès !', this.AlertMessage)
        this.finalData = result.data
        this.finaleAttestation = result.data.finaleAttestation
        this.smsTextBuilder(result.data)
        this.modalNumPolice = true
      }).catch((err) => {
        if(typeof err.response.data !='undefined' && typeof err.response.data.message!='undefined'){
          this.$message.error(err.response.data.message, this.AlertMessage)
        }else{
          this.$message.error('Error HTTP !', this.AlertMessage)
        }
      });
    },
    
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    },

    dateDiff(date1, date2) {
      let dateEcheancePlus1 = dayjs(date1).add(1, "day");
      let diffInMonths = dateEcheancePlus1.diff(dayjs(date2), "month");
      return diffInMonths;
    },

    userTypeBuild(item) 
    {
      if(item.toUpperCase() == "HOMME" || item.toUpperCase() == "FEMME"){
        return "PHYSIQUE"
      }else if(item.toUpperCase() == "ENTREPRISE"){
        return "MORALE"
      }else{
        return null
      }
    },
   
  },
  created() 
  {
    this.init()
    if(this.$route.params.id && this.$route.params.id != null && this.$route.params.id != undefined)
    {
      this.attestationId = this.$route.params.id
      this.getContractForAttestationEdit(this.attestationId)
    }else{
      console.log("ID de l'attestation non trouvé");
    }
  }
};
</script>

<style scoped>
.form-container {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.divpt{
  padding: 5px;
  background-color: rgb(222, 222, 222);
}
</style>
