import Vue from 'vue'
import Router from 'vue-router'

//pages
//import Login from '../views/pages/authentication/Login.vue'
import Login2 from '../views/pages/authentication/Login2.vue'
import Register from '../views/pages/authentication/Register.vue'
import ForgotPassword from '../views/pages/authentication/ForgotPassword.vue'
import ContratExpress from '../views/apps/contrat/contrat_express.vue'
import ContratListe from '../views/apps/contrat/allContrat.vue'
import ContratEncours from '../views/apps/contrat/contrat_encours.vue'
import ContratEchu from '../views/apps/contrat/contrat_echu.vue'

import ContratCloudListe from '../views/apps/contratCloud/allContrat.vue'
import ContratCloudEncours from '../views/apps/contratCloud/contrat_encours.vue'
import ContratCloudEchu from '../views/apps/contratCloud/contrat_echu.vue'


import SearchContrat from '../views/apps/contrat/search_contrat.vue'
import LivraisonListe from '../views/apps/livraison/liste_livraison.vue'
import Renouvellement from '../views/apps/contrat/renouvellement.vue'
import ListePartenaire from '../views/apps/partenaire/liste_partenaire.vue'
import nouveaupartenaire from '../views/apps/partenaire/nouveauPartenaire.vue'

import RechercheDevis from '../views/apps/contrat/rechercheDevis.vue'
import rechercheDevisMail from '../views/apps/devis/search_devis_by_user.vue'

import Profile from '../views/pages/Profile.vue'
import NotFound from '../views/pages/NotFound.vue'

import DevisListe from '../views/apps/devis/allDevis.vue'
import ListDevis from '../views/apps/devis/list_devis.vue'
import DevisArchive from '../views/apps/devis/devis_archive.vue'
import DevisForward from '../views/apps/devis/forward.vue'

import DevisCloudListe from '../views/apps/devisCloud/allDevis.vue'
import DevisCloudArchive from '../views/apps/devisCloud/devis_archive.vue'
import ListCloudDevis from '../views/apps/devisCloud/list_devis.vue'

/*=================== Devis cloud out SN VUE ====================*/ 
import DevisCloudListeOutSN from '../views/apps/devisCloud/allDevis_outsn.vue'
import DevisCloudArchiveOutSN from '../views/apps/devisCloud/devis_archive_outsn.vue'
import ListCloudDevisOutSN from '../views/apps/devisCloud/list_devis_outsn.vue'

/*=================== Contrat cloud out SN VUE ====================*/
import ContratCloudListeOutSN from '../views/apps/contratCloud/allContrat_outsn.vue'
import ContratCloudEncoursOutSN from '../views/apps/contratCloud/contrat_encours_outsn.vue'
import ContratCloudEchuOutSN from '../views/apps/contratCloud/contrat_echu_outsn.vue'


import BackUser from '../views/apps/users/back_office_user.vue'
import FrontUser from '../views/apps/users/front_office_user.vue'
import CreateUser from '../views/apps/users/creation_front_user.vue'
import CreateBackUser from '../views/apps/users/creation_back_user.vue'
import CreateBackAgent from '../views/apps/users/creation_back_agent.vue'
import ClientList from '../views/apps/users/client_list.vue'
import ProspectList from '../views/apps/users/prospects.vue'
import Client from '../views/apps/users/client_avec_contrat.vue'
import Flotte from '../views/apps/users/flotte.vue'

import listePackSante from '../views/apps/pack_sante/liste_pack_sante.vue'
import creationPackSante from '../views/apps/pack_sante/creation_pack_sante.vue'

import ListBranche from '../views/apps/branche/list_branche.vue'
import CreateBranche from '../views/apps/branche/create_branche.vue'

import listeAssureur from '../views/apps/assureur/liste_assureur.vue'
import creationAssureur from '../views/apps/assureur/creation_assureur.vue'
import CreatePartenaire from '../views/apps/partenaire/create_partenaire.vue'
import AddsTempon from '../views/apps/partenaire/add_tempon.vue'

import CourtierListe from '../views/apps/courtier/liste_courtier.vue'
import CreateCourtier from '../views/apps/courtier/creation_courtier.vue'

import TestimonialListe from '../views/apps/testimonial/liste_testimonial.vue'
import TestimonialCreate from '../views/apps/testimonial/creation_testimonial.vue'

import RSListe from '../views/apps/RSMonitor/monitor_liste.vue'
import RSCreate from '../views/apps/RSMonitor/creation_monitor.vue'

import MarketingListe from '../views/apps/marketing/marketing_liste.vue'
import MarketingCreate from '../views/apps/marketing/creation_marketing.vue'

import NotifListe from '../views/apps/notification/notif_liste.vue'
import CreateNotif from '../views/apps/notification/creation_notif.vue'

import DossierListe from '../views/apps/fraisDossier/liste_dossier.vue'
import CreateDossier from '../views/apps/fraisDossier/creation_dossier.vue'
import listeAssistance from '../views/apps/assistance/liste_assistance.vue'
import listeSinistre from '../views/apps/sinistre/liste_sinistre.vue'
import DevisAuto from '../views/apps/devis/devis_auto.vue'
import DevisHabitaiton from '../views/apps/devis/devis_habitation.vue'
import DevisVoyage from '../views/apps/devis/devis_voyage.vue'
import DevisSante from '../views/apps/devis/devis_sante.vue'
import NsiaEtudes from '../views/apps/devis/nsiaEtudes.vue'
import SunuAssur from '../views/apps/devis/sunuAssur.vue'
import MicroSunuvie from '../views/apps/devis/micro_sunuvie.vue'
import MicroAuto from '../views/apps/devis/micro_auto.vue'
import MicroHabitation from '../views/apps/devis/micro_habitation.vue'
import MicroVoyage from '../views/apps/devis/micro_voyage.vue'
import MicroAutoSimulation from '../views/apps/devis/micro_auto_simulation.vue'
import MicroHabitationSimulation from '../views/apps/devis/micro_habitation_simulation.vue'
import MicroVieSimulation from '../views/apps/devis/micro_vie_simulation.vue'

/*=================== Devis Individuel Accident ====================*/
import IndividuelAccident from '../views/apps/devis/individuel_accident.vue'

import DevisManuel from '../views/apps/devis/devis_manuel.vue'
import UpdateDevisauto from '../views/apps/devis/update_devis_auto'
import UpdateDevishabitation from '../views/apps/devis/update_devis_habitation'
import UpdateDevisvoyage from '../views/apps/devis/update_devis_voyage'

import PaiementLink from '../views/apps/paiement/link_paiement.vue'
import Contrat from '../views/apps/paiement/contrat.vue'
import PaiementListe from '../views/apps/paiement/liste_devis_paiement'
import Paiement from '../views/apps/paiement/paiement.vue'
import PaiementCash from '../views/apps/paiement/paiement_cash.vue'
import AllContrat from '../views/apps/paiement/all_contrat_partenaire.vue'
import PartenaireTiersListe from '../views/apps/partenaire/partenairesTiersListe.vue'

// Importation des components bordereaux
import bordereaux from '../views/apps/bordereaux/index'
import listeBordereaux from '../views/apps/bordereaux/listeBordereaux.vue'
import bordereauxCommission from '../views/apps/bordereaux/commissionComponent.vue'
import bordereauxEncaissement from '../views/apps/bordereaux/encaissementComponent.vue'
import bordereauxVersement from '../views/apps/bordereaux/versementComponent.vue'
// SDK COMMISSION 
import commission from '../views/apps/sdk_commission/commission.vue'
import parametre from '../views/apps/sdk_commission/parametre.vue'

import Configlist from '../views/apps/configuration/configlist.vue'
import CreateConfig from '../views/apps/configuration/createconfig.vue'
import createConfigPartenaire from '../views/apps/partenaire/createpartenaire.vue'


import Dashboard from '../views/apps/Dashboard.vue'
//import layout from './layout'
import layout from './layout'

import layouts from '../layout'
import store from '../store'

import uploadexcel from '../views/apps/flotte/uploadExcel.vue' 
import listeFlotte from '../views/apps/flotte/listeFlotte.vue' 
import devisflotte from '../views/apps/flotte/devisflotte.vue'
import listeflottenonvalider from '../views/apps/flotte/listeFlotteNonValider.vue'
import listeflottevalider from '../views/apps/flotte/listeFlotteValider.vue'
import devisgenerationflotte from '../views/apps/flotte/validationFlotte.vue'

import ContratTempo from '../views/apps/devis/contrat_tempo.vue'

//cumule de contrats
import CumuleContratAlert from '../views/apps/CumuleContratAlert.vue'
import Sms_manager from '../views/apps/sms/sms.vue'

/*== Gestion commission agent ==*/
import parametre_commission_agent from '../views/apps/commissionagent/parametre.vue'
import commission_agent from '../views/apps/commissionagent/commission.vue'

import preTocontrat from '../views/apps/contrat/precontratTocontrat.vue'

import logerview from '../views/apps/logview/logview.vue'
import corbeilleview from '../views/apps/logview/corbeille.vue'

/**Coupon routes */
import createCoupon from '../views/apps/coupons/create_coupon.vue'
import listeCoupon from '../views/apps/coupons/liste_coupon.vue'
import listeCouponEncours from '../views/apps/coupons/liste_coupon_encours.vue'
import listeCouponArchiver from '../views/apps/coupons/liste_coupon_archiver.vue'

/*== Avenant renouvellement==*/
import UpdateDevisautoavenant from '../views/apps/devis/update_devis_auto_for_renew_avenant.vue'
import avenantRenouvellement from '../components/avenant/renouvellement.component.vue'
import avenantListe from '../views/apps/contrat/avenant.vue'

import assurafsante from '../views/apps/test_sante.vue'


import simulationDevisAuto from '../views/apps/simulation/simulation_devis_auto.vue'
import calculPuissanceFiscale from '../views/apps/simulation/calcul_puissance_fiscale.vue'
import ListeOffreSimulation from '../views/apps/simulation/liste_offre_simulator.vue'

import editAttestion from '../views/apps/attestationDigitale/editAttestation.vue'

Vue.use(Router)
const router = new Router({
	mode: 'history',
	routes: [
		{
			path: "/edit/attestation/:id",
			name: "editAttestion",
			component: editAttestion,
			meta: {
				layout: layouts.navLeft,
				searchable: true,
				title: 'Edition Attestation',
				tags: ['attestation', 'edition']
			}
		},
		{
			path: "/sante/liste",
			name: "sante_liste",
			component: assurafsante,
			meta: {
				layout: layouts.navLeft,
				searchable: false,
			}
		},
		{
			path: "/avenant/liste",
			name: "avenant_liste",
			component: avenantListe,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'avenant contrat auto',
				tags: ['avenant', 'contrat', 'autor', 'liste']
			}
		},
		{
			path: "/avenant/update/auto",
			name: "avenant_update_devis_auto",
			component: UpdateDevisautoavenant,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'modification devis auto',
				tags: ['devis', 'modification']
			}
		},
		{
			path: "/avenant/renouvellement",
			name: "avenant_renouvellement",
			component: avenantRenouvellement,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'Avenant de Renouvellement',
				tags: ['devis', 'modification', "avenant", "Renouvellement"]
			}
		},

		/** Routes reduction coupon 8*/
		{
			path: "/coupons/create",
			name: "createcoupon",
			component: createCoupon,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Create Coupon reduction',
				tags: ['create', 'coupon', 'reduction', 'speciale']
			}
		},
		{
			path: "/coupons/liste",
			name: "listecoupon",
			component: listeCoupon,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Liste Coupon reduction',
				tags: ['create', 'liste', 'coupon', 'reduction', 'speciale']
			}
		},
		{
			path: "/coupons/liste/encours",
			name: "listecouponencours",
			component: listeCouponEncours,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Liste Coupon reduction encours',
				tags: ['create', 'liste', 'coupon', 'reduction', 'speciale', 'encours']
			}
		},
		{
			path: "/coupons/liste/archives",
			name: "listecouponarchiver",
			component: listeCouponArchiver,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Liste Coupon reduction archive',
				tags: ['create', 'liste', 'coupon', 'reduction', 'speciale', 'archive']
			}
		},
		{
			path: "/corbeille/list",
			name: "corbeilleview",
			component: corbeilleview
		},
		{
			path: "/logger/list",
			name: "logerview",
			component: logerview
		},
		{
			path: "/precontrat/validation",
			name: "preTocontrat",
			component: preTocontrat
		},
		{
			path: "/sms/index",
			name: "sms_index",
			component: Sms_manager
		},
		{
			path: "/commission/agent/index",
			name: "commissionagentindex",
			component: commission_agent
		},
		{
			path: "/commission/agent/parametre",
			name: "commissionagentparametre",
			component: parametre_commission_agent
		},
		//
		{
			path: "/contrat/cumule",
			name: "cumule_contrat_alert",
			component: CumuleContratAlert
		},
		// ================================= routes pour sdk commission  =======================
		{
			path: '/commission/index',
			name: 'commission',
			component: commission,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Liste commission',
				tags: ['commission', 'paramètre', 'liste', 'lister']
			}
		},
		{
			path: '/commission/parametre',
			name: 'parametre',
			component: parametre,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Liste parametre',
				tags: ['commission', 'paramètre', 'liste']
			}
		},
		// =================== begin route flotte =========================== 
		{
			path: '/flotte/uploadexcel',
			name: 'uploadexcel',
			component: uploadexcel,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Flotte Upload File Excel ',
				tags: ['flotte', 'upload', 'file', 'excel']
			}
		},
		{
			path: '/flotte/uploadexcel/:id',
			name: 'devisflotte',
			component: devisflotte,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Flotte Upload File Excel ',
				tags: ['flotte', 'upload', 'file', 'devis']
			}
		},
		{
			path: '/flotte/liste',
			name: 'listeFlotte',
			component: listeFlotte,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Liste Flotte',
				tags: ['flotte', 'upload', 'liste', 'excel', 'file']
			}
		},
		{
			path: '/flotte/liste/valider',
			name: 'listeFlotteValider',
			component: listeflottevalider,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Liste Flotte',
				tags: ['flotte', 'upload', 'liste', 'excel', 'file']
			}
		},
		{
			path: '/flotte/liste/nonvalider',
			name: 'listeFlotteNonValider',
			component: listeflottenonvalider,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Liste Flotte',
				tags: ['flotte', 'upload', 'liste', 'excel', 'file']
			}
		},
		{
			path: '/flotte/generation/:id',
			name: 'generationflotte',
			component: devisgenerationflotte,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Validation devis flotte ',
				tags: ['flotte', 'upload', 'file', 'devis', 'validation']
			}
		},
		// =================== end route flotte =========================== 

		// ================================= routes pour creation des devis =======================	
		{
			path: '/',
			name: 'login2',
			component: Login2,
			meta: {
				layout: layouts.navLeft,
				searchable: false,
				title: 'Login',
				tags: ['Login']
			}
		},
		{
			path: '/login',
			name: 'login2',
			component: Login2,
			meta: {
				layout: layouts.contenOnly
			}
		},
		{
			path: '*',
			name: 'not-found',
			component: NotFound,
			meta: {
				layout: layouts.contenOnly
			}
		},
		layout,

		/* ======================= USERS ROUTER ====================
		   ======================= USERS ROUTER ==================== */
		{
			path: '/users/createuser',
			name: 'creation_front_user',
			component: CreateUser,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation user',
				tags: ['Creation', 'utilisateur', 'frontoffice']
			}
		},

		{
			path: '/user/back/createuser',
			name: 'creation_back_user',
			component: CreateBackUser,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation user',
				tags: ['Creation', 'utilisateur', 'backoffice']
			}
		},

		{
			path: '/users/createagent',
			name: 'creation_back_user_agent',
			component: CreateBackAgent,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation agent',
				tags: ['Creation', 'utilisateur', 'backoffice']
			}
		},

		{
			path: '/users/listuser',
			name: 'list_user_back_office',
			component: BackUser,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'list user back',
				tags: ['liste', 'backOffice', 'office']
			}
		},

		{
			path: '/users/frontoffice',
			name: 'list_user_front_office',
			component: FrontUser,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'list user front',
				tags: ['liste', 'frontOffice', 'office']
			}
		},

		{
			path: '/clients/clientlist',
			name: 'list_client',
			component: ClientList,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'list client',
				tags: ['liste', 'client', 'prospects']
			}
		},

		{
			path: '/clients/prospects',
			name: 'list_prospect',
			component: ProspectList,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'list prospect',
				tags: ['liste', 'prospects']
			}
		},

		{
			path: '/clients/client',
			name: 'list_client_contrat',
			component: Client,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'list client with contrat',
				tags: ['liste', 'contrat', 'client']
			}
		},

		{
			path: '/user/fleet',
			name: 'list_client_flotte',
			component: Flotte,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'list client with flotte',
				tags: ['liste', 'flotte', 'client']
			}
		},

		/* ======================= BRANCH ROUTER ====================
		   ======================= BRANCH ROUTER ==================== */
		
		{
			path: '/branche/liste',
			name: 'list_branche',
			component: ListBranche,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'liste branche',
				tags: [' branche', 'liste']
			}
		},
		{
			path: '/branche/create',
			name: 'create_branche',
			component: CreateBranche,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation branche',
				tags: [' branche', 'creation']
			}
		},
		/* 	======================= ASSUREUR ROUTER ====================
			======================= ASSUREUR ROUTER ==================== */
		
		{
			path: '/assureurs/liste',
			name: 'assureur_liste',
			component: listeAssureur,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'liste assureur',
				tags: [' assureur', 'liste', 'lister']
			}
		},
		{
			path: '/assureurs/assureurcreate',
			name: 'creer_assureur',
			component: creationAssureur,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation assureur',
				tags: [' creation', 'liste', 'ssureur', 'compagnie']
			}
		},
		/* ======================= FIN ASSUREUR ROUTER ====================*/
	
		{
			path: '/home',
			name: 'dashboard',
			component: Dashboard,
			meta: {
				layout: layouts.navLeft,
				searchable: true,
				title: 'Accueil',
				tags: ['Accueil', 'Home']
			}
		},
		{
			path: '/profile',
			name: 'profile',
			component: Profile,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				tags: ['pages']
			}
		},
		/* ======================= DEVIS ROUTER ====================
		   ======================= DEVIS ROUTER ==================== */
		{
			path: '/devis/all',
			name: 'devis_liste',
			component: DevisListe,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis liste',
				tags: ['devis', 'liste', 'All']
			}
		},
		{
			path: '/devis/listdevis',
			name: 'devis_liste_non_archive',
			component: ListDevis,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis liste non-archive',
				tags: ['devis', 'liste', 'All', 'non-archive']
			}
		},
		{
			path: '/devis/archives',
			name: 'devis_liste_archive',
			component: DevisArchive,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis liste archive',
				tags: ['devis', 'liste', 'All', 'archive']
			}
		},

		{
			path: '/devis/forward',
			name: 'devis_forward',
			component: DevisForward,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis forward',
				tags: ['devis', 'forward', 'transfer']
			}
		},
		/* ======================= DEVIS CLOUD ROUTER ====================
		   ======================= DEVIS CLOUD ROUTER ==================== */
		{
			path: '/devis/cloud/all',
			name: 'devis_liste',
			component: DevisCloudListe,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis liste',
				tags: ['devis', 'liste', 'All']
			}
		},
		{
			path: '/devis/cloud/listdevis',
			name: 'devis_liste_non_archive',
			component: ListCloudDevis,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis liste non-archive',
				tags: ['devis', 'liste', 'All', 'non-archive']
			}
		},
		{
			path: '/devis/cloud/archives',
			name: 'devis_liste_archive',
			component: DevisCloudArchive,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis liste archive',
				tags: ['devis', 'liste', 'All', 'archive']
			}
		},
		/*==========================*/
		{
			path: "/devis/update/auto",
			name: "update_devis_auto",
			component: UpdateDevisauto,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'modification devis auto',
				tags: ['devis', 'modification']
			}
		},
		{
			path: "/devis/update/habitation",
			name: "update_devis_habitation",
			component: UpdateDevishabitation,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'modification devis habitation',
				tags: ['devis', 'modification']
			}
		},
		{
			path: "/devis/update/voyage",
			name: "update_devis_voyage",
			component: UpdateDevisvoyage,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'modification devis voyage',
				tags: ['devis', 'modification']
			}
		},
		{
			path: '/devis/tempo',
			name: 'devis_tempo',
			component: ContratTempo,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis tempo',
				tags: ['devis', 'tempo']
			}
		},

		/*============================= Contrat  Cloud Out SN=================================*/
		/*====================================================================================*/
		{
			path: '/contrat/cloud/outsn/listcontrat',
			name: 'contrat_liste_outsn',
			component: ContratCloudListeOutSN,
			meta: {
				layout: layouts.navLeft,
				searchable: true,
				title: 'contrat liste hors SN',
			}
		},
		{
			path: '/contrat/cloud/outsn/encours',
			name: 'contrat_cloud_encours',
			component: ContratCloudEncoursOutSN,
			meta: {
				layout: layouts.navLeft,
				searchable: false,
				title: 'contrat encours hors SN',
			}
		},
		{
			path: '/contrat/cloud/outsn/echus',
			name: 'contrat_cloud_echu_outsn',
			component: ContratCloudEchuOutSN,
			meta: {
				layout: layouts.navLeft,
				searchable: false,
				title: 'contrat cloud echu hors SN',
			}
		},
		/*<====================================================================================>*/ 



		/*============================= Devis Cloud Out SN=================================*/  
		/*=================================================================================*/
		{
			path: '/devis/cloud/outsn/all',
			name: 'devis_liste_outsn',
			component: DevisCloudListeOutSN,
			meta: {
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis liste',
			}
		},
		{
			path: '/devis/cloud/outsn/listdevis',
			name: 'devis_liste_non_archive_outsn',
			component: ListCloudDevisOutSN,
			meta: {
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis liste non-archive',
			}
		},
		{
			path: '/devis/cloud/outsn/archives',
			name: 'devis_liste_archive_outsn',
			component: DevisCloudArchiveOutSN,
			meta: {
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis liste archive',
			}
		},


		/* ===================== CONTRAT ROUTER ====================
		   ===================== CONTRAT ROUTER ==================== */
		{
			path: '/contrat/listcontrat',
			name: 'contrat_liste',
			component: ContratListe,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'contrat liste',
				tags: ['contrat', 'liste', 'All']
			}
		},
		{
			path: '/contrat/cloud/listcontrat',
			name: 'contrat_liste',
			component: ContratCloudListe,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'contrat liste',
				tags: ['contrat cloud', 'liste', 'All']
			}
		},
		{
			path: '/contrat/express/listing',
			name: 'contrat_express',
			component: ContratExpress,
		},
		{
			path: '/contrat/express/search',
			name: 'recherche_devis',
			component: RechercheDevis,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis recherche',
				tags: ['contrat', 'express', 'recherche']
			}
		},
		/* ===================== DEVIS INDIVIDUEL ACCIDENT ====================
		   ===================== DEVIS INDIVIDUEL ACCIDENT ==================== */
		   {
			path: '/devis/individuel',
			name: 'devis_individuel_accident',
			component: IndividuelAccident,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis individuel accident',
				tags: ['devis', 'Individuel', 'Accident']
			}
		},
		{
			path: '/contrat/encours',
			name: 'contrat_encours',
			component: ContratEncours,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'contrat encours',
				tags: ['contrat', 'encours', 'valide']
			}
		},
		{
			path: '/contrat/echus',
			name: 'contrat_echu',
			component: ContratEchu,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'contrat echu',
				tags: ['contrat', 'echu', 'echouer']
			}
		},
		{
			path: '/contrat/cloud/encours',
			name: 'contrat_cloud_encours',
			component: ContratCloudEncours,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'contrat encours',
				tags: ['contrat cloud', 'encours', 'valide']
			}
		},
		{
			path: '/contrat/cloud/echus',
			name: 'contrat_cloud_echu',
			component: ContratCloudEchu,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'contrat cloud echu',
				tags: ['contrat cloud', 'echu']
			}
		},
		{
			path: '/contrat/search',
			name: 'search_contrat',
			component: SearchContrat,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'contrat search',
				tags: ['contrat', 'search']
			}
		},

		/* ===================== PACK SANTE ROUTER ====================
		   ===================== PACK SANTE ROUTER ==================== */
		{
			path: '/packsante/listing',
			name: 'list_pack_sante',
			component: listePackSante,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'list pack sante',
				tags: ['liste', 'pack', 'sante']
			}
		},
		{
			path: '/packsante/create',
			name: 'creation_pack_sante',
			component: creationPackSante,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation pack sante',
				tags: ['creation', 'pack', 'sante']
			}
		},

		/* ===================== PAIEMENT ROUTER ====================
		   ===================== PAIEMENT ROUTER ==================== */
		{
			path: '/payment/listing',
			name: 'paiement_link',
			component: PaiementLink,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'paiement link',
				tags: ['paiement', 'Link', 'Lien']
			}
		},	
		{
			path: '/contrat/paiement/cash',
			name: 'paiement_cash',
			component: PaiementCash,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'paiement cash',
				tags: ['paiement', 'cash']
			}
		},
		{
			path: '/paiement/paiement',
			name: 'paiement',
			component: Paiement,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'paiement',
				tags: ['paiement']
			}
		},
		{
			path: '/delivery/all',
			name: 'livraison_liste',
			component: LivraisonListe,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'liste livraison',
				tags: [' livraison', 'liste', 'lister']
			}
		},

		{
			path: '/contrat/renouvellement',
			name: 'contrat_renouvellement',
			component: Renouvellement,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'contrat renouvellement',
				tags: ['contrat', 'renouvellement']
			}
		},
		{
			path: '/partenaires/tiers/liste',
			name: 'superadmin',
			component: PartenaireTiersListe,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'contrat partenaire',
				tags: ['All', 'contrat']
			}
		},


		/* ===================== PARTENER ROUTER ====================
		   ===================== PARTENER ROUTER ==================== */
		{
			path: '/partenaires/listpartenaire',
			name: 'list_partenaire',
			component: ListePartenaire,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'list partenaire',
				tags: ['liste', 'partenaire']
			}
		},
		{
			path: '/partenaires/nouveau',
			name: 'nouveau_partenaire',
			component: nouveaupartenaire,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'nouveau partenaire',
				tags: ['Nouveau', 'partenaire']
			}
		},
		{
			path: '/partenaires/createpartenaire',
			name: 'creation_partenaire',
			component: CreatePartenaire,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation partenaire',
				tags: ['creation', 'partenaire']
			}
		},
		{
			path: '/partenaire/liste',
			name: 'liste_partenaire',
			component: ListePartenaire,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation partenaire',
				tags: ['creation', 'partenaire']
			}
		},
		{
			path: '/partner/stamp/add',
			name: 'add_tempon',
			component: AddsTempon,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'ajouter tempon',
				tags: ['tempon', 'ajouter']
			}
		},

		/* ===================== COURTIER ROUTER ====================
		   ===================== COURTIER ROUTER ==================== */

		{
			path: '/courtier/courtierlist',
			name: 'list_courtier',
			component: CourtierListe,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'list courtier',
				tags: ['liste', 'courtier', 'partenaire']
			}
		},
		{
			path: '/courtier/createcourtier',
			name: 'creation_courtier',
			component: CreateCourtier,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation courtier',
				tags: ['creation', 'courtier', 'partenaire']
			}
		},

		/* ===================== FRAIS DOSSIER ROUTER ====================
		   ===================== FRAIS DOSSIER ROUTER ==================== */
		{
			path: '/fraisdossier/listing',
			name: 'list_dossier',
			component: DossierListe,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'liste dossier',
				tags: ['dossier', 'frais']
			}
			
		},
		{
			path: '/fraisdossier/create',
			name: 'creation_dossier',
			component: CreateDossier,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation dossier',
				tags: ['creation', 'dossier', 'frais']
			}
		},

		/* ===================== ASSISTANCE ====================
		   ===================== ASSISTANCE ==================== */
		{
			path: '/assistances/liste',
			name: 'assistance_liste',
			component: listeAssistance,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'liste assistance',
				tags: [' assistance', 'liste', 'assistant', 'lister']
			}
		},

		/* ===================== SINISTRE ====================
		   ===================== SINISTRE ==================== */
		{
			path: '/sinistre/liste',
			name: 'list_user_sinistre',
			component: listeSinistre,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'list sinistre',
				tags: ['liste', 'sinistre', 'declare']
			}
		},

		/* ===================== DEVIS AUTO ====================
		   ===================== DEVIS AUTO ==================== */
		{
			path: '/devis/auto',
			name: 'devis_auto',
			component: DevisAuto,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis auto',
				tags: ['devis', 'Auto']
			}
		},
		{
			path: '/devis/voyage',
			name: 'devis_voyage',
			component: DevisVoyage,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis voyage',
				tags: ['devis', 'Voyage']
			}
		},
		{
			path: '/devis/habitation',
			name: 'devis_habitation',
			component: DevisHabitaiton,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis habitation',
				tags: ['devis', 'Habitation']
			}
		},
		{
			path: '/devis/nsiaetudes',
			name: 'devis_nsia_etudes',
			component: NsiaEtudes,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis nsia etudes',
				tags: ['devis', 'Nsia', 'Etudes']
			}
		},
		{
			path: '/devis/sunuassur',
			name: 'devis_sunu_assur',
			component: SunuAssur,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis sunu assur',
				tags: ['devis', 'Sunu', 'Assur']
			}
		},
		{
			path: '/Devis/micro/sunuvie',
			name: 'devis_micro_sunuvie',
			component: MicroSunuvie,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis micro sunu assur',
				tags: ['devis', 'Sunu', 'Assur','micro', 'vie']
			}
		},
		{
			path: '/Devis/micro/auto',
			name: 'devis_micro_auto',
			component: MicroAuto,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis micro auto',
				tags: ['devis','micro', 'auto']
			}
		},
		{
			path: '/Devis/micro/habitation',
			name: 'devis_micro_habitation',
			component: MicroHabitation,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis micro habitation',
				tags: ['devis','micro', 'habitation']
			}
		},
		{
			path: '/Devis/micro/voyage',
			name: 'devis_micro_voyage',
			component: MicroVoyage,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis micro voyage',
				tags: ['devis','micro', 'voyage']
			}
		},
		{
			path: '/Devis/micro/autotmp',
			name: 'devis_micro_autotmp',
			component: MicroAutoSimulation,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis micro auto',
				tags: ['devis','micro', 'automobile']
			}
		},
		{
			path: '/Devis/micro/habitationtmp',
			name: 'devis_micro_habitationtmp',
			component: MicroHabitationSimulation,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis micro habitation',
				tags: ['devis','micro', 'habitation']
			}
		},
		,
		{
			path: '/Devis/micro/vietmp',
			name: 'devis_micro_vietmp',
			component: MicroVieSimulation,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis micro vie',
				tags: ['devis','micro', 'vie']
			}
		},
		{
			path: '/devis/manuel',
			name: 'devis_manuel',
			component: DevisManuel,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis manuel',
				tags: ['devis', 'Manuel']
			}
		},
		{
			path: '/devis/sante',
			name: 'devis_sante',
			component: DevisSante,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'devis sante',
				tags: ['devis', 'Santé']
			}
		},
		{
			path: '/devis/user/search',
			name: 'recherche_devis',
			component: rechercheDevisMail,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'recherche devis',
				tags: ['devis', 'recherche']
			}
		},
		/* ===================== SIMULATION DEVIS AUTO ====================
		   ===================== SIMULATION DEVIS AUTO ==================== */
		{
			path: '/simulation/devis/auto',
			name: 'simulation_auto',
			component: simulationDevisAuto,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'simulation devis',
				tags: ['devis', 'simulation']
			}
		},
		{
			path: '/calculate/fiscalpower',
			name: 'calcul_puissance_ficale',
			component: calculPuissanceFiscale,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'simulation devis',
				tags: ['calcule', 'simulation', 'puissance']
			}
		},

		/* ===================== PAIEMENT ====================
		   ===================== PAIEMENT ==================== */
		{
			path: '/simulation/liste',
			name: 'liste_devis_simulation',
			component: ListeOffreSimulation,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'paiement liste',
				tags: ['paiement', 'liste']
			}
		},
		{
			path: '/paiement/liste',
			name: 'liste_devis_paiement',
			component: PaiementListe,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'paiement liste',
				tags: ['paiement', 'liste']
			}
		},
		{
			path: '/paiement/paiement',
			name: 'paiement',
			component: Paiement,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'paiement',
				tags: ['paiement']
			}
		},
		{
			path: '/contrat/ok',
			name: 'contrat_ok',
			component: Contrat,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: false,
				title: 'contrat validé',
				tags: ['contrat', 'okay']
			}
		},

		/* ===================== EMAIL NOTIFICATION AUTO ====================
		   ===================== EMAIL NOTIFICATION AUTO ==================== */
		{
			path: '/config/notitication/email_adresse',
			name: 'notif_liste',
			component: NotifListe,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Notification liste',
				tags: [' notification', 'liste']
			}
		},
		{
			path: '/config/notitication/email_adresse/create',
			name: 'notif_create',
			component: CreateNotif,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Notification creation',
				tags: [' notification', 'creation']
			}
		},

		/* ===================== TESTIMONIAL CREATE & LISTE ====================
		   ===================== TESTIMONIAL CREATE & LISTE ==================== */
		{
			path: '/testimonial',
			name: 'list_testimonial',
			component: TestimonialListe,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'liste testimonial',
				tags: [' testimonial', 'liste']
			}
		},
		{
			path: '/testimonial/create',
			name: 'create_testimonial',
			component: TestimonialCreate,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation testimonial',
				tags: [' testimonial', 'creation']
			}
		},
		/* ===================== RS MONITOR CREATE & LISTE ====================
		   ===================== RS MONITOR CREATE & LISTE ==================== */
		{
			path: '/RS',
			name: 'monitor_liste',
			component: RSListe,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'RS monitor liste',
				tags: [' monitor', 'liste']
			}
		},
		{
			path: '/RS/create',
			name: 'monitor_create',
			component: RSCreate,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'RS monitor creation',
				tags: [' monitor', 'creation']
			}
		},

		/* ===================== POPUP MARKETING CREATE & LISTE ====================
		   ===================== POPUP MARKETING CREATE & LISTE ==================== */
		{
			path: '/marketing/popup',
			name: 'list_marketing',
			component: MarketingListe,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'marketing popup',
				tags: [' marketing', 'popup']
			}
		},
		{
			path: '/marketing/popup/create',
			name: 'create_marketing',
			component: MarketingCreate,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation marketing popup',
				tags: [' marketing', 'creation', 'popup']
			}
		},

		/* ===================== NOTIFICATION PARTENAIRE CREATE & LISTE ====================
		   ===================== NOTIFICATION PARTENAIRE CREATE & LISTE ==================== */
		{
			path: '/config/notitication/email_adresse',
			name: 'notif_liste',
			component: NotifListe,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Notification liste',
				tags: [' notification', 'liste']
			}
		},
		{
			path: '/config/notitication/email_adresse/create',
			name: 'notif_create',
			component: CreateNotif,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Notification creation',
				tags: [' notification', 'creation']
			}
		},

		/* ===================== BORDEREAUX BORDEREAUX ====================
		   ===================== BORDEREAUX BORDEREAUX ==================== */
		{
			path: '/bordereaux/commissions',
			name: 'commission',
			component: bordereauxCommission,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Commission liste',
				tags: ['commission']
			}
		},
		{
			path: '/bordereaux/encaissements',
			name: 'commission',
			component: bordereauxEncaissement,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Commission liste',
				tags: ['commission']
			}
		},
		{
			path: '/bordereaux/reversements',
			name: 'commission',
			component: bordereauxVersement,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Commission liste',
				tags: ['commission']
			}
		},
		{
			path: '/bordereaux/liste',
			name: 'listeBordereaux',
			component: listeBordereaux,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Bordereaux liste',
				tags: ['bordereaux']
			}
		},
		/* ===================== CONFIGURATION ====================
		   ===================== CONFIGURATION ==================== */
		   {
			path: '/partner/configuration',
			name: 'all_configuration',
			component: Configlist,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Configuration liste',
				tags: [' Configuration', 'liste']
			}
		},
		/* ===================== CREATION CONFIGURATION ====================
		   ===================== CREATION CONFIGURATION ==================== */
		{
			path: '/partenaires/config/createpartenaire',
			name: 'createconfigpartenaire',
			component: createConfigPartenaire,
			meta: {
				//auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'Create Partenaire',
				tags: [' Create Partenaire']
			}
		},
		{
			path: '/partner/configuration/create',
			name: 'create_config',
			component: CreateConfig,
			meta: {
				// auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'creation configuration',
				tags: ['configuration','config', 'creation']
			}
		},
	]
})


const l = {
	contenOnly(){
		store.commit('setLayout', layouts.contenOnly)
	},
	navLeft(){
		store.commit('setLayout', layouts.navLeft)
	},
	navRight(){
		store.commit('setLayout', layouts.navRight)
	},
	navTop(){
		store.commit('setLayout', layouts.navTop)
	},
	navBottom(){
		store.commit('setLayout', layouts.navBottom)
	},
	set(layout){
		store.commit('setLayout', layout)
	}
}

//insert here login logic
const auth = {
	loggedIn() {
		return store.getters.isLogged
	},
	logout() {
		store.commit('setLogout')
	}
}

router.beforeEach((to, from, next) => {
	let authrequired = false
	if(to && to.meta && to.meta.auth)
		authrequired = true

	//console.log('authrequired', authrequired, to.name)

	if(authrequired) {
		if(auth.loggedIn()) 
		{
			if(to.name === 'login') 
			{
				window.location.href = '/'
				return false
			} else { 
				next()
			}
		} else {
			if(to.name !== 'login')
			{
				window.location.href = '/login'
				return false
			}
			next()
		}
	} else {
		if(auth.loggedIn() && to.name === 'login'){
			window.location.href = '/'
			return false
		} else {
			next()
		}
	}

	if(to && to.meta && to.meta.layout){
		l.set(to.meta.layout)
	}	
})

// router.afterEach((to, from) => {
// 	// setTimeout(()=>{
// 	// 	store.commit('setSplashScreen', false)
// 	// }, 100)
// })

export default router