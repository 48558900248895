export default {
    'apiurl': "https://assurapi.assuraf.com/apicloud/",// 
    //'apiurl': "https://assurapi.assuraf.com/apicloudmigration/", 
    //"http://localhost:3005/",
    //'urlweb':'http://localhost:8080/', // URL FRONTEND
    urlweb: 'https://assuraf.com/', // URL FRONTEND
    'user': {
        'avatar': '../../assets/img/user_icon.png'
    },
    sdk: {
        emailConfig: [
            //'mosila2138@gmail.com', 
            'config@assuraf.com',
            'bamba@assuraf.com',
            'sgning@assuraf.com',
            'mowencleopas@assuraf.com'
        ],
        commission: {
            all: '/sdk/commission/all',
            add: '/sdk/commission/add',
            one: '/sdk/commission/one/',
            police: '/sdk/commission/police',
            update: '/sdk/commission/update',
            delete: '/sdk/commission/delete/',
            addmany: '/sdk/commission/add/many',
            filtrePeriode: '/sdk/commission/filtre/periode',
            exportToExcel: '/sdk/commission/export/excel',
            generate: '/sdk/commission/generate',
            updateEncaissement: '/sdk/commission/update/encaissement'
        },
        parametre: {
            all: '/sdk/parametre/all',
            add: '/sdk/parametre/add',
            one: '/sdk/parametre/one/',
            update: '/sdk/parametre/update',
            delete: '/sdk/parametre/delete/'
        },
        commissionAgent: {
            all: '/sdk/commission/agent/all',
            add: '/sdk/commission/agent/add',
            one: '/sdk/commission/agent/one/',
            update: '/sdk/commission/agent/update',
            delete: '/sdk/commission/agent/delete/',
            byAgence: '/sdk/commission/agent/byagence/',
            generate: '/sdk/commission/generate',
        },
        parametreAgent: {
            all: '/sdk/parametre/agent/all',
            add: '/sdk/parametre/agent/add',
            one: '/sdk/parametre/agent/one/',
            update: '/sdk/parametre/agent/update',
            delete: '/sdk/parametre/agent/delete/',
            agence: '/sdk/parametre/agent/byagence/'
        }
    }
}
